import { useMemo } from 'react';
import styled from 'styled-components';
import { useCommonManagementHook } from './useCommonHookV2';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContent from './TableContent';

import { get } from 'lodash';

const Button = styled.div`
  background: ${({ background }) => background};
  color: #fff;
  width: ${({ width }) => width};
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 20px;
  height: 38px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const CommonManagement = () => {
  const props = useCommonManagementHook();
  const {
    data,
    title,
    exportUrl,
    showPrint,
    table,

    onExport,
    onPrint,
  } = props;

  const breadcrumbItems = useMemo(
    () => [
      { title: 'AnPhu', link: '/' },
      { title, link: '#' },
    ],
    [title]
  );

  return (
    <div className="page-content">
      <div className="d-flex align-items-center justify-content-end">
        <div className="flex-1"></div>
        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
      </div>

      {table.map((_config, i) => (
        <TableContent
          key={i}
          {...props}
          {..._config}
          data={get(data, [_config.name, 'items'], [])}
          tableSummary={get(data, [_config.name, 'summary'], {})}
          pageOptions={{
            sizePerPage: 10,
            totalSize: get(data, [_config.name, 'items'], []).length,
            custom: true,
          }}
          selectRowProp={undefined}
        />
      ))}
      <div className="d-none">{/* <TableContent previewMode {...props} ref={contentRef} pageOptions={pageOptionPreview} /> */}</div>
      <div className="d-flex align-items-center mt-4">
        {showPrint && (
          <Button background="#DC3E15" width="100px" onClick={onPrint}>
            In LCH
          </Button>
        )}
        {!!exportUrl && (
          <Button background="#1D6F42" width="120px" onClick={onExport}>
            Xuất Excel
          </Button>
        )}
      </div>
    </div>
  );
};

export default CommonManagement;
