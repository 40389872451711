import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const getAllOrders = () =>
  axios({
    url: NORMAL_END_POINT + 'orders',
    method: 'GET',
  });

export const getOrderById = orderNr =>
  axios({
    url: NORMAL_END_POINT + 'orders/' + orderNr,
    method: 'GET',
  });

export const createOrder = data =>
  axios({
    url: NORMAL_END_POINT + 'orders',
    method: 'POST',
    data,
  });

export const updateOrder = (id, data) =>
  axios({
    url: NORMAL_END_POINT + 'orders/' + id,
    method: 'PUT',
    data,
  });

export const getOrderDeliveryState = (state, orderId = '') =>
  axios({
    url: NORMAL_END_POINT + `orders/delivery_state/?current_state=${state}&order_id=${orderId}`,
    method: 'GET',
  });
