import { memo, useCallback, useMemo } from 'react';

import Text from 'components/own/Text';
import { parsePrice, propsAreEqual } from 'utils/util';
import { useHistory } from 'react-router';

const CellText = ({ custom, link, lineClamp = 3, render, value, ...rest }) => {
  const history = useHistory();
  const valueShow = useMemo(() => {
    if (render) return render(value);
    switch (custom) {
      case 'price':
        return value ? parsePrice(value) + ' đ' : '0';
      case 'array':
        return value?.join(', ');

      default:
        return value;
    }
  }, [render, value, custom]);

  const cursor = useMemo(() => (!!link ? 'pointer' : ''), [link]);

  const onClick = useCallback(() => {
    if (link) {
      history.push(link);
    }
  }, [history, link]);
  if (link) {
    return (
      <a href={link} rel="noreferrer" target="_self">
        <Text {...rest} variant="clamped" lineClamp={lineClamp}>
          {valueShow}
        </Text>
      </a>
    );
  }
  return (
    <Text {...rest} variant="clamped" lineClamp={lineClamp} onClick={onClick} cursor={cursor}>
      {valueShow}
    </Text>
  );
};

export default memo(CellText, propsAreEqual);
