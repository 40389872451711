import { AuthenticationContext } from 'context/Authentication/context';
import { useContextSelector } from 'use-context-selector';

const getUserInfo = ({ userInfo }) => userInfo;
const getActionNotification = ({ pushNotification }) => pushNotification;
const getActionUpdateProfile = ({ updateUserInfo }) => updateUserInfo;

export const useUserInfo = () => useContextSelector(AuthenticationContext, getUserInfo);
export const useUpdateProfile = () => useContextSelector(AuthenticationContext, getActionUpdateProfile);
export const usePushNotification = () => useContextSelector(AuthenticationContext, getActionNotification);
