import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportManagementInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'report_date',
      text: 'Ngày',
    },
    {
      dataField: 'branch',
      text: 'Chi nhánh',
    },
    {
      dataField: 'send_quantity',
      text: 'Số lượng hàng đi',
      headerStyle: {
        borderBottom: '3px solid #74788d',
      },
    },
    {
      dataField: 'send_dathu_fee',
      text: 'Đã thu hàng đi',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #74788d',
      },
    },
    {
      dataField: 'send_chuathu_fee',
      text: 'Chưa thu hàng đi',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #74788d',
      },
    },
    {
      dataField: 'send_total',
      text: 'Tổng cộng hàng đi',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #74788d',
      },
    },
    { dataField: '', text: '' },
    {
      dataField: 'receive_quantity',
      text: 'Số lượng hàng về',
      headerStyle: {
        borderBottom: '3px solid #556ee6',
      },
    },
    {
      dataField: 'receive_dathu_fee',
      text: 'Đã thu hàng về',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #556ee6',
      },
    },
    {
      dataField: 'receive_chuathu_fee',
      text: 'Chưa thu hàng về',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #556ee6',
      },
    },
    {
      dataField: 'receive_total',
      text: 'Tổng cộng hàng về',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
      headerStyle: {
        borderBottom: '3px solid #556ee6',
      },
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'TỔNG CỘNG HÀNG ĐI :', name: 'send_total' },
        { label: 'TỔNG CỘNG HÀNG VỀ :', name: 'receive_total' },
        {
          label: 'TỔNG DOANH THU AN PHÚ :',
          branchLabel: 'TỔNG DOANH THU CHI NHÁNH :',
          name: 'total_doanhthu',
          controlType: ControlTypes.LABEL_DEPEND_BRANCH,
        },
      ],
    },
  ],
  totalBlock: [
    { label: 'Tổng hàng đi :', name: 'send_quantity' },
    { label: 'Tổng hàng về :', name: 'receive_quantity' },
  ],
  defaultParams: { from_date: new Date().toLocaleDateString('en-ZA'), to_date: new Date().toLocaleDateString('en-ZA') },
  filterElements: [
    {
      name: 'from_date',
      from: 'from_date',
      to: 'to_date',
      controlType: ControlTypes.DATE_RANGE_PICKER,
    },
  ],
  title: 'DOANH THU QUẢN TRỊ NGÀY',
  showBranchFilter: true,
  showSearch: false,
  needApply: true,
  showPrint: false,
  detailUrl: '',
  url: NORMAL_END_POINT + 'reports/baocaoquantringay/',
  exportUrl: NORMAL_END_POINT + 'reports/baocaoquantringay/excel',
});
