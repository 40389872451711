import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const getAllExpressRef = () =>
  axios({
    url: NORMAL_END_POINT + 'esrs',
    method: 'GET',
  });

export const lookupExpressRef = query =>
  axios({
    url: NORMAL_END_POINT + 'esrs/esf/?' + query,
    method: 'GET',
  });

export const getExpressRefById = id =>
  axios({
    url: NORMAL_END_POINT + 'esrs/' + id,
    method: 'GET',
  });

export const createExpressRef = data =>
  axios({
    url: NORMAL_END_POINT + 'esrs',
    method: 'POST',
    data,
  });

export const updateExpressRef = (id, data) =>
  axios({
    url: NORMAL_END_POINT + 'esrs/' + id,
    method: 'PUT',
    data,
  });
