import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportPayoffCompanyInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  showSearch: true,
  columns: [
    {
      dataField: 'order_nr',
      text: 'Mã sản phẩm',
    },
    {
      dataField: 'from_branch',
      text: 'Nơi giao',
    },
    {
      dataField: 'to_branch',
      text: 'Nơi nhận',
    },
    {
      dataField: 'from_person_name',
      text: 'Tên người giao',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    {
      dataField: 'to_person_name',
      text: 'Tên người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
    {
      dataField: 'service_type',
      text: 'Loại hàng',
    },
    {
      dataField: 'quantity',
      text: 'SL',
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
    },
    {
      dataField: 'mc_fee',
      text: 'Phí MC',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'Tổng phí <b>MC</b> :', name: 'mc_fee' },
        { label: 'Tổng tiền <b>STPC</b> :', name: 'stpc_fee' },
        { label: 'Tổng tiền <b>đã thu</b> :', name: 'dathu_fee' },
        { label: 'Tổng tiền <b>chưa thu</b> :', name: 'chuathu_fee' },
        { label: 'Tổng tiền <b>Thu Hộ BH</b> :', name: 'thbh_fee' },
      ],
    },
    {
      items: [
        { label: 'Tổng tiền <b>TTN </b> :', name: 'ttn_fee' },
        { label: 'Tổng tiền <b>GTN </b> :', name: 'gtn_fee' },
        { label: 'Tổng tiền <b>Phí An Phú </b> :', name: 'anphu_fee' },
        { label: 'Tổng tiền <b>thu khác </b> :', name: 'other_fee' },
      ],
    },
  ],
  totalBlock: [
    { label: 'Tổng phiếu :', name: 'item_count' },
    { label: 'Tổng SLH', name: 'quantity_count' },
  ],
  defaultParams: { from_date: new Date().toLocaleDateString('en-ZA'), to_date: new Date().toLocaleDateString('en-ZA') },
  filterElements: [
    {
      label: 'Nạp Số Liệu',
      controlType: ControlTypes.APPLY_BUTTON,
    },
  ],
  needApply: true,
  showPrint: true,
  title: 'BÁO CÁO THANH LÝ CÔNG TY TRÊN 6 THÁNG',
  detailUrl: '',
  url: NORMAL_END_POINT + 'reports/hangthanhly/',
  exportUrl: NORMAL_END_POINT + 'reports/hangthanhly/excel',
});
