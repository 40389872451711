import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { produce } from 'immer';

import { Button, Card, Col, Container, Label, Row } from 'reactstrap';
import { cloneDeep, get } from 'lodash';

import { fields, getCurrentDate, initialOrderMoney } from 'mockups/order';
import { FieldInput } from 'components/own';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { KEY_ENTER, KEY_TAB } from 'constants/keycode';
import { getBranches } from 'services/branch';
import { ControlTypes } from 'constants/field';
import { getExpressServices } from 'services/express';
import { createOrder, getOrderById, getOrderDeliveryState, updateOrder } from 'services/order';
import { usePushNotification, useUserInfo } from 'hook/useContextSelector';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { DeliveryState, NORMAL_END_POINT, Role, ServiceMoneyId } from 'constants/common';
import { OrderMoneyTemplateComponent } from './PrintTemplateMoney';

const Root = styled(Container)`
  width: 100%;
  height: 100%;
  padding: 47px 160px;
  background-color: #fff;
  input {
    background-color: #f1f5f7;

    &:not(:placeholder-shown) {
      background-color: #fff;
    }
    &:read-only {
      &:not(.flatpickr-input) {
        background-color: #e0e0e0;
      }
    }
    &.flatpickr-input.disabled {
      background-color: #e0e0e0;
    }
  }
  .select2-selection__control--is-disabled {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: #505d69;
`;

const OrderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: rgb(220, 62, 21);
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const FieldContent = styled(Card)`
  background-color: #fff;
  padding: 26px;
  display: grid;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);

  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const GridContent = styled.div`
  display: grid;
  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Phiếu hàng hóa', link: '#' },
];

const initialState = {
  focusField: 'from_branch',
  options: {},
  loading: true,
  data: {},
};

const getOptions = (options = [], field, values) => {
  if (field.option !== 'branch') return options;
  let value = get(values, ['from_branch']);
  if (field.name === 'from_branch') {
    value = get(values, ['to_branch']);
  }
  return options.filter(_op => _op.value !== value);
};

const isReadOnly = (field, values, isEditPage, url, scope) => {
  if (scope !== Role.Admin && !isEditPage && field.name === 'from_branch') {
    return true;
  }
  return field.readOnly;
  // if (url === 'received_nr/')
  //   return (
  //     [
  //       'to_person.address',
  //       'to_person.cmnd',
  //       'thng_fee',
  //       'dcnn_fee',
  //       'luukho_fee',
  //       'thuho_fee',
  //       'delivery_date',
  //       'delivery_state',
  //       'ref_order_nr',
  //       'note',
  //     ].indexOf(field.name) === -1
  //   );
  // else if (isEditPage)
  //   return (
  //     [
  //       'from_person.address',
  //       'from_person.cmnd',
  //       'to_person.address',
  //       'to_person.cmnd',
  //       'service_type',
  //       'chuathu_fee',
  //       'delivery_address',
  //       'qnnt_fee',
  //       'mc_fee',
  //       'delivery_state',
  //       'ref_order_nr',
  //       'note',
  //     ].indexOf(field.name) === -1
  //   );
  // switch (field.name) {
  //   case 'chuathu_fee':
  //     if (values['dathu_fee']) return true;
  //     break;

  //   case 'dathu_fee':
  //     if (values['chuathu_fee']) return true;
  //     break;

  //   case 'thng_fee':
  //   case 'dcnn_fee':
  //     return true;

  //   default:
  //     return field.readOnly;
  // }
};

const countServiceFee = values => {
  const money = values.stpc_fee || 0;
  if (values.service_type === ServiceMoneyId.FAX) {
    if (money > 0 && money < 500000) {
      return 10000;
    } else if (money >= 500000 && money <= 1000000) {
      return 20000;
    } else if (money > 1000000 && money <= 2000000) {
      return 25000;
    } else if (money > 2000000 && money <= 5000000) {
      return 30000;
    } else if (money > 5000000 && money <= 10000000) {
      return 45000;
    } else if (money > 10000000) {
      const plus = Math.floor((money - 10000000) / 1000000);
      return 45000 + plus * 5000;
    }
  } else if (values.service_type === ServiceMoneyId.COD) {
    if (money > 0 && money < 500000) {
      return 10000;
    } else if (money >= 500000 && money <= 1000000) {
      return 15000;
    } else if (money > 1000000 && money <= 2000000) {
      return 20000;
    } else if (money > 2000000 && money <= 5000000) {
      return 25000;
    } else if (money > 5000000 && money <= 10000000) {
      return 40000;
    } else if (money > 10000000) {
      const plus = Math.floor((money - 10000000) / 1000000);
      return 40000 + plus * 4500;
    }
  }
  return 0;
};

const updateServiceFee = (values, setFieldValue) => {
  const service_fee = countServiceFee(values);
  setFieldValue('service_fee', service_fee);
  setFieldValue('total_fee', service_fee);
  if (values.other_fee_from_sender) {
    setFieldValue('thuho_fee', 0);
    setFieldValue('chuathu_fee', 0);
    setFieldValue('remain_fee', 0);
    setFieldValue('dathu_fee', service_fee);
    setFieldValue('stpc_remain_fee', values.stpc_fee - service_fee);
  } else {
    setFieldValue('dathu_fee', 0);
    setFieldValue('chuathu_fee', service_fee);
    setFieldValue('thuho_fee', service_fee);
    setFieldValue('remain_fee', service_fee);
    setFieldValue('stpc_remain_fee', values.stpc_fee);
  }
};

const OrderMoney = ({ url = '' }) => {
  const [{ focusField, options, data, loading }, setState] = useState(initialState);
  const params = useParams();
  const userInfo = useUserInfo();
  const templateRef = useRef();
  const pushNotification = usePushNotification();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        from_branch: yup.string().required('Vui lòng chọn chi nhánh'),
        to_branch: yup.string().required('Vui lòng chọn chi nhánh'),
        from_person: yup.object().shape({
          phone: yup.string().required('Vui lòng nhập số điện thoại'),
          fullname: yup.string().required('Vui lòng nhập họ tên'),
        }),
        to_person: yup.object().shape({
          phone: yup.string().required('Vui lòng nhập số điện thoại'),
          fullname: yup.string().required('Vui lòng nhập họ tên'),
        }),
        stpc_fee: yup.number().required('Vui lòng nhập STPC'),
        service_type: yup.string().required('Vui lòng chọn dịch vụ'),
      }),
    []
  );

  const isEditPage = useMemo(() => params.id !== 'new', [params.id]);

  const callAPIGet = useCallback(
    id => {
      getOrderById(url + id).then(res => {
        const d = get(res, ['data', 0], {});
        setState(
          produce(draft => {
            draft.loading = false;
            if (d)
              draft.data = {
                ...d,
                from_branch_name: d.from_branch?.branch_name || d.from_branch,
                from_branch_code: d.from_branch?.branch_code || d.from_branch,
                to_branch_code: d.to_branch?.branch_code || d.from_branch,
                to_branch_name: d.to_branch?.branch_name || d.to_branch,
                to_branch_phone: d.to_branch?.phone || d.to_branch,
                to_branch_address: d.to_branch?.address || d.to_branch,
                service_type_name: d.service_type?.service_detail || d.service_type,
                from_branch: d.from_branch?._id || d.from_branch,
                to_branch: d.to_branch?._id || d.to_branch,
                service_type: d.service_type?._id || d.service_type,
              };
            else draft.data = {};
          })
        );
      });
    },
    [url]
  );

  useEffect(() => {
    const ele = document.getElementById('form-reset-button');
    if (ele && params.id === 'new') ele.click();
  }, [params.id]);

  useEffect(() => {
    if (!userInfo) return;

    if (!isEditPage) {
      setState(
        produce(draft => {
          draft.loading = false;
          draft.data = { ...initialOrderMoney, from_branch: get(userInfo, ['branch', '$oid'], '') };
        })
      );
    } else {
      callAPIGet(params.id);
    }
  }, [callAPIGet, isEditPage, params.id, userInfo]);

  useEffect(() => {
    const callAPI = async () => {
      Promise.all([getBranches(), getExpressServices('/phieutien')]).then(res => {
        setState(
          produce(draft => {
            draft.options.branch = get(res, [0], [])?.map(_b => ({ label: _b.branch_name, value: get(_b, ['_id', '$oid']) }));
            draft.options.service = get(res, [1], [])?.map(_b => ({ label: _b.service_detail, value: get(_b, ['_id', '$oid']) }));
          })
        );
      });
    };
    callAPI();
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      getOrderDeliveryState(data?.delivery_state || '', data?.id).then(res => {
        setState(
          produce(draft => {
            draft.options.state = res?.map(_b => ({ label: _b, value: _b }));
          })
        );
      });
    };
    callAPI();
  }, [data?.delivery_state, data?.id]);

  const onChangeFocus = useCallback(field => {
    setState(
      produce(draft => {
        draft.focusField = field;
      })
    );
  }, []);

  const handleKeyDown = useCallback(
    e => {
      const currentIndex = fields.findIndex(_f => _f === focusField);
      const isLatest = currentIndex === fields.length - 1;
      switch (e.keyCode) {
        case KEY_ENTER:
        case KEY_TAB:
          e.preventDefault();
          if (currentIndex === -1 || isLatest) onChangeFocus(fields[0]);
          else onChangeFocus(fields[currentIndex + 1]);
          break;
        default:
          break;
      }
    },
    [focusField, onChangeFocus]
  );

  const infoFields = useMemo(
    () => ({
      person: [
        {
          title: 'Bên gửi',
          fields: [
            {
              name: 'from_branch',
              label: 'Chi nhánh gửi',
              controlType: ControlTypes.SELECT_PICKER,
              option: 'branch',
              placeholder: 'Chọn chi nhánh',
              isRequired: true,
            },
            {
              name: 'from_person.phone',
              label: 'SDT',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'from_person.fullname',
              label: 'Họ tên',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            { name: 'from_person.address', label: 'Địa chỉ' },
            { name: 'from_person.cmnd', label: 'CMND' },
          ],
        },
        {
          title: 'Bên nhận',
          fields: [
            {
              name: 'to_branch',
              label: 'Chi nhánh nhận',
              controlType: ControlTypes.SELECT_PICKER,
              option: 'branch',
              placeholder: 'Chọn chi nhánh',
              isRequired: true,
            },
            {
              name: 'to_person.phone',
              label: 'SDT',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'to_person.fullname',
              label: 'Họ tên',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            { name: 'to_person.address', label: 'Địa chỉ' },
            { name: 'to_person.cmnd', label: 'CMND' },
          ],
        },
      ],
      product: [
        {
          name: 'service_type',
          label: 'Loại Bì',
          isRequired: true,
          controlType: ControlTypes.SELECT_PICKER,
          option: 'service',
          placeholder: 'Chọn dịch vụ',
          countServiceFee: true,
        },
        { name: 'stpc_fee', label: 'STPC', controlType: ControlTypes.CURRENCY, countServiceFee: true, isRequired: true },
        {
          name: 'other_fee_from_sender',
          label: 'Cộng phí gửi tiền vào đã thu?',
          controlType: ControlTypes.CHECK_BOX,
          countServiceFee: true,
        },
        { controlType: ControlTypes.SPACE },
        { name: 'dathu_fee', label: 'Đã thu', controlType: ControlTypes.CURRENCY, readOnly: true },
        { name: 'chuathu_fee', label: 'Chưa thu', controlType: ControlTypes.CURRENCY, readOnly: true },
        { name: 'thuho_fee', label: 'Thu hộ', controlType: ControlTypes.CURRENCY, readOnly: true },
        { name: 'stpc_remain_fee', controlType: ControlTypes.CURRENCY, label: 'STPC còn lại', readOnly: true },

        { name: 'received_date', label: 'Ngày gửi', controlType: ControlTypes.DATE_PICKER },
        { name: 'delivery_date', label: 'Ngày hoàn tất', controlType: ControlTypes.DATE_PICKER, minDate: new Date() },
        { name: 'total_fee', label: 'Tổng cộng', controlType: ControlTypes.CURRENCY, readOnly: true },
        {
          name: 'remain_fee',
          label: 'Còn phải thu',
          readOnly: true,
          controlType: ControlTypes.CURRENCY,
        },
        {
          name: 'delivery_state',
          label: 'Tình trạng phiếu',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'state',
        },
        { name: 'ref_order_nr', label: 'Phiếu liên kết' },
      ],
    }),
    []
  );

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });

  const onSave = useCallback(
    (values, { resetForm }) => {
      const req = cloneDeep(values);
      if (req.delivery_state && req.delivery_state === DeliveryState.Done && !req.delivery_date) {
        req.delivery_date = getCurrentDate();
      }
      if (req.id) {
        updateOrder(req.id, req)
          .then(() => {
            callAPIGet(req.order_nr);
            pushNotification('success', 'Cập nhật phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Tạo không thành công');
          });
      } else {
        req.status = true;
        createOrder(req)
          .then(() => {
            resetForm();
            pushNotification('success', 'Tạo phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Cập nhật không thành công');
          });
      }
    },
    [callAPIGet, pushNotification]
  );

  if (loading) return <div />;

  return (
    <div className="page-content">
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      <Root fluid>
        <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, setValues, setFieldValue, submitCount, touched = {}, values = {} }) => (
            <>
              <Title className="justify-content-center">PHIẾU GỬI TIỀN</Title>
              {params.id !== 'new' ? <OrderTitle className="mb-3 justify-content-center">{params.id}</OrderTitle> : <div className="mb-3" />}
              <UserInfo>
                {infoFields.person.map(({ title, fields }, index) => (
                  <Col xs={12} key={index}>
                    <FieldContent>
                      <SubTitle>
                        <i className="ri-send-plane-fill me-2"></i>
                        {title}
                      </SubTitle>
                      {fields.map((field, i) => (
                        <Row key={i}>
                          <FieldInput
                            {...field}
                            error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                            value={get(values, field.name, '')}
                            autoFocus={focusField === field.name}
                            onKeyDown={handleKeyDown}
                            onFocus={() => onChangeFocus(field.name)}
                            onSelect={(val, item) => {
                              const itemValues = get(item, 'value', {});
                              const prefix = field.name.split('.')[0];
                              for (const key in itemValues) {
                                if (Object.hasOwnProperty.call(itemValues, key)) {
                                  const element = itemValues[key];
                                  setFieldValue(`${prefix}.${key}`, element);
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            readOnly={isReadOnly(field, values, isEditPage, url, userInfo?.scope)}
                            onChange={handleChange}
                            options={getOptions(options[field.option], field, values)}
                            placeholder={field.placeholder || `Nhập ${field.label}`}
                          />
                        </Row>
                      ))}
                    </FieldContent>
                  </Col>
                ))}
              </UserInfo>
              <FieldContent $templateColumn="1fr">
                <SubTitle className="d-flex align-items-center justify-content-center">
                  <i className="fas fa-info me-2"></i>
                  Thông tin đơn hàng
                </SubTitle>
                <div style={{ borderBottom: '1px solid #BDBDBD' }} />
                <GridContent $templateColumn="1fr 1fr">
                  {infoFields.product.map((field, i) => (
                    <Row key={i}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name, '')}
                        autoFocus={focusField === field.name}
                        onKeyDown={handleKeyDown}
                        readOnly={isReadOnly(field, values, isEditPage, url, userInfo?.scope)}
                        onFocus={() => onChangeFocus(field.name)}
                        onSelect={(val, item) => {
                          const itemValues = get(item, 'value', {});
                          const prefix = field.name.split('.')[0];
                          for (const key in itemValues) {
                            if (Object.hasOwnProperty.call(itemValues, key)) {
                              const element = itemValues[key];
                              setFieldValue(`${prefix}.${key}`, element);
                            }
                          }
                        }}
                        onBlur={e => {
                          if (field.countServiceFee) {
                            updateServiceFee(values, setFieldValue);
                          }

                          handleBlur(e);
                        }}
                        onChange={e => {
                          const newValues = { ...values, [e.target.name]: e.target.value };
                          if (field.countServiceFee) {
                            updateServiceFee(newValues, setFieldValue);
                          }
                          handleChange(e);
                        }}
                        options={options[field.option]}
                        placeholder={field.placeholder || `Nhập ${field.label}`}
                      />
                    </Row>
                  ))}
                </GridContent>
                <div className="mb-3">
                  <Label className="form-label">Note</Label>
                  <textarea className="form-control" rows="2" name="note" value={values.note || ''} onChange={handleChange}></textarea>
                </div>
              </FieldContent>

              <div className="d-flex align-items-center mt-3">
                <Button className="me-2" color="primary" onClick={handleSubmit}>
                  {isEditPage ? 'Cập nhật' : 'Lập phiếu'}
                </Button>
                <Button className="me-2" color="secondary" onClick={handlePrint}>
                  In phiếu
                </Button>
                {/* <Button color="primary">In phiếu con</Button> */}
              </div>
              <button className="d-none" onClick={() => setValues(initialOrderMoney)} id="form-reset-button"></button>
            </>
          )}
        </Formik>
      </Root>
      <div className="d-none">
        <OrderMoneyTemplateComponent data={data} ref={templateRef} />
      </div>
    </div>
  );
};

export default OrderMoney;
