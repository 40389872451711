import { useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { useHistory } from 'react-router';

import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Col, Row, Input } from 'reactstrap';
import { getAllOrders } from 'services/order';

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const initialState = { totalItem: 0, data: [] };

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Danh sách phiếu', link: '#' },
];

const OrderManagement = () => {
  const [{ totalItem, data }, setState] = useState(initialState);
  const history = useHistory();
  const pageOptions = useMemo(
    () => ({
      sizePerPage: 10,
      totalSize: data.length,
      custom: true,
    }),
    [data.length]
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'from_branch.branch_name',
        text: 'Chi nhánh đi',
        sort: true,
      },
      {
        dataField: 'to_branch.branch_name',
        text: 'Chi nhánh đến',
        sort: true,
      },
      {
        dataField: 'from_person.fullname',
        text: 'Người gửi',
        sort: true,
      },
      {
        dataField: 'to_person.fullname',
        text: 'Người nhận',
        sort: true,
      },
      {
        dataField: 'service_type.service_detail',
        text: 'Tên loại hàng',
        sort: true,
      },
      {
        dataField: 'total_fee',
        text: 'Tổng tiền',
        sort: true,
      },
      {
        dataField: 'order_nr',
        text: 'Thao tác',
        formatter: cell => (
          <Actions>
            {cell && (
              <IconButton onClick={() => history.push('order/' + cell)}>
                <i className="fas fa-pencil-alt"></i>
              </IconButton>
            )}
          </Actions>
        ),
      },
    ],
    [history]
  );

  useEffect(() => {
    getAllOrders().then(res => {
      setState(
        produce(draft => {
          draft.data = res;
          draft.totalItem = res.length;
        })
      );
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
        <div className="d-flex align-items-center justify-content-end">
          <Label className="me-2">{totalItem} mục</Label>
          <SearchBox type="text" className="form-control me-3" placeholder={'Nhập keyword'} />
        </div>

        <ContentTable className="">
          <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={data}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider keyField="id" columns={columns} data={data} search>
                {toolkitProps => (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={'id'}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={'table align-middle table-nowrap'}
                            headerWrapperClasses={'thead-light'}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </ContentTable>
      </div>
    </>
  );
};

export default OrderManagement;
