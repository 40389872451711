import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import NonAuthLayout from './components/NonAuthLayout';
import { AuthenticationProvider } from 'context/Authentication';

// Import scss
import './assets/scss/theme.scss';

//Fake backend
import { setUpApi } from 'utils/service';

// Activating fake backend
setUpApi();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Returns the layout
   */

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute exact path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute exact {...route} layout={AuthenticationProvider} key={idx} isAuthProtected={true} />
            ))}
            <Redirect from="*" to="/dashboard" />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
