import { useCallback, useMemo, useState, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import produce from 'immer';
import { get, remove, uniqBy } from 'lodash';

import Breadcrumbs from 'components/Common/Breadcrumb';
import { CommonManagement } from 'components/own';
import { usePushNotification } from 'hook/useContextSelector';

import { callUploadExcel } from 'services/common';

const Root = styled(Container)`
  width: 100%;
  min-height: calc(100vh - 45px - 96px - 60px);
  background-color: #fff;
  display: grid;
  grid-template-rows: 1fr max-content;

  .page-content {
    padding: 0 !important;
    min-height: unset !important;
  }

  input {
    background-color: #f1f5f7;

    &:not(:placeholder-shown) {
      background-color: #fff;
    }
    &:read-only {
      &:not(.flatpickr-input) {
        background-color: #e0e0e0;
      }
    }
    &.flatpickr-input.disabled {
      background-color: #e0e0e0;
    }
  }
  .select2-selection__control--is-disabled {
    background-color: #e0e0e0;
  }
`;
const DropZoneContainer = styled.div`
  height: 100%;
  padding: 32px;
  flex: 1;
`;

const DropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dashed #4f4f4f;
  height: ${({ $hasFile }) => ($hasFile ? '' : '100%')};
  transition: all 0.3s ease-in-out;

  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    /* Secondary */

    color: #505d69;

    b {
      color: #556ee6;
      text-decoration: underline;
    }
  }
`;

const RemoveItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;

  /* Primary */

  color: #556ee6;
  cursor: pointer;
`;

const UploadButton = styled.div`
  width: 120px;
  height: 40px;

  background: #1d6f42;
  border-radius: 20px;

  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  justify-content: center;

  /* White */

  color: #ffffff;
`;

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Phiếu VTSG Import', link: '#' },
];

const initialState = {
  files: [],
};
const ImportComponent = ({ url = 'imports/import/vtsg/hanggui/' }) => {
  const [state, setState] = useState(initialState);
  const pushNotification = usePushNotification();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  useEffect(() => {
    if (!!acceptedFiles.length) {
      setState(
        produce(draft => {
          draft.files = uniqBy([...draft.files, ...acceptedFiles], 'name');
        })
      );
    }
  }, [acceptedFiles]);

  const removeFile = useCallback(item => {
    setState(
      produce(draft => {
        remove(draft.files, item);
      })
    );
  }, []);

  const uploadExcel = useCallback(async () => {
    const res = await callUploadExcel(state.files, url);
    pushNotification('success', get(res, ['data', 0]));
    setState(
      produce(draft => {
        draft.files = [];
      })
    );
    const button = document.getElementById('refresh-common-management-button');
    if (button) button.click();
  }, [pushNotification, state.files, url]);

  const columns = useMemo(
    () => [
      {
        dataField: 'name',
        text: 'Tên file',
      },
      {
        dataField: 'path',
        text: 'Đường dẫn',
      },
      {
        dataField: 'name',
        text: '',
        formatter: (name, item) => <RemoveItemText onClick={() => removeFile(item)}>Gỡ file đính kèm</RemoveItemText>,
      },
    ],
    [removeFile]
  );

  return (
    <div className="page-content">
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      <Root fluid>
        <DropZoneContainer>
          <DropZone $hasFile={!!state.files.length} {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>
              Thả file Excel vào đây hoặc <b>browse</b>
            </p>
          </DropZone>
          {!!state.files.length && (
            <>
              <div className="mt-3"></div>
              <BootstrapTable keyField="id" bordered={false} data={state.files} columns={columns} />
              <UploadButton onClick={uploadExcel}>Nhập Excel</UploadButton>
            </>
          )}
        </DropZoneContainer>
        <CommonManagement hideIfEmpty />
      </Root>
    </div>
  );
};

export default ImportComponent;
