import { LOCAL_STORAGE__INFO, LOCAL_STORAGE__JWT } from 'constants/common';

export const setToken = token => {
  localStorage.setItem(LOCAL_STORAGE__JWT, token);
};

export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE__JWT);
};

export const getUserToken = () => {
  return localStorage.getItem(LOCAL_STORAGE__JWT);
};

export const getUserInfo = () => {
  const res = localStorage.getItem(LOCAL_STORAGE__INFO);
  try {
    if (res) return JSON.parse(res);
  } catch (error) {
    return null;
  }

  return null;
};

export const setUserInfo = info => {
  localStorage.setItem(LOCAL_STORAGE__INFO, JSON.stringify(info));
};
