export const ControlTypes = {
  LINE: 'LINE',
  LABEL_DEPEND_BRANCH: 'LABEL_DEPEND_BRANCH',
  DB_LINE: 'DB_LINE',
  SPACE: 'SPACE',
  SELECT_PICKER: 'SELECT_PICKER',
  SELECT_PICKER_WITH_APPLY: 'SELECT_PICKER_WITH_APPLY',
  CHECK_BOX: 'CHECK_BOX',
  DATE_PICKER: 'DATE_PICKER',
  DATE_PICKER_WITH_APPLY: 'DATE_PICKER_WITH_APPLY',
  DATE_RANGE_PICKER: 'DATE_RANGE_PICKER',
  APPLY_BUTTON: 'APPLY_BUTTON',
  YEAR_PICKER: 'YEAR_PICKER',
  CURRENCY: 'CURRENCY',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  INPUT_APPLY: 'INPUT_APPLY',
  STT: 'STT',
  RADIO_GROUP: 'RADIO_GROUP',
};
