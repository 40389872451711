import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { AUTH_END_POINT, NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { getStateBackground, getStateColor } from './report-branch';

export const getReportCargoInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  showSearch: true,
  columns: [
    {
      dataField: 'nr',
      text: 'STT',
      controlType: ControlTypes.STT,
      formatter: (cell, data, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      dataField: 'order_nr',
      text: 'Mã sản phẩm',
      sort: true,
    },
    {
      dataField: 'from_branch',
      text: 'Nơi giao',
    },
    {
      dataField: 'to_branch',
      text: 'Nơi nhận',
    },
    {
      dataField: 'from_person_name',
      text: 'Tên người giao',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    {
      dataField: 'to_person_name',
      text: 'Tên người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
    {
      dataField: 'service_type',
      text: 'Loại hàng',
      sort: true,
    },
    {
      dataField: 'quantity',
      text: 'SL',
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
    },
    {
      dataField: 'delivery_state',
      text: 'Trạng thái',
      sort: true,
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus dataField="delivery_state" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },
  ],
  defaultParams: { report_date: new Date().toLocaleDateString('en-ZA') },
  filterElements: [
    {
      name: 'to_branch',
      width: '140px',
      controlType: ControlTypes.SELECT_PICKER_WITH_APPLY,
      placeholder: 'Chi nhánh nhận',
      button: {
        title: 'Nạp',
      },
      optionEmpty: true,
      optionUrl: {
        url: AUTH_END_POINT + 'branchs',
        keyLabel: 'branch_name',
        keyValue: '_id.$oid',
      },
    },
    {
      name: 'report_date',
      width: 200,
      controlType: ControlTypes.DATE_PICKER_WITH_APPLY,
      placeholder: 'Chọn ngày',
    },
  ],
  totalBlock: [
    { label: 'Tổng phiếu : ', name: 'item_count' },
    { label: 'Số lượng : ', name: 'quantity_count' },
  ],
  showBranchFilter: true,
  selectable: true,
  needApply: true,
  showPrint: true,
  title: 'LỆNH CHUYỂN HÀNG CHI NHÁNH',
  detailUrl: '',
  url: NORMAL_END_POINT + 'reports/chuyenhang/',
  exportUrl: NORMAL_END_POINT + 'reports/chuyenhang/excel/',
  updateUrl: NORMAL_END_POINT + 'reports/lenhchuyen/update/',
});
