import { Role } from 'constants/common';
import { useUserInfo } from 'hook/useContextSelector';

// TODO: Snackbar on redirect.
export const AccountantGuard = ({ children }) => {
  const userInfo = useUserInfo();

  if (!userInfo?.scope || userInfo?.scope !== Role.Accountant) return <div />;

  return children;
};
