import styled from 'styled-components';
import LogoAnPhu from 'assets/images/logo-anphu-2.png';
import LogoVantaiSG from 'assets/images/logo-vantai-sg.png';
import QRCode from 'react-qr-code';
import React from 'react';
import { get } from 'lodash';
import { parseDateTime, parsePrice } from 'utils/util';
import { URL_SEARCH } from 'constants/common';

const Root = styled.div`
  width: 561.5px;
  height: 796px;
  background-color: #fff;
  padding: 20px;
  font-family: auto;
  color: #000;
  display: flex;
  flex-direction: column;
`;

const LogoIcon = styled.div`
  margin-right: 5%;
`;

const Title = styled.div`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
  text-overflow: ellipsis;
  line-height: ${({ lineHeight }) => lineHeight || ''};
`;

const SubTitle = styled.div`
  font-size: 13px;
`;

const InfoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
  margin: 12px 0;
`;

const InfoBlock = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  padding: 8px;
  border: 2px dashed #656565;
  border-radius: 6px;
`;

const CreateDateBlock = styled.div`
  margin-top: 5px;
  height: 20px;
  width: 200px;
  margin-left: auto;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 100px 50px 100px 50px 100px 1fr;
  grid-gap: 4px;
  align-items: center;
`;

const LargeContent = styled.div`
  display: grid;
  grid-template-columns: 100px 208px 100px 1fr;
  grid-gap: 4px;
  align-items: center;
`;

const FullContent = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-gap: 4px;
`;

const Signature = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const infoFields = [
  {
    title: 'block-1',
    fields: [
      { title: 'NƠI GỬI:', value: 'from_branch_name' },
      { title: 'Người gửi:', value: 'from_person.fullname' },
      { title: 'Số điện thoại:', value: 'from_person.phone' },
    ],
  },
  {
    title: 'block-2',
    fields: [
      { title: 'NƠI NHẬN:', value: 'to_branch_name' },
      { title: 'Người nhận:', value: 'to_person.fullname' },
      { title: 'Số điện thoại:', value: 'to_person.phone' },
    ],
  },
];

const OrderFieldData = ({ title, value }) => (
  <>
    <Title fontSize="10px" fontWeight={400} uppercase>
      {title}
    </Title>
    <Title fontSize="10px">{value}</Title>
  </>
);

export const OrderTemplateComponent = React.forwardRef(({ data = {} }, ref) => {
  return (
    <Root ref={ref}>
      <div className="d-flex">
        <LogoIcon>
          <img src={LogoAnPhu} alt="" width={100} />
        </LogoIcon>
        <div className="d-flex flex-column flex-1 align-items-center justify-content-center">
          <div className="d-flex align-items-center ">
            <Title>AN PHÚ EXPRESS </Title>
            <Title fontSize="16px" className="mx-1">
              | {`Logistics & Busline`}
            </Title>
          </div>
          <Title fontSize="16px">Chi nhánh {data?.to_branch_name}</Title>
          <Title fontSize="14px">Sđt : {data?.to_branch_phone}</Title>
          <Title fontSize="11px" className="text-center">
            {data?.to_branch_address}
          </Title>
          <Title className="text-center mt-4" fontSize="20px" fontWeight={800}>
            PHIẾU HÀNG HÓA
          </Title>
          <Title className="text-center mt-2" fontSize="20px" fontWeight={800}>
            MSP: {data?.order_nr}
          </Title>
        </div>
        <div style={{ width: 100 }}>
          <Title fontSize="10px" style={{ color: '#656565' }}>
            The Main Partner Of
          </Title>
          <LogoIcon>
            <img src={LogoVantaiSG} alt="" width={100} />
          </LogoIcon>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <QRCode value={URL_SEARCH + data?.order_nr || ''} size={100} />
          </div>
        </div>
      </div>

      <InfoContent>
        {infoFields.map(_b => (
          <InfoBlock key={_b.title}>
            {_b.fields?.map((_f, index) => (
              <React.Fragment key={index}>
                <SubTitle>{_f.title}</SubTitle>
                <Title fontSize="14px" uppercase>
                  {get(data, _f.value)}
                </Title>
              </React.Fragment>
            ))}
          </InfoBlock>
        ))}
      </InfoContent>
      <Content>
        <OrderFieldData title="LOẠI HÀNG:" value={get(data, 'service_type_name', '')} />
        <OrderFieldData title="SỐ LƯỢNG:" value={get(data, 'quantity', 0)} />
        <OrderFieldData title="PHÍ MC:" value={parsePrice(get(data, 'mc_fee', ''))} />
      </Content>
      <Content>
        <OrderFieldData title="ĐÃ THU:" value={parsePrice(get(data, 'dathu_fee', ''))} />
        <OrderFieldData title="CHƯA THU:" value={parsePrice(get(data, 'chuathu_fee', ''))} />
        <OrderFieldData title="TIỀN TTN:" value={parsePrice(get(data, 'ttn_fee', ''))} />
      </Content>
      <LargeContent>
        <OrderFieldData title="ĐỊA CHỈ GTN:" value={get(data, 'delivery_address', '') || ''} />
        <OrderFieldData title="GTN:" value={parsePrice(get(data, 'delivery_fee', ''))} />
      </LargeContent>
      <Content>
        <OrderFieldData title="THU HỘ BH:" value={parsePrice(get(data, 'thbh_fee', ''))} />
        <OrderFieldData title="PHÍ An Phú:" value={parsePrice(get(data, 'qnnt_fee', ''))} />
        <OrderFieldData title="THU KHÁC:" value={parsePrice(get(data, 'other_fee', ''))} />
      </Content>
      <Content>
        <OrderFieldData title="TỔNG CỘNG:" value={parsePrice(get(data, 'total_fee', ''))} />
        <OrderFieldData title={`${get(data, 'from_branch_name', '')} THU:`} value={parsePrice(get(data, 'benguithu_fee', ''))} />
        <OrderFieldData
          title={`${get(data, 'to_branch_name', '')} THU:`}
          value={parsePrice(get(data, 'remain_fee', '') || get(data, 'chuathu_fee', ''))}
        />
      </Content>
      <FullContent>
        <OrderFieldData title="GHI CHÚ:" value={get(data, 'note', '')} />
      </FullContent>
      <CreateDateBlock>{parseDateTime(data?.received_date)}</CreateDateBlock>
      <Signature>
        <Title fontSize="14px" fontWeight="700" className="text-center">
          Người nhận
        </Title>
        <Title fontSize="14px" fontWeight="700" className="text-center">
          Nhân viên trả hàng
        </Title>
        <Title fontSize="14px" fontWeight="700" className="text-center">
          Nhân viên lập phiếu
        </Title>
      </Signature>
      <div style={{ flex: 1 }}></div>
      <Title fontSize="14px">CHÚ Ý: KIỂM TRA THÔNG TIN TRƯỚC KHI RA KHỎI QUẦY, MỌI KHIẾU NẠI VỀ SAU SẼ KHÔNG ĐƯỢC GIẢI QUYẾT.</Title>
      <SubTitle>
        1/ Hàng gửi phải có Hóa đơn. Không nhận hàng quốc cấm, dễ cháy, nổ và các chất gây nguy hiểm. Khi nhận hàng khách phải mang theo CMND và
        giấy giới thiệu nếu là cơ quan, doanh nghiệp...
      </SubTitle>
      <SubTitle>2/ Không giải quyết khiếu nại nếu quý khách nhận hàng sau 7 ngày và sau khi rời công ty.</SubTitle>
      <SubTitle>
        3/ Hàng hóa bị mất mát. Công ty sẽ bồi thường tối đa 20 lần phí gửi; không bồi thường hàng dễ vỡ, chất lỏng, thực phẩm để qua đêm.
      </SubTitle>
      <Title fontSize="60px" fontWeight="700" lineHeight="70px" uppercase className="w-100 text-center">
        {data?.from_branch_code} - {data?.to_branch_code} {get(data, 'order_nr', '').slice(-4)} - {get(data, 'quantity', 0)}
      </Title>
    </Root>
  );
});
