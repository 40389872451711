import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';
import { sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { guid, parseDate } from 'utils/util';
const columns = [
  {
    dataField: 'id',
    text: '#',
    formatter: (cell, data, i) => i + 1,
  },
  {
    dataField: 'received_date',
    text: 'Ngày',
    formatter: cell => parseDate(cell),
    sort: true,
  },
  {
    dataField: 'branch',
    text: 'Chi nhánh',
    sort: true,
  },
  {
    dataField: 'count',
    text: 'Phiếu chưa xử lý',
    sort: true,
  },
];

const RemindOrder = ({ branchSelected }) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const total = useMemo(() => sumBy(data, 'count'), [data]);
  useEffect(() => {
    axios.post(NORMAL_END_POINT + 'reports/remind/').then(res => {
      setData(res.map(_r => ({ ..._r, id: guid() })));
    });
  }, []);

  const pageOptions = useMemo(
    () => ({
      sizePerPage: 10,
      totalSize: data.length, // replace later with size(customers),
      custom: true,
    }),
    [data.length]
  );

  const routerToPage = useCallback(
    (d = {}) => {
      history.push(`/report-cargo?branch=${d.branch_id}&report_date=${d.received_date}`); //
    },
    [history]
  );

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-4">Danh sách phiếu chưa xử lý</h4>
          <h4 className="card-title mb-4">Tổng phiếu : {total}</h4>
        </div>
        <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={data}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider keyField="id" columns={columns} data={data} search>
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={'id'}
                          responsive
                          bordered={false}
                          striped={false}
                          rowEvents={{
                            onClick: (e, d) => routerToPage(d),
                          }}
                          classes={'table align-middle table-nowrap table-row-click'}
                          headerWrapperClasses={'thead-light'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default RemindOrder;
