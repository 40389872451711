import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';
import { getStateBackground, getStateColor } from './report-branch';

export const getReportRefundAccountantInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'order_nr',
      text: 'Mã sản phẩm',
    },
    {
      dataField: 'from_branch',
      text: 'Nơi giao',
    },
    {
      dataField: 'to_branch',
      text: 'Nơi nhận',
    },
    {
      dataField: 'ref_order_nr',
      text: 'Phiếu liên kết',
    },
    {
      dataField: 'from_person_name',
      text: 'Tên người giao',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    {
      dataField: 'to_person_name',
      text: 'Tên người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
    {
      dataField: 'service_type',
      text: 'Loại hàng',
    },
    {
      dataField: 'quantity',
      text: 'SL',
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
    },
    {
      dataField: 'mc_fee',
      text: 'Phí MC',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'stpc_fee',
      text: 'STPC',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'dathu_fee',
      text: 'Đã thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'chuathu_fee',
      text: 'Chưa thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thbh_fee',
      text: 'Thu hộ BH',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'ttn_fee',
      text: 'Tiền TTN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'gtn_fee',
      text: 'Tiền GTN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'anphu_fee',
      text: 'Phí AP',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'other_fee',
      text: 'Thu khác',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'gtn_address',
      text: 'Địa chỉ GTN',
    },
    {
      dataField: 'to_person_address',
      text: 'Địa chỉ người nhận',
    },
    {
      dataField: 'to_person_cmnd',
      text: 'CMND người nhận',
    },
    {
      dataField: 'received_date',
      text: 'Ngày G',
    },
    {
      dataField: 'done_date',
      text: 'Ngày HT',
    },
    {
      dataField: 'delivery_state',
      text: 'Trạng thái',
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus dataField="delivery_state" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },
    {
      dataField: 'order_created_by',
      text: 'Tên NVLP',
    },
    {
      dataField: 'delivered_by',
      text: 'Tên NVTH',
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'Tổng phí <b>MC</b> :', name: 'mc_fee' },
        { label: 'Tổng tiền <b>STPC</b> :', name: 'stpc_fee' },
        { label: 'Tổng tiền <b>đã thu</b> :', name: 'dathu_fee' },
        { label: 'Tổng tiền <b>chưa thu</b> :', name: 'chuathu_fee' },
        { label: 'Tổng tiền <b>Thu Hộ BH</b> :', name: 'thbh_fee' },
      ],
    },
    {
      items: [
        { label: 'Tổng tiền <b>TTN </b> :', name: 'ttn_fee' },
        { label: 'Tổng tiền <b>GTN </b> :', name: 'gtn_fee' },
        { label: 'Tổng tiền <b>Phí An Phú </b> :', name: 'anphu_fee' },
        { label: 'Tổng tiền <b>thu khác </b> :', name: 'other_fee' },
      ],
    },
  ],
  totalBlock: [
    { label: 'Tổng phiếu :', name: 'item_count' },
    { label: 'Tổng SLH', name: 'quantity_count' },
  ],
  defaultParams: { from_date: new Date().toLocaleDateString('en-ZA'), to_date: new Date().toLocaleDateString('en-ZA') },
  filterElements: [
    {
      name: 'from_date',
      from: 'from_date',
      to: 'to_date',
      controlType: ControlTypes.DATE_RANGE_PICKER,
    },
  ],
  title: 'BÁO CÁO HÀNG TRẢ CHI NHÁNH',
  showBranchFilter: true,
  showSearch: true,
  branchOnly: true,
  needApply: true,
  searchServerSide: true,
  showPrint: true,
  detailUrl: '',
  url: NORMAL_END_POINT + 'reports/hangtracn/',
  exportUrl: NORMAL_END_POINT + 'reports/hangtracn/excel',
});
