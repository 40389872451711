import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, loginUserSuccessful } from './actions';

// AUTH related methods
import { postLogin } from 'services/auth';
import { LOCAL_STORAGE__JWT } from 'constants/common';
import { setToken } from 'utils/localStorage';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
  try {
    const url = process.env.REACT_APP_AUTH_END_POINT + 'token';
    const response = yield call(postLogin, url, {
      username: user.username,
      password: user.password,
    });
    setToken(response.access_token);
    yield put(loginUserSuccessful(response));
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError('Incorrect username or password'));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(LOCAL_STORAGE__JWT);

    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default loginSaga;
