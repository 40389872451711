export const LOCAL_STORAGE__JWT = 'AnPhuExpress__token';
export const LOCAL_STORAGE__INFO = 'AnPhuExpress__info';

export const AUTH_END_POINT = process.env.REACT_APP_AUTH_END_POINT;
export const NORMAL_END_POINT = process.env.REACT_APP_API_ENDPOINT;
export const URL_SEARCH = process.env.REACT_APP_URL_SEARCH;

export const HTTP_CODE = {
  InternalServerError: 500,
  Unauthorized: 401,
  Succeeded: 200,
  Created: 201,
};

export const OrderType = {
  NoOrder: 0,
  Asc: 1,
  Desc: 2,
};

export const Role = {
  Admin: 'Admin',
  User: 'User',
  Accountant: 'Accountant',
};

export const DeliveryState = {
  New: 'Chưa xử lý',
  Receive: 'Đến nơi nhận',
  Done: 'Hoàn tất',
  Liquidation: 'Hàng thanh lý',
  Back: 'Quay về nơi gửi',
  Forwarding: 'Chuyển tiếp nơi nhận',
  Cancel: 'Hủy phiếu',
  CongNo: 'Công nợ',
};

export const CustomerOrderStatus = {
  New: 'Mới tạo',
  Inprogress: 'Đang xử lý',
  Done: 'Hoàn tất',
  Cancel: 'Hủy',
};

export const ServiceMoneyId = {
  COD: '61c9441fce70ab072707797a',
  FAX: '61c958848c5b3011fea5ffa1',
};
