import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { NORMAL_END_POINT } from 'constants/common';
import { parsePrice } from 'utils/util';
import { getStateBackground, getStateColor } from './report-branch';

export const getLookupInfo = () => ({
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'created_date',
      text: 'Ngày',
      columnStatus: true,
    },
    {
      dataField: 'order_status',
      text: 'Trạng thái',
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus dataField="order_status" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },
    {
      dataField: 'weight',
      text: 'Trọng lượng',
    },
    {
      dataField: 'quantity',
      text: 'SL',
    },
    {
      dataField: 'kichthuoc.0.0',
      text: 'Chiều dài',
    },
    {
      dataField: 'kichthuoc.0.1',
      text: 'Chiều rộng',
    },
    {
      dataField: 'estimate_fee',
      text: 'Cước ước tính',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'from_person',
      text: 'Người gửi',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    // {
    //   dataField: 'location',
    //   text: 'Văn phòng giao nhận',
    // },
    {
      dataField: 'to_person',
      text: 'Người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
  ],
  summaryBlock: [],
  selectable: false,
  showSearch: true,
  title: 'DANH SÁCH TRA CỨU',
  hideBreadcrumb: true,
  detailUrl: 'services',
  url: NORMAL_END_POINT + 'customer_orders/list_estimate/',
});
