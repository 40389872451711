import { NORMAL_END_POINT } from 'constants/common';

export const getCustomerInfo = () => ({
  buttonAddTitle: '',
  showSearch: true,
  columns: [
    {
      dataField: 'phone',
      text: 'Số điện thoại',
    },
    {
      dataField: 'fullname',
      text: 'Họ và tên',
    },
    {
      dataField: 'cmnd',
      text: 'CMND/CCCD',
    },

    {
      dataField: 'address',
      text: 'Địa chỉ',
    },
    // {
    //   dataField: '_id.$oid',
    //   text: 'Thao tác',
    //   formatter: cell => <CellAction value={cell} detailUrl={'users'} />,
    // },
  ],
  selectable: false,
  title: 'Danh sách khách hàng',
  detailUrl: 'customers',
  url: NORMAL_END_POINT + 'customers',
  exportUrl: NORMAL_END_POINT + 'customers/excel/',
  exportMethod: 'GET',
});
