import { find } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Col, Label } from 'reactstrap';
import { propsAreEqual } from 'utils/util';

const SelectPicker = ({ autoFocus, error, isRequired, label, name, onChange, options, value, ...rest }) => {
  const selectedValue = useMemo(() => (value ? find(options, ['value', value]) : null), [options, value]);
  const handleSelectGroup = useCallback(
    item => {
      const split = name.split('.');
      if (split.length > 1) onChange({ target: { name: split[0], value: item } });
      else onChange({ target: { name, value: item.value } });
    },
    [name, onChange]
  );
  const customStyles = useMemo(
    () => ({
      control: provided => ({
        ...provided,
        borderColor: !!error ? '#ff3d60 !important' : 'hsl(0, 0%, 80%)',
        boxShadow: !!error ? 'unset !important' : '',
      }),
    }),
    [error]
  );

  return (
    <>
      <Label className="col-md-12 col-form-label">
        {label} {isRequired && <b style={{ color: '#DC3E15' }}>*</b>}
      </Label>
      <Col md={12}>
        <Select
          {...rest}
          classNamePrefix="select2-selection"
          styles={customStyles}
          onChange={handleSelectGroup}
          options={options}
          value={selectedValue}
          isDisabled={rest.readOnly}
        />
      </Col>
    </>
  );
};

export default memo(SelectPicker, propsAreEqual);
