import { memo, useCallback, useMemo } from 'react';

import styles from './ProfileDialog.module.scss';
import { cloneDeep, get } from 'lodash';
import * as yup from 'yup';

import { Modal, Row } from 'reactstrap';
import { FieldInput } from 'components/own';
import { Formik } from 'formik';

const initialValues = {
  current_pass: '',
  new_pass: '',
  new_pass_confirm: '',
};

const PasswordDialog = ({ onHide, onUpdate, open }) => {
  const onSave = useCallback(
    values => {
      onUpdate(cloneDeep(values));
    },
    [onUpdate]
  );

  const account = useMemo(
    () => [
      {
        name: 'current_pass',
        label: 'Mật khẩu cũ',
      },
      {
        name: 'new_pass',
        label: 'Mật khẩu mới',
      },
      {
        name: 'new_pass_confirm',
        label: 'Xác nhận mật khẩu',
      },
    ],
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        current_pass: yup.string().required('Vui lòng nhập mật khẩu cũ.'),
        new_pass: yup.string().required('Vui lòng nhập mật khẩu mới.'),
        new_pass_confirm: yup.string().when('new_pass', {
          is: val => !!val && !!val.length,
          then: yup
            .string()
            .required('Vui lòng xác nhận mật khẩu.')
            .oneOf([yup.ref('new_pass')], 'Mật khẩu không giống nhau.'),
        }),
      }),
    []
  );

  return (
    <Modal size="l" centered={true} isOpen={open} toggle={onHide}>
      <div className={styles.popup__header}>
        <i className="ri-lock-password-line me-2"></i>
        Đổi mật khẩu
      </div>
      {open && (
        <Formik initialValues={initialValues} onSubmit={onSave} validationSchema={validationSchema}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
            <>
              <div className={styles.popup__body}>
                {account.map((field, index) => (
                  <Row key={index}>
                    <FieldInput
                      type="password"
                      {...field}
                      error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                      value={get(values, field.name)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={field.placeholder || field.label}
                    />
                  </Row>
                ))}
              </div>

              <div className={styles.popup__button} onClick={handleSubmit}>
                Đổi mật khẩu
              </div>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default memo(PasswordDialog);
