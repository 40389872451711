import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 8px;

  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: center;

  /* Primary/Main Color */

  background-color: #556ee6;
  color: #fff;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
  height: ${({ size }) => (size ? size + 'px' : '32px')};
`;

const ButtonPrimary = ({ children, ...rest }) => <Root {...rest}>{children}</Root>;

export default ButtonPrimary;
