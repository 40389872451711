import { memo, useCallback } from 'react';
import Flatpickr from 'react-flatpickr';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';
import 'flatpickr/dist/themes/material_blue.css';

const DatePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;
  background: #fff;

  width: ${({ width }) => width || '100%'};
  height: 32px;
  padding: 0 8px;

  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 4px;
`;

const CustomDatePicker = ({ label, name, onChange, placeholder = '', readOnly = false, value, width, minDate }) => {
  const handleSelect = useCallback(
    (date, value) => {
      const split = name.split('.');
      if (split.length > 1) onChange({ target: { name: split[0], value } });
      else onChange({ target: { name, value: value } });
    },
    [name, onChange]
  );

  return (
    <>
      <Label className="col-md-12 col-form-label">{label}</Label>
      <Col md={12}>
        <DatePicker
          width={width}
          value={value}
          className={readOnly ? 'disabled me-2' : 'me-2'}
          disabled={readOnly}
          placeholder={placeholder}
          options={{
            dateFormat: 'Y/m/d H:i:S',
            enableTime: true,
            minDate,
            defaultHour: new Date().getHours(),
            defaultMinute: new Date().getMinutes(),
          }}
          onChange={handleSelect}
        />
      </Col>
    </>
  );
};

export default memo(CustomDatePicker, propsAreEqual);
