import CellAction from 'components/own/Table/CellRendering/CellAction';
import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { NORMAL_END_POINT } from 'constants/common';

export const getExpressServiceInfo = () => ({
  buttonAddTitle: 'Thêm dịch vụ',
  columns: [
    {
      dataField: 'service_type',
      text: 'Loại dịch vụ',
    },
    {
      dataField: 'service_detail',
      text: 'Mô tả',
    },
    {
      dataField: 'service_code',
      text: 'Code',
    },
    {
      dataField: 'status',
      text: 'Tình trạng',
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus
          dataField="status"
          data={data}
          render={value => (value ? 'Hoạt động' : 'Không hoạt động')}
          background={value => (value ? 'rgba(0, 119, 112, 0.1)' : 'rgba(232, 0, 0, 0.1)')}
          color={value => (value ? '#556ee6' : '#E80000')}
          width="120px"
        />
      ),
    },
    {
      dataField: 'created_date',
      text: 'Ngày tạo',
    },
    {
      dataField: '_id.$oid',
      text: 'Thao tác',
      formatter: cell => <CellAction value={cell} detailUrl={'services'} />,
    },
  ],
  selectable: false,
  showSearch: true,
  title: 'Danh sách dịch vụ',
  detailUrl: 'services',
  url: NORMAL_END_POINT + 'express_services',
});
