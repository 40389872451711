import Breadcrumbs from 'components/Common/Breadcrumb';
import { FilterInput } from 'components/own/Filter';
import { AUTH_END_POINT, Role } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { useUserInfo } from 'hook/useContextSelector';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { callGetAPI } from 'services/common';
import styled from 'styled-components';
import DailyRevenueAnalytics from './DailyRevenueAnalytics';
import MonthlyRevenueAnalytics from './MonthlyRevenueAnalytics';
import RemindOrder from './RemindOrder';

const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: #000000;
`;

const SubTitle = styled.div`
  flex: 1;

  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
`;

const SearchContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Trang chủ', link: '#' },
];

const initialBranch = {
  address: 'E1.30 Celadon City , 36 Bờ bao tân thắng, P. Sơn Kỳ, Quận Tân Phú',
};
const Home = () => {
  const info = useUserInfo();
  const [branchInfo, setBranchInfo] = useState(initialBranch);
  const [branchSelected, setBranchSelected] = useState(null);

  const getStatistic = useCallback((branch = '') => {}, []);

  useEffect(() => {
    if (info?.scope !== Role.Admin && get(info, 'branch.$oid')) {
      callGetAPI(AUTH_END_POINT + 'branchs/id/' + get(info, 'branch.$oid')).then(res => {
        setBranchInfo(res);
      });
    }

    if (info && (info?.scope === Role.Admin || info.scope === Role.Accountant)) {
      setBranchSelected('');
    } else if (info && info?.scope !== Role.Admin) {
      setBranchSelected(get(info, 'branch.$oid'));
    }
  }, [info]);

  if (!info) return <div></div>;

  return (
    <div className="page-content">
      <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

      <Title>An Phú Express - Trụ sở Tân Phú</Title>
      <div className="d-flex position-relative mb-5">
        <SubTitle>{branchInfo.address}</SubTitle>

        {(info?.scope === Role.Admin || info.scope === Role.Accountant) && (
          <SearchContainer>
            <FilterInput
              optionEmpty
              controlType={ControlTypes.SELECT_PICKER_WITH_APPLY}
              name={'from_branch'}
              optionUrl={{ url: AUTH_END_POINT + 'branchs', keyLabel: 'branch_name', keyValue: '_id.$oid' }}
              placeholder="CÔNG TY"
              button={{
                title: 'Nạp',
              }}
              value={branchSelected}
              onChange={e => setBranchSelected(e.target.value)}
              onApply={() => getStatistic(branchSelected)}
            />
          </SearchContainer>
        )}
      </div>
      <Row className="p-2">
        <Col xl={8}>
          <Row>
            <DailyRevenueAnalytics branchSelected={branchSelected} />
          </Row>
          <Row>
            <MonthlyRevenueAnalytics branchSelected={branchSelected} />
          </Row>
        </Col>
        <Col xl={4}>
          <RemindOrder branchSelected={branchSelected} />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
