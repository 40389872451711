import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import { NORMAL_END_POINT } from 'constants/common';
import { get } from 'lodash';
import produce from 'immer';
import { map } from 'lodash';
import { parsePrice } from 'utils/util';
import { LoadingIcon } from 'components/icons';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
`;

const MonthlyRevenueAnalytics = ({ branchSelected }) => {
  const [state, setState] = useState({
    series: [
      {
        name: 'Thu',
        type: 'column',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: true,
        },
      },
      stroke: {
        width: [0, 3],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: ['#556EE6'],
      labels: [],
      yaxis: {
        labels: {
          formatter: val => parsePrice(val) + ' đ',
        },
      },
    },
    loading: true,
  });

  useEffect(() => {
    if (branchSelected === null) return;
    setState(
      produce(draft => {
        draft.loading = true;
      })
    );
    axios
      .post(NORMAL_END_POINT + 'reports/doanhthuthang/', {
        year: new Date().getFullYear(),
        branch: branchSelected,
      })
      .then(res => {
        const data = get(res, ['data', 0, 'items']);

        setState(
          produce(draft => {
            draft.series[0].data = map(data, 'total_dathu_thcn');
            draft.options.labels = map(data, 'report_month');
            draft.loading = false;
          })
        );
      });
  }, [branchSelected]);

  if (state.loading)
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Hoạt động kinh doanh tháng</h4>
          <LoadingContainer>
            <LoadingIcon />
          </LoadingContainer>
        </CardBody>
      </Card>
    );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Hoạt động kinh doanh tháng</h4>
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart options={state.options} series={state.series} type="bar" height="280" />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyRevenueAnalytics;
