import { DeliveryState, ServiceMoneyId } from 'constants/common';
import { replace } from 'lodash';

export const getCurrentDate = () => {
  const date = new Date();
  return replace(date.toLocaleString('en-ZA'), ',', '');
};

export const initialOrderProduct = {
  from_person: {
    fullname: '',
    phone: '',
    address: '',
    cmnd: '',
  },
  to_person: {
    fullname: '',
    phone: '',
    address: '',
    cmnd: '',
  },
  service_type: '', //
  from_branch: '', //
  to_branch: '', //
  // detail: '',
  quantity: 1, //Số lượng
  ttn_fee: 0, // thu tận nơi
  thbh_fee: 0, // Thu hộ BH
  dathu_fee: 0, // Đã thu
  chuathu_fee: 0, //Chưa thu
  discount: 0, // Giảm giá
  other_fee: 0, // Thu khác
  delivery_fee: 0, // Phí GTN
  delivery_address: '', // Địa chỉ GTN
  thng_fee: 0, //Thu THNG
  dcnn_fee: 0, //Thu ĐCNN
  luukho_fee: 0, // Phí lưu kho
  thuho_fee: 0, //thu hộ
  qnnt_fee: 0, // Phí Quy Nhơn
  mc_fee: 0, // Phí màng co
  received_date: getCurrentDate(),
  delivery_date: '',
  benguithu_fee: 0,
  total_fee: 0, // Tổng cộng
  remain_fee: 0, // Còn phải thu

  note: '',
  service_fee: 0, // Phí dịch vụ

  order_nr: '',
  other_fee_from_sender: false,
  // payed: true,
  status: true,
  delivery_state: DeliveryState.New,
  //new
};

export const initialOrderMoney = {
  from_person: {
    fullname: '',
    phone: '',
    address: '',
    cmnd: '',
  },
  to_person: {
    fullname: '',
    phone: '',
    address: '',
    cmnd: '',
  },
  service_type: ServiceMoneyId.COD,
  other_fee_from_sender: true,
  service_fee: 0,
  stpc: '',
  dathu_fee: 0,
  chuathu_fee: 0,
  stpc_remain_fee: 0,
  received_date: getCurrentDate(),
  delivery_date: '',
  total_fee: 0,
  remain_fee: 0,
  thuho_fee: 0,
  ref_order_nr: '',
  order_money: true,
  delivery_state: DeliveryState.New,
};

export const fields = [
  'from_branch.branch_name',
  'to_branch.branch_name',
  'from_person.phone',
  'to_person.phone',
  'from_person.fullname',
  'to_person.fullname',
  'from_person.address',
  'to_person.address',
  'from_person.cmnd',
  'to_person.cmnd',
  'service_type',
  'quantity',
  'ttn_fee',
  'thbh_fee',
  'dathu_fee',
  'chuathu_fee',
  'discount',
  'other_fee',
  'delivery_fee',
  'delivery_address',
  'thng_fee',
  'dcnn_fee',
  'luukho_fee',
  'qnnt_fee',
  'mc_fee',
  'received_date',
  'delivery_date',
  'benguithu_fee',
  'total_fee',
  'remain_fee',

  'note',
];
