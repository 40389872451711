import { Role } from 'constants/common';
import { useUserInfo } from 'hook/useContextSelector';

// TODO: Snackbar on redirect.
export const AdminGuard = ({ children }) => {
  const userInfo = useUserInfo();

  if (!userInfo?.scope || userInfo?.scope !== Role.Admin) return <div />;

  return children;
};
