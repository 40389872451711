import { memo } from 'react';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';

const Actions = styled.div`
  display: flex;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  position: static;
  width: 128px;
  height: 40px;
  left: 0px;
  top: 0px;

  /* Primary/Main Color */

  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-transform: uppercase;

  /* Primary/Main Color */

  background: ${({ type }) => (type === 'primary' ? '#556ee6' : type === 'danger' ? '#f44336' : '#ffffff')};
  color: ${({ type }) => (type === 'primary' || type === 'danger' ? '#fff' : '#556ee6')};

  margin-right: 12px;

  i {
    margin-right: 7px;
    font-size: 18px;
  }
`;

const ActionsItem = ({ onCancel, onDelete, onSaveTemp, onSave }) => {
  return (
    <Actions>
      {onCancel && (
        <Button onClick={onCancel}>
          <i className="mdi mdi-close"></i>
          Hủy
        </Button>
      )}
      {onSaveTemp && (
        <Button onClick={onSaveTemp}>
          <i className="mdi mdi-paperclip"></i>
          Lưu nháp
        </Button>
      )}
      {onSave && (
        <Button type="primary" onClick={onSave}>
          <i className="mdi mdi-content-save-outline"></i>
          Lưu
        </Button>
      )}
      {onDelete && (
        <Button type="danger" onClick={onDelete}>
          <i className="mdi mdi-delete"></i>
          Xóa
        </Button>
      )}
    </Actions>
  );
};

export default memo(ActionsItem, propsAreEqual);
