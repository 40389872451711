import { CheckCircleIcon } from 'components/icons';
import { get } from 'lodash';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 30px;

  border-radius: 10px;
  min-width: ${({ width }) => width || ''};
  padding: 0 10px;

  background-color: ${({ background }) => background || ''};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  color: ${({ color }) => color || ''};
`;

const CellStatus = ({ background, color, custom, data, dataField, render, ...rest }) => {
  const value = useMemo(() => (render ? render(get(data, dataField)) : get(data, dataField)), [data, dataField, render]);

  const statusColor = useMemo(
    () => (typeof color === 'function' ? color(get(data, dataField)) : typeof color === 'string' ? color : ''),
    [color, data, dataField]
  );

  const statusBackground = useMemo(
    () => (typeof background === 'function' ? background(get(data, dataField)) : typeof background === 'string' ? background : ''),
    [background, data, dataField]
  );

  switch (custom) {
    case 'circle':
      if (value) {
        return (
          <div>
            <CheckCircleIcon />
          </div>
        );
      } else {
        return <div></div>;
      }

    default:
      return (
        <StatusContainer background={statusBackground} color={statusColor} {...rest}>
          {value}
        </StatusContainer>
      );
  }
};

export default memo(CellStatus, propsAreEqual);
