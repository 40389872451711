import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const getCustomerOrderDetail = id =>
  axios({
    url: NORMAL_END_POINT + 'customer_orders/detail/',
    method: 'POST',
    data: JSON.stringify({ id }),
  });

export const updateCustomerOrder = data =>
  axios({
    url: NORMAL_END_POINT + 'customer_orders/update/',
    method: 'POST',
    data,
  });

export const finishCustomerOrder = id =>
  axios({
    url: NORMAL_END_POINT + 'customer_orders/inactive/',
    method: 'POST',
    data: { id },
  });

export const transferCustomerOrder = data =>
  axios({
    url: NORMAL_END_POINT + 'customer_orders/transfer/',
    method: 'POST',
    data,
  });
