import { memo, useEffect, useRef } from 'react';
import { Col, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';

const LabelInput = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;

const CustomInput = ({ autoFocus, error, touched, isRequired, label, name, ...rest }) => {
  const ref = useRef(name);

  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);

  return (
    <>
      <LabelInput className="col-md-3 col-form-label">
        {label} {isRequired && '*'}
      </LabelInput>
      <Col md={9}>
        <Input innerRef={ref} autoFocus={autoFocus} invalid={!!error} fullWidth name={name} {...rest} />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(CustomInput, propsAreEqual);
