import { propsAreEqual } from 'utils/util';
import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

const RadioGroup = ({ error, touched, isRequired, label, name, options = [], value, onChange, readOnly, ...rest }) => {
  return (
    <>
      <Label className="col-md-12 col-form-label">
        {label} {isRequired && <b style={{ color: '#DC3E15' }}>*</b>}
      </Label>
      <Col md={12}>
        {options.map((_opt, i) => (
          <div className="form-check ms-3" key={i}>
            <Input
              className="form-check-input"
              type="radio"
              readOnly={readOnly}
              name={_opt.label}
              id={_opt.label}
              value={_opt.value}
              checked={_opt.value === value}
              onChange={e => {
                !readOnly && onChange({ target: { name, value: _opt.value } });
              }}
            />
            <Label className="form-check-label" htmlFor={_opt.label}>
              {_opt.label}
            </Label>
          </div>
        ))}

        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(RadioGroup, propsAreEqual);
