import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const getExpressServices = (sub = '') =>
  axios({
    url: NORMAL_END_POINT + 'express_services' + sub,
    method: 'GET',
  });

export const getExpressServiceById = id =>
  axios({
    url: NORMAL_END_POINT + 'express_services/id?id=' + id,
    method: 'GET',
  });

export const createExpressService = data =>
  axios({
    url: NORMAL_END_POINT + 'express_services',
    method: 'POST',
    data,
  });

export const updateExpressService = (id, data) =>
  axios({
    url: NORMAL_END_POINT + 'express_services',
    method: 'PUT',
    data,
  });
