import { Label, Col } from 'reactstrap';
import Switch from 'react-switch';

const FieldCheckbox = ({ label, name, onChange, value, readOnly }) => {
  return (
    <>
      <Label className="col-md-12 col-form-label d-flex align-items-center">{label}</Label>
      <Col md={9} className="d-flex align-items-center">
        <Switch
          className="me-1 mb-sm-8 mb-2"
          onColor="#38a4f8"
          name={name}
          onChange={checked => {
            onChange({ target: { name, value: checked } });
          }}
          checked={value || false}
          disabled={readOnly}
        />
      </Col>
    </>
  );
};

export default FieldCheckbox;
