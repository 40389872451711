import { useCallback, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

const SearchBox = styled(Input)`
  width: 200px;
  height: 47px;
  background: #fafafc;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #74788d;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

// const LabelInput = styled(Label)`
//   font-family: Quicksand;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 140%;
//   /* identical to box height, or 20px */

//   color: #000000;
// `;

const CustomInput = ({ autoFocus, error, minDate, maxDate, touched, isRequired, label, name, onApply, ...rest }) => {
  const ref = useRef(name);

  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);
  const onKeypressFilter = useCallback(
    e => {
      if (e.which === 13) onApply();
    },
    [onApply]
  );

  return (
    <>
      <SearchBox onKeyPress={onKeypressFilter} innerRef={ref} autoFocus={autoFocus} invalid={!!error} name={name} {...rest} />
      {error && (
        <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
          {error}
        </div>
      )}
    </>
  );
};

export default CustomInput;
