import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';

import styled from 'styled-components';
import { Row, UncontrolledCollapse } from 'reactstrap';
import { ChevronUpIcon } from 'components/icons';
import { usePushNotification } from 'hook/useContextSelector';
import { FieldInput } from 'components/own';
import ActionsItem from 'components/own/ActionsItem';
import { createBranch, deleteBranch, getBranchById, updateBranch } from 'services/branch';

const initialUser = {
  branch_name: '',
  branch_code: '',
  phone: '',
  city: '',
  address: '',
  status: true,
};

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const BranchItem = () => {
  const [{ data, options }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = usePushNotification();

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = initialUser;
        })
      );
    } else {
      getBranchById(id).then(res => {
        setState(
          produce(draft => {
            draft.data = { ...res, id: get(res, ['_id', '$oid'], res._id), status: get(res, ['status'], true) };
          })
        );
      });
    }
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        branch_name: yup.string().required('Vui lòng nhập tên chi nhánh.'),
        branch_code: yup.string().required('Vui lòng chọn loại chi nhánh.'),
        city: yup.string(),
        address: yup.string().required('Vui lòng nhập địa chỉ.'),
        phone: yup.string().required('Vui lòng nhập số điện thoại.'),
      }),
    []
  );

  const fields = useMemo(
    () => [
      {
        id: 'block-1',
        title: 'Thông tin chi nhánh',
        content: [
          {
            label: 'Tên chi nhánh',
            name: 'branch_name',
          },
          {
            label: 'Mã chi nhánh',
            name: 'branch_code',
          },
          {
            label: 'Thành phố',
            name: 'city',
          },
          {
            label: 'Địa chỉ',
            name: 'address',
          },
          {
            label: 'Số điện thoại',
            name: 'phone',
          },
        ],
      },
    ],
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/branches');
  }, [router]);

  const onSave = useCallback(
    values => {
      let request = cloneDeep(values);
      delete request._id;
      if (request.id) {
        updateBranch(request)
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/branches');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        createBranch(request)
          .then(res => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/branches');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [pushNotification, router]
  );

  const onDelete = useCallback(() => {
    deleteBranch(data?.branch_name)
      .then(res => {
        pushNotification('success', 'Xóa thành công');
        router.replace('/branches');
      })
      .catch(error => {
        pushNotification('error', error);
      });
  }, [data?.branch_name, pushNotification, router]);

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết chi nhánh</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => (
          <>
            {fields.map(block => (
              <Block key={block.id}>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {block.title}
                  <IconButton id={block.id}>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#${block.id}`}>
                  {block.content.map((field, index) => (
                    <Row key={index}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name)}
                        autoComplete="new-password"
                        // autoFocus={focusField === field.name}
                        // onKeyDown={handleKeyDown}
                        // onFocus={() => onChangeFocus(field.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={options[field.option]}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
              </Block>
            ))}
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} onDelete={onDelete} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default BranchItem;
