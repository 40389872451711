import CellAction from 'components/own/Table/CellRendering/CellAction';
import { AUTH_END_POINT } from 'constants/common';

export const getBranchInfo = () => ({
  buttonAddTitle: 'Thêm chi nhánh',
  showSearch: true,
  columns: [
    {
      dataField: 'branch_name',
      text: 'Chi nhánh',
      sort: true,
    },
    {
      dataField: 'branch_code',
      text: 'Code',
      sort: true,
    },
    {
      dataField: 'city',
      text: 'Thành phố',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại',
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Địa chỉ',
      sort: true,
    },

    {
      dataField: '_id.$oid',
      text: 'Thao tác',
      formatter: cell => <CellAction value={cell} detailUrl={'branches'} />,
    },
  ],
  selectable: false,
  title: 'Danh sách chi nhánh',
  detailUrl: 'branches',
  url: AUTH_END_POINT + 'branchs',
});
