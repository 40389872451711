import React, { useCallback, useState } from 'react';
import { usePushNotification, useUpdateProfile, useUserInfo } from 'hook/useContextSelector';
import produce from 'immer';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ProfileDialog from './ProfileDialog';
import PasswordDialog from './PasswordDialog';
import cloneDeep from 'lodash.clonedeep';
import { changePassword } from 'services/auth';

const ProfileMenu = () => {
  const [{ menu, openPassword, openProfile }, setState] = useState({ menu: false, openProfile: false, openPassword: false });
  const userInfo = useUserInfo();
  const updateUserInfo = useUpdateProfile();
  const pushNotification = usePushNotification();

  const toggleChangePass = useCallback(() => {
    setState(
      produce(draft => {
        draft.openPassword = !draft.openPassword;
      })
    );
  }, []);

  const updateUserPassword = useCallback(
    async values => {
      const req = cloneDeep(values);
      delete req.new_pass_confirm;
      changePassword(`current_pass=${req.current_pass}&new_pass=${req.new_pass}`).then(res => {
        pushNotification('success', 'Đổi mật khẩu thành công');
        toggleChangePass();
      });
    },
    [pushNotification, toggleChangePass]
  );

  const toggle = useCallback(() => {
    setState(
      produce(draft => {
        draft.menu = !draft.menu;
      })
    );
  }, []);

  const toggleProfile = useCallback(() => {
    setState(
      produce(draft => {
        draft.openProfile = !draft.openProfile;
      })
    );
  }, []);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
        <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
          <span className="d-none d-xl-inline-block ms-1 text-transform">{userInfo?.fullname}</span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={toggleProfile}>
            <i className="ri-user-line align-middle me-1"></i>Thông tin
          </DropdownItem>
          <DropdownItem onClick={toggleChangePass}>
            <i className="ri-lock-password-line align-middle me-1"></i>Đổi mật khẩu
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="text-danger" href="/logout">
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ProfileDialog open={openProfile} data={userInfo} onHide={toggleProfile} onUpdate={updateUserInfo} />
      <PasswordDialog open={openPassword} onHide={toggleChangePass} onUpdate={updateUserPassword} />
    </React.Fragment>
  );
};

export default ProfileMenu;
