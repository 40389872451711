import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { find, get, replace } from 'lodash';

import { DropDownIcon } from 'components/icons';
import { callGetAPI } from 'services/common';
const Wrapper = styled.div`
  width: ${({ width }) => `calc(${width} + 70px)` || '190px'};
  cursor: pointer;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #74788d;
  background-color: #fff;

  display: flex;
`;

const SelectAction = styled(Select)`
  width: ${({ width }) => width || '120px'};
  font-size: 14px;
  height: 45px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
  .select2-selection__control {
    border: unset;
    border-color: transparent !important;
    box-shadow: unset;
    box-sizing: border-box;
    height: 45px;
    min-height: 45px;
    border-radius: 20px;
  }
  .select2-selection__value-container {
    padding: 0 8px;
  }
`;

const Button = styled.div`
  background: #fcc400;
  width: 70px;
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 0px 20px 20px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterSelectPicker = ({
  label,
  name,
  button = {},
  onApply,
  onChange,
  options = [],
  optionUrl,
  placeholder,
  value,
  optionEmpty,
  ...rest
}) => {
  const [selectOptions, setSelectOption] = useState(options);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (optionUrl && !selectOptions.length && !loading) {
      setLoading(true);
      callGetAPI(optionUrl?.url).then(res => {
        const _opts = get(res, ['data'], res).map(_d => ({
          label: get(_d, optionUrl.keyLabel),
          value: replace(get(_d, optionUrl.keyValue), '/', ''),
        }));
        if (optionEmpty) _opts.unshift({ label: placeholder, value: '' });
        setSelectOption(_opts);
        setLoading(false);
      });
    }
  }, [loading, optionEmpty, optionUrl, placeholder, selectOptions.length]);

  const selectedValue = useMemo(() => (value ? find(selectOptions, ['value', value]) : null), [selectOptions, value]);
  const handleSelectGroup = useCallback(
    item => {
      onChange({ target: { name, value: item.value } });
    },
    [name, onChange]
  );

  return (
    <Wrapper width={rest.width}>
      <SelectAction
        {...rest}
        placeholder={placeholder || label}
        value={selectedValue}
        onChange={handleSelectGroup}
        options={selectOptions}
        classNamePrefix="select2-selection"
        components={{
          DropdownIndicator: DropDownIcon,
          IndicatorSeparator: null,
        }}
      />
      <Button {...button} onClick={onApply}>
        {button.title}
      </Button>
    </Wrapper>
  );
};

export default FilterSelectPicker;
