import { AUTH_END_POINT } from 'constants/common';
import axios from 'axios';

const parseBody = user => {
  var formBody = [];
  for (var property in user) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(user[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');

  return formBody;
};

export const postLogin = (url, data) => {
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: parseBody(data),
  }).then(res => {
    if (res.ok) return res.json();
    else throw new Error();
  });
};

export const getCurrentInfo = () =>
  axios({
    url: AUTH_END_POINT + 'users/me',
    method: 'GET',
  });

export const getUserById = id => axios({ url: AUTH_END_POINT + 'users/id?user_id=' + id, method: 'GET' });

export const updateProfile = (id, data) => axios({ url: AUTH_END_POINT + 'users/id/' + id, method: 'PUT', data });

export const changePassword = query => axios({ url: AUTH_END_POINT + 'users/me/changepass?' + query, method: 'PUT' });

export const createUser = data => axios({ url: AUTH_END_POINT + 'users', method: 'POST', data });

export const updateUser = (id, data) => axios({ url: AUTH_END_POINT + 'users/admin/id/' + id, method: 'PUT', data });

export const deleteUser = id => axios({ url: AUTH_END_POINT + 'users/' + id, method: 'DELETE' });
