import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportMonthlyInfo = () => ({
  apiPost: true,
  hiddenBranch: true,
  columns: [
    {
      dataField: 'report_month',
      text: 'Tháng',
    },
    {
      dataField: 'item_count',
      text: 'SLP',
    },
    {
      dataField: 'quantity',
      text: 'SLH',
    },
    {
      dataField: 'dathu_fee',
      text: 'Đã thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'chuathu_fee',
      text: 'Chưa thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thuho_count',
      text: 'SPTH',
    },
    {
      dataField: 'thuho_fee',
      text: 'Thu hộ',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu',
      text: 'TổngDT1',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu_chuathu',
      text: 'TổngDT2',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'anphu_fee',
      text: 'DT An Phú',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'trahang_cn_count',
      text: 'SPTHCN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thuho_cn',
      text: 'Thu hộ CN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu_thcn',
      text: 'TổngDT CN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'Tổng tiền đã thu', name: 'dathu_fee' },
        { label: 'Thu hộ', name: 'thuho_fee' },
        { controlType: ControlTypes.LINE },
        { label: 'Tổng DT1', name: 'total_dathu', fontWeight: 700 },
        { label: 'Tổng tiền đã thu', name: 'dathu_fee' },
        { label: 'Tổng tiền chưa thu', name: 'chuathu_fee' },
        { controlType: ControlTypes.LINE },
        { label: 'Tổng DT2', name: 'total_dathu_chuathu', fontWeight: 700 },
      ],
    },
    {
      items: [
        { label: 'Tổng DT An Phú', name: 'anphu_fee', fontWeight: 700 },
        { label: 'TỔNG Thu hộ CN', name: 'thuho_cn' },
        { label: 'Tổng DT CN', name: 'total_dathu_thcn' },
      ],
    },
  ],
  totalBlock: [
    { label: 'SLP:', name: 'item_count' },
    { label: 'SLH', name: 'quantity' },
    { label: 'SPTHCN:', name: 'thuho_count' },
  ],
  defaultParams: { year: new Date().getFullYear() },
  filterElements: [
    {
      name: 'year',
      controlType: ControlTypes.YEAR_PICKER,
    },
  ],
  title: 'DOANH THU CHI NHÁNH THÁNG',
  showBranchFilter: true,
  detailUrl: '',
  needApply: true,
  showPrint: true,
  url: NORMAL_END_POINT + 'reports/doanhthuthang/',
  exportUrl: NORMAL_END_POINT + 'reports/doanhthuthang/excel',
});
