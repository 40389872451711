import CellAction from 'components/own/Table/CellRendering/CellAction';
import { AUTH_END_POINT, NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';

export const getExpressServicePriceInfo = () => ({
  buttonAddTitle: 'Thêm giá',
  apiPost: true,
  columns: [
    {
      dataField: 'service_type.service_detail',
      text: 'Loại dịch vụ',
    },
    {
      dataField: 'from_branch.branch_name',
      text: 'Nơi khởi hành',
    },
    {
      dataField: 'to_branch.branch_name',
      text: 'Nơi đến',
    },
    {
      dataField: 'detail',
      text: 'Mô tả',
    },
    {
      dataField: 'service_fee',
      text: 'Giá tiền',
      formatter: cell => cell + 'k',
    },

    {
      dataField: 'id',
      text: 'Thao tác',
      formatter: cell => <CellAction value={cell} detailUrl={'service-price'} />,
    },
  ],
  filterElements: [
    {
      name: 'service_type',
      width: '200px',
      controlType: ControlTypes.SELECT_PICKER,
      placeholder: 'Loại dịch vụ',
      button: {
        title: 'Nạp',
      },
      optionEmpty: true,
      optionUrl: {
        url: NORMAL_END_POINT + 'express_services',
        keyLabel: 'service_detail',
        keyValue: '_id.$oid',
      },
    },
    {
      name: 'from_branch',
      width: '140px',
      controlType: ControlTypes.SELECT_PICKER,
      placeholder: 'Nơi khởi hành',
      button: {
        title: 'Nạp',
      },
      optionEmpty: true,
      optionUrl: {
        url: AUTH_END_POINT + 'branchs',
        keyLabel: 'branch_name',
        keyValue: '_id.$oid',
      },
    },
    {
      name: 'to_branch',
      width: '140px',
      controlType: ControlTypes.SELECT_PICKER,
      placeholder: 'Nơi đến',
      button: {
        title: 'Nạp',
      },
      optionEmpty: true,
      optionUrl: {
        url: AUTH_END_POINT + 'branchs',
        keyLabel: 'branch_name',
        keyValue: '_id.$oid',
      },
    },

    {
      name: 'service_fee',
      width: '140px',
      placeholder: 'Giá tiền',
    },
    {
      label: 'Nạp Số Liệu',
      controlType: ControlTypes.APPLY_BUTTON,
    },
  ],
  showSearch: false,
  needApply: true,
  title: 'Danh sách giá',
  detailUrl: 'service-price',
  url: NORMAL_END_POINT + 'esrs/lookup/',
});
