import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { getInfoManagementByUrl } from './modules';
import { parseUrlQuery } from 'utils/util';
import { cloneDeep, get } from 'lodash';
import { DeliveryState, Role } from 'constants/common';
import { useUserInfo } from 'hook/useContextSelector';
import { saveAs } from 'file-saver';
import { getUserToken } from 'utils/localStorage';
import { useReactToPrint } from 'react-to-print';

const initialState = {
  total: 0,
  data: [],
  searchValue: '',
  loading: true,
  order: { order: '', orderBy: '' },
  selected: [],
  params: {},
  summary: {},
  firstInit: true,
};

export const useCommonManagementHook = () => {
  const history = useHistory();
  const location = useLocation();
  const userInfo = useUserInfo();
  const contentRef = useRef();

  const isAdmin = useMemo(() => userInfo?.scope === Role.Admin || userInfo?.scope === Role.Accountant, [userInfo?.scope]);

  const [state, setState] = useState(initialState);

  const info = useMemo(() => getInfoManagementByUrl(location.pathname), [location.pathname]);

  const executeAPI = useCallback(() => {
    if (!userInfo) return;
    const defaultParams = Object.assign(cloneDeep(info.defaultParams || {}), {
      branch: info.reportCompany ? '' : get(userInfo, ['branch', '$oid']),
    });
    const handleSuccess = res => {
      if (get(res, ['data', 0]) === '') {
        setState(
          produce(draft => {
            if (draft.firstInit) draft.params = defaultParams || {};
            draft.firstInit = false;
            draft.summary = {};
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
        return;
      }
      setState(
        produce(draft => {
          if (draft.firstInit) draft.params = defaultParams || {};
          draft.firstInit = false;
          draft.summary = get(res, ['data', 0, 'report_total'], {});
          draft.loading = false;
          draft.data = get(res, ['data', 0], []);
          // draft.total = data.length;
        })
      );
    };

    const handleError = () => {
      setState(
        produce(draft => {
          draft.firstInit = false;
          draft.loading = false;
          draft.data = [];
          draft.total = 0;
        })
      );
    };

    const callAPI = async () => {
      let query = parseUrlQuery(Object.assign(defaultParams || {}, state.params));
      if (state.searchValue) query += '&keyword=' + state.searchValue;
      try {
        const res = await axios.get(info.url + '?' + query);
        handleSuccess(res);
      } catch (error) {
        handleError();
      }
    };

    const callPostAPI = async () => {
      try {
        const res = await axios.post(info.url, Object.assign(defaultParams || {}, state.params));
        handleSuccess(res);
      } catch (error) {
        handleError();
      }
    };

    if (info.apiPost) {
      callPostAPI();
    } else callAPI();
  }, [info.apiPost, info.defaultParams, info.reportCompany, info.url, state.params, state.searchValue, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    if (!info.url || info.needApply) {
      const defaultParams = Object.assign(cloneDeep(info.defaultParams || {}), {
        branch: info.reportCompany ? '' : get(userInfo, ['branch', '$oid']),
      });
      setState(
        produce(draft => {
          if (draft.firstInit) draft.params = defaultParams || {};
          draft.firstInit = false;
          draft.loading = false;
        })
      );
      return;
    }
    executeAPI();
  }, [executeAPI, info.defaultParams, info.needApply, info.reportCompany, info.url, userInfo]);

  const onAddNew = useCallback(() => {
    history.push(info?.detailUrl + '/new');
  }, [history, info?.detailUrl]);

  const onSelectFilter = useCallback(e => {
    setState(
      produce(draft => {
        draft.params[e.target.name] = e.target.value;
        draft.loading = true;
        draft.page = 1;
      })
    );
  }, []);

  const onApply = useCallback(() => {
    executeAPI();
  }, [executeAPI]);

  const onPrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const onExport = useCallback(async () => {
    const token = getUserToken();
    const res = await fetch(info.exportUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(state.params),
    });
    const blob = await res.blob();
    const contentDisposition = res.headers.get('content-disposition') || 'report.xlsx';
    const fileName = contentDisposition.replace(/attachment; filename="/gi, '').replace(/"/g, '');
    saveAs(blob, fileName);
  }, [info.exportUrl, state.params]);

  const onClickChangeStatus = useCallback(() => {
    if (!state.selected?.length) return;
    axios
      .put(
        info.updateUrl,
        state.selected.map(_id => ({ id: _id, delivery_state: DeliveryState.Receive }))
      )
      .then(res => {
        executeAPI();
      });
  }, [executeAPI, info.updateUrl, state.selected]);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setState(
        produce(draft => {
          draft.selected.push(row.id);
        })
      );
    } else {
      setState(
        produce(draft => {
          draft.selected = draft.selected.filter(x => x !== row.id);
        })
      );
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      setState(
        produce(draft => {
          draft.selected = ids;
        })
      );
    } else {
      setState(
        produce(draft => {
          draft.selected = [];
        })
      );
    }
  };

  return {
    ...info,
    ...state,
    contentRef,
    isAdmin,
    handleOnSelect,
    handleOnSelectAll,
    onApply,
    onAddNew,
    onClickChangeStatus,
    onExport,
    onPrint,
    onSelectFilter,
  };
};
