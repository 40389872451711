import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { produce } from 'immer';

import { Button, Card, Col, Container, Label, Row } from 'reactstrap';
import { cloneDeep, get } from 'lodash';

import { fields, getCurrentDate, initialOrderProduct } from 'mockups/order';
import { FieldInput } from 'components/own';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { KEY_ENTER, KEY_TAB } from 'constants/keycode';
import { getBranches } from 'services/branch';
import { ControlTypes } from 'constants/field';
import { getExpressServices } from 'services/express';
import { createOrder, getOrderById, getOrderDeliveryState, updateOrder } from 'services/order';
import { usePushNotification, useUserInfo } from 'hook/useContextSelector';
import { lookupExpressRef } from 'services/express-ref';
import { useParams } from 'react-router';
import { OrderTemplateComponent } from './PrintTemplate';
import { useReactToPrint } from 'react-to-print';
import { DeliveryState, NORMAL_END_POINT, Role } from 'constants/common';

const Root = styled(Container)`
  width: 100%;
  height: 100%;
  padding: 47px 160px;
  background-color: #fff;
  input {
    background-color: #f1f5f7;

    &:not(:placeholder-shown) {
      background-color: #fff;
    }
    &:read-only {
      &:not(.flatpickr-input) {
        background-color: #e0e0e0;
      }
    }
    &.flatpickr-input.disabled {
      background-color: #e0e0e0;
    }
  }
  .select2-selection__control--is-disabled {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: #505d69;
`;

const OrderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: rgb(220, 62, 21);
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const FieldContent = styled(Card)`
  background-color: #fff;
  padding: 26px;
  display: grid;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);

  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const GridContent = styled.div`
  display: grid;
  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Phiếu hàng hóa', link: '#' },
];

const initialState = {
  focusField: 'from_branch',
  options: {},
  loading: true,
  data: {},
};

const countTotal = values => {
  let total = 0;
  if (values.hang_noi_bo) return 0;
  if (values.ttn_fee) total += parseInt(values.ttn_fee);

  if (values.dathu_fee) total += parseInt(values.dathu_fee);
  if (values.chuathu_fee) total += parseInt(values.chuathu_fee);

  // if (values.thuho_fee) total += parseInt(values.thuho_fee);
  if (values.delivery_fee) total += parseInt(values.delivery_fee);

  if (values.thbh_fee) total += values.thbh_fee;
  if (values.discount && values.other_fee_from_sender) total -= parseInt(values.discount);
  if (values.other_fee) total += parseInt(values.other_fee);

  return parseInt(total);
};

const countSentPaid = values => {
  let total = 0;
  if (values.hang_noi_bo) return 0;
  if (values.ttn_fee) total += parseInt(values.ttn_fee);

  if (values.dathu_fee) total += parseInt(values.dathu_fee);
  if (values.delivery_fee) total += parseInt(values.delivery_fee);

  if (values.discount && values.other_fee_from_sender) total -= parseInt(values.discount);
  return parseInt(total);
};

const updateServiceFee = (values, updateFieldValue, isCount) => {
  if (!isCount) return values;
  const total = parseInt(values.service_fee || '0') * parseInt(values.quantity || '0');
  if (values.other_fee_from_sender) {
    values.dathu_fee = total;
    values.chuathu_fee = 0;
    updateFieldValue('dathu_fee', total);
    updateFieldValue('chuathu_fee', 0);
  } else {
    values.dathu_fee = 0;
    values.chuathu_fee = total;
    updateFieldValue('dathu_fee', 0);
    updateFieldValue('chuathu_fee', total);
  }

  return values;
};

const countRemainFee = values => {
  let total = 0;
  if (values.chuathu_fee) total += parseInt(values.chuathu_fee);
  if (values.thbh_fee) total += parseInt(values.thbh_fee);
  if (values.luukho_fee) total += parseInt(values.luukho_fee);
  if (values.other_fee) total += parseInt(values.other_fee);

  return total;
};

const updateSumValue = (newValues, setFieldValue) => {
  newValues.thuho_fee = newValues.chuathu_fee;
  setFieldValue('thuho_fee', newValues.chuathu_fee);
  const total_fee = countTotal(newValues);
  const benguithu_fee = countSentPaid(newValues);
  const remain_fee = countRemainFee(newValues);
  setFieldValue('total_fee', total_fee);
  setFieldValue('benguithu_fee', benguithu_fee);
  setFieldValue('remain_fee', remain_fee);
};

const getOptions = (options = [], field, values) => {
  if (field.option !== 'branch') return options;
  let value = get(values, ['from_branch']);
  if (field.name === 'from_branch') {
    value = get(values, ['to_branch']);
  }
  return options.filter(_op => _op.value !== value);
};

const isReadOnly = (field, values, isEditPage, url, scope) => {
  if (scope === Role.Admin) {
    return false;
  }
  if (url === 'received_nr/')
    return (
      [
        'to_person.address',
        'to_person.cmnd',
        'thng_fee',
        'dcnn_fee',
        'luukho_fee',
        'thbh_fee',
        'delivery_date',
        'delivery_date',
        'delivery_state',
        'ref_order_nr',
        'note',
      ].indexOf(field.name) === -1
    );
  else if (isEditPage)
    return (
      [
        'from_person.address',
        'from_person.cmnd',
        'to_person.address',
        'to_person.cmnd',
        'other_fee_from_sender',
        'delivery_address',
        'thng_fee',
        'dcnn_fee',
        'luukho_fee',
        'thuho_fee',
        'delivery_date',
        'delivery_state',
        'ref_order_nr',
        'note',
      ].indexOf(field.name) === -1
    );
  else if (!isEditPage && field.name === 'from_branch') {
    return true;
  }

  switch (field.name) {
    case 'chuathu_fee':
      if (values['dathu_fee']) return true;
      break;

    case 'dathu_fee':
      if (values['chuathu_fee']) return true;
      break;

    case 'thng_fee':
    case 'dcnn_fee':
      return true;

    default:
      return field.readOnly;
  }
};

const OrderProduct = ({ url = '' }) => {
  const [{ focusField, options, data, loading }, setState] = useState(initialState);
  const params = useParams();
  const userInfo = useUserInfo();
  const templateRef = useRef();
  const pushNotification = usePushNotification();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        from_branch: yup.string().required('Vui lòng chọn chi nhánh'),
        to_branch: yup.string().required('Vui lòng chọn chi nhánh'),
        from_person: yup.object().shape({
          phone: yup.string().required('Vui lòng nhập số điện thoại'),
          fullname: yup.string().required('Vui lòng nhập họ tên'),
          address: yup.string(),
          cmnd: yup.string(),
        }),
        to_person: yup.object().shape({
          phone: yup.string().required('Vui lòng nhập số điện thoại'),
          fullname: yup.string().required('Vui lòng nhập họ tên'),
          address: yup.string(),
          cmnd: yup.string(),
        }),
        quantity: yup.number().required('Vui lòng nhập số lượng'),
        service_type: yup.string().required('Vui lòng chọn dịch vụ'),
      }),
    []
  );

  const isEditPage = useMemo(() => params.id !== 'new', [params.id]);

  const callAPIGet = useCallback(
    id => {
      getOrderById(url + id).then(res => {
        const d = get(res, ['data', 0], {});
        setState(
          produce(draft => {
            draft.loading = false;
            if (d)
              draft.data = {
                ...d,
                from_branch_name: d.from_branch?.branch_name || d.from_branch,
                from_branch_code: d.from_branch?.branch_code || d.from_branch,
                to_branch_code: d.to_branch?.branch_code || d.from_branch,
                to_branch_name: d.to_branch?.branch_name || d.to_branch,
                to_branch_phone: d.to_branch?.phone || d.to_branch,
                to_branch_address: d.to_branch?.address || d.to_branch,
                service_type_name: d.service_type?.service_detail || d.service_type,
                from_branch: d.from_branch?._id || d.from_branch,
                to_branch: d.to_branch?._id || d.to_branch,
                service_type: d.service_type?._id || d.service_type,
              };
            else draft.data = {};
          })
        );
      });
    },
    [url]
  );

  useEffect(() => {
    const ele = document.getElementById('form-reset-button');
    if (ele && params.id === 'new') ele.click();
  }, [params.id]);

  useEffect(() => {
    if (!userInfo) return;
    if (!isEditPage) {
      setState(
        produce(draft => {
          draft.loading = false;
          draft.data = { ...initialOrderProduct, from_branch: get(userInfo, ['branch', '$oid'], '') };
        })
      );
    } else {
      callAPIGet(params.id);
    }
  }, [callAPIGet, isEditPage, params.id, userInfo]);

  useEffect(() => {
    const callAPI = async () => {
      Promise.all([getBranches(), getExpressServices('/phieuhang')]).then(res => {
        setState(
          produce(draft => {
            draft.options.branch = get(res, [0], [])?.map(_b => ({ label: _b.branch_name, value: get(_b, ['_id', '$oid']) }));
            draft.options.service = get(res, [1], [])?.map(_b => ({ label: _b.service_detail, value: get(_b, ['_id', '$oid']) }));
          })
        );
      });
    };
    callAPI();
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      getOrderDeliveryState(data?.delivery_state || '', data?.id).then(res => {
        setState(
          produce(draft => {
            draft.options.state = res?.map(_b => ({ label: _b, value: _b }));
          })
        );
      });
    };
    callAPI();
  }, [data?.delivery_state, data?.id]);

  const onChangeFocus = useCallback(field => {
    setState(
      produce(draft => {
        draft.focusField = field;
      })
    );
  }, []);

  const handleKeyDown = useCallback(
    e => {
      const currentIndex = fields.findIndex(_f => _f === focusField);
      const isLatest = currentIndex === fields.length - 1;
      switch (e.keyCode) {
        case KEY_ENTER:
        case KEY_TAB:
          e.preventDefault();
          if (currentIndex === -1 || isLatest) onChangeFocus(fields[0]);
          else onChangeFocus(fields[currentIndex + 1]);
          break;
        default:
          break;
      }
    },
    [focusField, onChangeFocus]
  );

  const infoFields = useMemo(
    () => ({
      person: [
        {
          title: 'Bên gửi',
          fields: [
            {
              name: 'from_branch',
              label: 'Chi nhánh gửi',
              controlType: ControlTypes.SELECT_PICKER,
              option: 'branch',
              placeholder: 'Chọn chi nhánh',
              countServiceFee: true,
              isRequired: true,
            },
            {
              name: 'from_person.phone',
              label: 'SDT',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'from_person.fullname',
              label: 'Họ tên',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            { name: 'from_person.address', label: 'Địa chỉ' },
            { name: 'from_person.cmnd', label: 'CMND' },
          ],
        },
        {
          title: 'Bên nhận',
          fields: [
            {
              name: 'to_branch',
              label: 'Chi nhánh nhận',
              controlType: ControlTypes.SELECT_PICKER,
              option: 'branch',
              placeholder: 'Chọn chi nhánh',
              countServiceFee: true,
              isRequired: true,
            },
            {
              name: 'to_person.phone',
              label: 'SDT',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'to_person.fullname',
              label: 'Họ tên',
              isRequired: true,
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            { name: 'to_person.address', label: 'Địa chỉ' },
            { name: 'to_person.cmnd', label: 'CMND' },
          ],
        },
      ],
      product: [
        {
          name: 'service_type',
          label: 'Tên loại hàng',
          isRequired: true,
          controlType: ControlTypes.SELECT_PICKER,
          option: 'service',
          placeholder: 'Chọn dịch vụ',
          countServiceFee: true,
        },
        // {
        //   name: 'service_fee',
        //   label: 'Số tiền dịch vụ',

        //   controlType: ControlTypes.CURRENCY,
        //   min: '0',
        //   step: 'any',
        //   readOnly: true,
        //   sum: true,
        // },

        // { name: 'other_fee_from_sender', label: 'Bên gửi thanh toán', controlType: ControlTypes.CHECK_BOX },
        { name: 'quantity', label: 'Số lượng', controlType: ControlTypes.CURRENCY, min: 1, sum: true, isRequired: true },
        { name: 'other_fee_from_sender', label: 'Đã thu tiền', controlType: ControlTypes.CHECK_BOX, sum: true, countServiceFee: true },
        { name: 'hang_noi_bo', label: 'Hàng nội bộ', controlType: ControlTypes.CHECK_BOX, sum: true },
        // { controlType: ControlTypes.SPACE },
        { name: 'ttn_fee', label: 'Tiền TTN', controlType: ControlTypes.CURRENCY, sum: true },
        { name: 'thbh_fee', label: 'Thu hộ BH', controlType: ControlTypes.CURRENCY, sum: true },

        { name: 'dathu_fee', label: 'Đã thu', controlType: ControlTypes.CURRENCY, sum: true },
        { name: 'chuathu_fee', label: 'Chưa thu', controlType: ControlTypes.CURRENCY, sum: true },

        { name: 'discount', label: 'Giảm giá', controlType: ControlTypes.CURRENCY, sum: true },
        { name: 'other_fee', label: 'Thu khác', controlType: ControlTypes.CURRENCY, sum: true },

        { name: 'delivery_fee', label: 'Tiền GTN', controlType: ControlTypes.CURRENCY, sum: true },
        { name: 'delivery_address', label: 'Địa chỉ GTN' },

        { name: 'thng_fee', label: 'Thu THNG', controlType: ControlTypes.CURRENCY, sum: true },
        { name: 'dcnn_fee', label: 'Thu ĐCNN', controlType: ControlTypes.CURRENCY, sum: true },

        { name: 'luukho_fee', label: 'Phí Lưu kho', controlType: ControlTypes.CURRENCY, readOnly: true },
        { name: 'thuho_fee', label: 'Thu hộ', controlType: ControlTypes.CURRENCY, readOnly: true },

        { name: 'qnnt_fee', label: 'Phí An Phú', controlType: ControlTypes.CURRENCY },
        { name: 'mc_fee', label: 'Phí MC', controlType: ControlTypes.CURRENCY },

        { name: 'received_date', label: 'Ngày giờ gửi', controlType: ControlTypes.DATE_PICKER },
        { name: 'delivery_date', label: 'Ngày giờ hoàn tất', controlType: ControlTypes.DATE_PICKER, minDate: new Date() },

        { controlType: ControlTypes.SPACE },
        { name: 'benguithu_fee', label: 'Bên gửi thu', controlType: ControlTypes.CURRENCY, readOnly: true, sum: true },

        { name: 'total_fee', label: 'Tổng cộng', controlType: ControlTypes.CURRENCY, readOnly: true },
        {
          name: 'remain_fee',
          label: 'Còn phải thu',
          readOnly: true,
          controlType: ControlTypes.CURRENCY,
        },

        {
          name: 'delivery_state',
          label: 'Tình trạng phiếu',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'state',
        },
        { name: 'ref_order_nr', label: 'Phiếu liên kết' },
      ],
    }),
    []
  );

  const checkServiceRef = useCallback(
    async (values, callback) => {
      const service_type = get(values, `service_type`);
      const from_branch = get(values, `from_branch`);
      const to_branch = get(values, `to_branch`);
      if (service_type && from_branch && to_branch) {
        lookupExpressRef(`service_type=${service_type}&from_branch=${from_branch}&to_branch=${to_branch}`)
          .then(res => {
            if (res.code === 404) pushNotification('warning', 'Không tìm thấy giá dịch vụ');
            const fee = parseInt(get(res, [0, 'service_fee'], 0)) * 1000;
            let newValue = cloneDeep(values);
            callback('service_fee', fee);
            newValue.service_fee = fee;
            newValue = updateServiceFee(newValue, callback, true);
            updateSumValue(newValue, callback);
          })
          .catch(error => callback('service_fee', 0));
      } else callback('service_fee', 0);
    },
    [pushNotification]
  );

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });

  const onSave = useCallback(
    (values, { resetForm }) => {
      const req = cloneDeep(values);
      if (req.delivery_state && req.delivery_state === DeliveryState.Done && !req.delivery_date) {
        req.delivery_date = getCurrentDate();
      }
      if (!req.hang_noi_bo && !req.dathu_fee && !req.chuathu_fee) {
        pushNotification('warning', 'Vui lòng nhập số tiền đã thu hoặc chưa thu');

        return;
      }
      if (req.id) {
        updateOrder(req.id, req)
          .then(res => {
            callAPIGet(req.order_nr);
            pushNotification('success', 'Cập nhật phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Cập nhật không thành công');
          });
      } else {
        req.status = true;
        createOrder(req)
          .then(res => {
            resetForm();
            pushNotification('success', 'Tạo phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Cập nhật không thành công');
          });
      }
    },
    [callAPIGet, pushNotification]
  );

  if (loading) return <div />;

  return (
    <div className="page-content">
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      <Root fluid>
        <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, setValues, setFieldValue, submitCount, touched = {}, values = {} }) => (
            <>
              <Title className="justify-content-center">{isEditPage ? 'PHIẾU HÀNG HÓA' : 'LẬP PHIẾU'}</Title>
              {params.id !== 'new' ? <OrderTitle className="mb-3 justify-content-center">{params.id}</OrderTitle> : <div className="mb-3" />}
              <UserInfo>
                {infoFields.person.map(({ title, fields }, index) => (
                  <Col xs={12} key={index}>
                    <FieldContent>
                      <SubTitle>
                        <i className="ri-send-plane-fill me-2"></i>
                        {title}
                      </SubTitle>
                      {fields.map((field, i) => (
                        <Row key={i}>
                          <FieldInput
                            {...field}
                            error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                            value={get(values, field.name, '')}
                            autoFocus={focusField === field.name}
                            onKeyDown={handleKeyDown}
                            onFocus={() => onChangeFocus(field.name)}
                            onSelect={(val, item) => {
                              const itemValues = get(item, 'value', {});
                              const prefix = field.name.split('.')[0];
                              for (const key in itemValues) {
                                if (Object.hasOwnProperty.call(itemValues, key)) {
                                  const element = itemValues[key];
                                  setFieldValue(`${prefix}.${key}`, element);
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            readOnly={isReadOnly(field, values, isEditPage, url, userInfo?.scope)}
                            onChange={e => {
                              if (field.countServiceFee) {
                                checkServiceRef({ ...values, [e.target.name]: e.target.value }, setFieldValue);
                              }

                              handleChange(e);
                            }}
                            options={getOptions(options[field.option], field, values)}
                            placeholder={field.placeholder || `Nhập ${field.label}`}
                          />
                        </Row>
                      ))}
                    </FieldContent>
                  </Col>
                ))}
              </UserInfo>
              <FieldContent $templateColumn="1fr">
                <SubTitle className="d-flex align-items-center justify-content-center">
                  <i className="fas fa-info me-2"></i>
                  Thông tin đơn hàng
                </SubTitle>
                <div style={{ borderBottom: '1px solid #BDBDBD' }} />
                <GridContent $templateColumn="1fr 1fr">
                  {infoFields.product.map((field, i) => (
                    <Row key={i}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name, '')}
                        autoFocus={focusField === field.name}
                        onKeyDown={handleKeyDown}
                        readOnly={isReadOnly(field, values, isEditPage, url, userInfo?.scope)}
                        onFocus={() => onChangeFocus(field.name)}
                        onSelect={(val, item) => {
                          const itemValues = get(item, 'value', {});
                          const prefix = field.name.split('.')[0];
                          for (const key in itemValues) {
                            if (Object.hasOwnProperty.call(itemValues, key)) {
                              const element = itemValues[key];
                              setFieldValue(`${prefix}.${key}`, element);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        onChange={e => {
                          const newValues = updateServiceFee(
                            { ...values, [e.target.name]: e.target.value },
                            setFieldValue,
                            field.countServiceFee
                          );
                          if (field.countServiceFee) {
                            checkServiceRef(newValues, setFieldValue);
                            handleChange(e);
                            return;
                          }

                          if (field.sum) {
                            updateSumValue(newValues, setFieldValue);
                          }

                          handleChange(e);
                        }}
                        options={options[field.option]}
                        placeholder={field.placeholder || `Nhập ${field.label}`}
                      />
                    </Row>
                  ))}
                </GridContent>
                <div className="mb-3">
                  <Label className="form-label">Note</Label>
                  <textarea className="form-control" rows="2" name="note" value={values.note || ''} onChange={handleChange}></textarea>
                </div>
              </FieldContent>

              <div className="d-flex align-items-center mt-3">
                <Button className="me-2" color="primary" onClick={handleSubmit}>
                  {isEditPage ? 'Cập nhật' : 'Lập phiếu'}
                </Button>
                {data.id && (
                  <Button className="me-2" color="secondary" onClick={handlePrint}>
                    In phiếu
                  </Button>
                )}
                {/* <Button color="primary">In phiếu con</Button> */}
              </div>
              <button className="d-none" onClick={() => setValues(initialOrderProduct)} id="form-reset-button"></button>
            </>
          )}
        </Formik>
      </Root>
      <div style={{ display: 'none' }}>
        <OrderTemplateComponent data={data} ref={templateRef} />
      </div>
    </div>
  );
};

export default OrderProduct;
