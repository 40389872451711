import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';

import styled from 'styled-components';
import { Row, UncontrolledCollapse } from 'reactstrap';
import { ChevronUpIcon } from 'components/icons';
import { createUser, getUserById, updateUser } from 'services/auth';
import { usePushNotification } from 'hook/useContextSelector';
import { ControlTypes } from 'constants/field';
import { FieldInput } from 'components/own';
import { getAllScope } from 'services/scope';
import ActionsItem from 'components/own/ActionsItem';
import { getBranches } from 'services/branch';

const initialUser = {
  name: '',
  email: '',
  phone: '',
  userType: null,
  password: '',
  confirmPassword: '',
  permission: [],
};

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const UserItem = () => {
  const [{ data, options }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = usePushNotification();

  useEffect(() => {
    Promise.all([getAllScope(), getBranches()]).then(res => {
      setState(
        produce(draft => {
          draft.options.scope = get(res, [0], []).map(_s => ({ label: _s.name, value: _s.name }));
          draft.options.branch = get(res, [1], []).map(_s => ({ label: _s.branch_name, value: get(_s, ['_id', '$oid']) }));
        })
      );
    });
  }, []);

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = initialUser;
        })
      );
    } else {
      getUserById(id).then(res => {
        setState(
          produce(draft => {
            draft.data = { ...res, branch: get(res, ['branch', '$oid'], res.branch) };
          })
        );
      });
    }
  }, [id]);

  const validationSchema = useMemo(
    () =>
      id === 'new'
        ? yup.object().shape({
            username: yup
              .string()
              .required('Vui lòng nhập tên tài khoản.')
              .matches(/^(?:[a-zA-Z0-9_-]|[-](?=[^-]*$)){1,20}$/, 'Tên tài khoản không hợp lệ'),
            scope: yup.string().required('Vui lòng chọn loại tài khoản.'),
            phone: yup
              .string()
              .nullable()
              .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
            email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
            password: yup.string().required('Vui lòng nhập mật khẩu.'),
            passwordConfirmation: yup.string().when('password', {
              is: val => !!val && !!val.length,
              then: yup
                .string()
                .required('Vui lòng xác nhận mật khẩu.')
                .oneOf([yup.ref('password')], 'Mật khẩu không giống nhau.'),
            }),
          })
        : yup.object().shape({
            username: yup.string().required('Vui lòng nhập tên tài khoản.'),
            scope: yup.string().required('Vui lòng chọn loại tài khoản.'),
            phone: yup
              .string()
              .nullable()
              .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
            email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
          }),
    [id]
  );

  const fields = useMemo(
    () => [
      {
        id: 'block-1',
        title: 'Thông tin tài khoản',
        content: [
          {
            label: 'Tên Tài Khoản',
            name: 'username',
          },
          {
            label: 'Quyền',
            name: 'scope',
            controlType: ControlTypes.SELECT_PICKER,
            option: 'scope',
          },
          {
            label: 'Chi nhánh',
            name: 'branch',
            controlType: ControlTypes.SELECT_PICKER,
            option: 'branch',
          },
          {
            label: 'Active',
            name: 'status',
            controlType: ControlTypes.CHECK_BOX,
          },
        ],
      },
      {
        id: 'block-2',
        title: 'Thông tin thành viên',
        content: [
          {
            label: 'Họ và tên',
            name: 'fullname',
          },
          {
            label: 'Số điện thoại',
            name: 'phone',
          },
          {
            label: 'Email',
            name: 'email',
          },
          {
            controlType: ControlTypes.SPACE,
          },
          {
            label: 'Mật khẩu',
            name: 'password',
            type: 'password',
          },
          {
            label: 'Mật khẩu xác nhận',
            name: 'passwordConfirmation',
            type: 'password',
          },
        ],
      },
    ],
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/users');
  }, [router]);

  const onSave = useCallback(
    values => {
      let request = cloneDeep(values);
      delete request._id;
      if (values._id) {
        updateUser(get(values, ['_id', '$oid']), request)
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/users');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        delete request.passwordConfirmation;
        createUser(request)
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/users');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [pushNotification, router]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết thành viên</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => (
          <>
            {fields.map(block => (
              <Block key={block.id}>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {block.title}
                  <IconButton id={block.id}>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#${block.id}`}>
                  {block.content.map((field, index) => (
                    <Row key={index}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name)}
                        autoComplete="new-password"
                        // autoFocus={focusField === field.name}
                        // onKeyDown={handleKeyDown}
                        // onFocus={() => onChangeFocus(field.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={options[field.option]}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
              </Block>
            ))}
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default UserItem;
