import styled from 'styled-components';

const Button = styled.div`
  border-radius: 20px;
  background: #556ee6;
  color: #fff;
  width: 170px;
  height: 45px;
  cursor: pointer;
  /* Item */

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterApplyButton = ({ label, onApply }) => {
  return <Button onClick={onApply}>{label || 'Tìm'}</Button>;
};

export default FilterApplyButton;
