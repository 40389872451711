import { LOCAL_STORAGE__JWT } from 'constants/common';
import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

const AppRoute = ({ component: Component, guard, layout: Layout, isAuthProtected, urlDetail, ...rest }) => {
  const Guard = guard || Fragment;

  return (
    <Route
      {...rest}
      render={props => {
        const authUser = localStorage.getItem(LOCAL_STORAGE__JWT);
        if (isAuthProtected && !authUser) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        return (
          <Layout>
            <Guard>
              <Component url={urlDetail} />
            </Guard>
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
