import styled from 'styled-components';
import clsx from 'clsx';
import { Col, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { LoadingIcon } from 'components/icons';
import { FilterInput } from '../Filter';

import { includes, filter, get } from 'lodash';
import { ControlTypes } from 'constants/field';
import { AUTH_END_POINT } from 'constants/common';
import { parseDate, parsePrice, replaceTitle } from 'utils/util';

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  /* Secondary */

  color: #505d69;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
  padding: 16px;

  .dropdown-menu {
    width: 60px;
    min-width: 60px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 500px;
`;

const TotalSummaryItem = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  margin-left: 27px;

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Gray 2 */

  color: #4f4f4f;
`;

const SummaryLine = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  div {
    width: 120px;
    border: 1px solid #000000;
  }
`;

const SummaryItem = styled.div`
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Gray 2 */

  color: #4f4f4f;
  justify-content: space-between;
  margin-bottom: 5px;
  div {
    min-width: 120px;
  }
`;

const parseTime = params => {
  if (params.from_date && params.to_date) {
    return `Từ ${parseDate(params.from_date)} đến ${parseDate(params.to_date)}`;
  }

  if (params.report_date) return ` Ngày ${parseDate(params.report_date)}`;

  if (params.year) return `Năm ${params.year}`;

  return '';
};

const TableContent = React.forwardRef(
  (
    {
      columns = [],
      data,
      defaultSearch = '',
      filterElements = [],
      isAdmin,
      loading,
      pageOptions,
      params,
      summary = {},
      title,
      showBranchFilter,
      selectable,
      selected = [],
      selectRowProp,
      showSearch,
      searchServerSide,
      summaryBlock,
      totalBlock = [],

      onApply,
      onSelectFilter,
      previewMode,
      branchOnly,
    },
    ref
  ) => {
    const { SearchBar } = Search;
    const dataTable = useMemo(
      () => (previewMode && selectable ? filter(data, _d => selected.indexOf(_d.id) > -1) : data),
      [data, previewMode, selectable, selected]
    );
    const columnsTable = useMemo(
      () => (previewMode ? columns.filter(_c => !_c.columnStatus).map(_c => ({ ..._c, text: replaceTitle(_c.text), sort: false })) : columns),
      [columns, previewMode]
    );
    return (
      <ContentTable className="" ref={ref}>
        <div className={clsx('d-flex align-items-center  pr-4', { 'mb-4': !previewMode })}>
          {isAdmin && showBranchFilter && !previewMode && (
            <FilterInput
              optionEmpty={!branchOnly}
              name={'branch'}
              label="Chi nhánh"
              controlType={ControlTypes.SELECT_PICKER_WITH_APPLY}
              button={{
                title: 'Nạp',
              }}
              onApply={onApply}
              optionUrl={{
                url: AUTH_END_POINT + 'branchs',
                keyLabel: 'branch_name',
                keyValue: '_id.$oid',
              }}
              width="160px"
              placeholder="Chọn chi nhánh"
              onChange={onSelectFilter}
              value={params['branch']}
            />
          )}
          <Title>{title}</Title>
          {isAdmin && showBranchFilter && !previewMode && <div style={{ width: 230 }} />}
        </div>
        {previewMode && <div className="text-center">{parseTime(params)}</div>}
        <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columnsTable} data={dataTable}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columnsTable}
              data={dataTable}
              search={{
                defaultSearch,
              }}
            >
              {toolkitProps => (
                <>
                  {summaryBlock ? (
                    <Row className="mb-4">
                      <Col md={9}>
                        <Row>
                          {summaryBlock?.map(({ items = [] }, _bIndex) => (
                            <Col md={4} key={_bIndex}>
                              {items?.map(({ controlType, label, name, branchLabel, ...rest }, i) =>
                                controlType === ControlTypes.LINE ? (
                                  <SummaryLine>
                                    <div />
                                  </SummaryLine>
                                ) : controlType === ControlTypes.LABEL_DEPEND_BRANCH ? (
                                  <SummaryItem key={i} {...rest}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: !isAdmin || !showBranchFilter || params['branch'] ? branchLabel : label,
                                      }}
                                    ></div>
                                    <div className="text-center">{parsePrice(get(summary, name, 0))}</div>
                                  </SummaryItem>
                                ) : (
                                  <SummaryItem key={i} {...rest}>
                                    <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                    <div className="text-center">{parsePrice(get(summary, name, 0))}</div>
                                  </SummaryItem>
                                )
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col md={3}>
                        <div className="d-flex flex-column justify-content-between align-items-end h-100">
                          <div className="d-flex align-items-center">
                            <div className="flex-1"></div>
                            {!previewMode &&
                              filterElements.map((_f, index) => (
                                <FilterInput
                                  key={index}
                                  onApply={onApply}
                                  {..._f}
                                  onChange={onSelectFilter}
                                  params={params}
                                  value={params[_f.name]}
                                  minDate={params[_f.minDate]}
                                  maxDate={params[_f.maxDate]}
                                />
                              ))}
                          </div>
                          {!previewMode && showSearch && (
                            <>
                              {searchServerSide ? (
                                <div className="my-3">
                                  <FilterInput
                                    onChange={onSelectFilter}
                                    onApply={onApply}
                                    name="search"
                                    value={params['search']}
                                    placeholder="Tìm"
                                  />
                                </div>
                              ) : (
                                <div className="my-3">
                                  <SearchBar {...toolkitProps.searchProps} className="search-box" placeholder="Tìm" />
                                </div>
                              )}
                            </>
                          )}
                          <div className="d-flex align-items-center">
                            {totalBlock.map(({ label, name }, i) => (
                              <TotalSummaryItem key={i}>
                                {label}
                                {`  `}
                                {includes(name, 'fee') ? parsePrice(get(summary, name, 0)) : get(summary, name, 0)}
                              </TotalSummaryItem>
                            ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div className="d-flex align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        {totalBlock.map(({ label, name }, i) => (
                          <TotalSummaryItem key={i}>
                            {label}
                            {`  `}
                            {includes(name, 'fee') ? parsePrice(get(summary, name, 0)) : get(summary, name, 0)}
                          </TotalSummaryItem>
                        ))}
                      </div>
                      <div className="flex-1"></div>
                      {!previewMode &&
                        filterElements.map((_f, index) => (
                          <FilterInput
                            key={index}
                            {..._f}
                            onChange={onSelectFilter}
                            params={params}
                            onApply={onApply}
                            value={params[_f.name]}
                            minDate={params[_f.minDate]}
                            maxDate={params[_f.maxDate]}
                          />
                        ))}
                      <div className="me-3"></div>
                      {!previewMode && showSearch && (
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} className="search-box" placeholder="Tìm" />
                        </div>
                      )}
                    </div>
                  )}
                  <LoadingContainer className={clsx({ 'd-none': !loading })}>
                    <LoadingIcon />
                  </LoadingContainer>
                  <Row className={clsx({ 'd-none': loading })}>
                    <Col xl="12">
                      {previewMode ? (
                        <BootstrapTable
                          keyField={'_id'}
                          selectRow={selectRowProp}
                          responsive={false}
                          bordered={false}
                          striped={false}
                          headerWrapperClasses={'thead-light'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      ) : (
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={'_id'}
                            selectRow={selectRowProp}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={'table align-middle table-nowrap'}
                            headerWrapperClasses={'thead-light'}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  {!previewMode && (
                    <Row className="align-items-md-center mt-3">
                      <Col className="inner-custom-pagination d-flex">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        {previewMode && (
          <div className="d-flex flex-column align-items-center">
            <div className="w-50 mt-4 d-flex align-items-center justify-content-end">
              <div>Ngày</div>
              <div className="mx-4">tháng</div>
              <div>năm</div>
            </div>
            <div className="w-50 mt-1 d-flex align-items-center justify-content-between">
              <b>Nhân viên áp tải</b>
              <b>Nhân viên giao hàng</b>
            </div>
            <div className="my-5"></div>
          </div>
        )}
      </ContentTable>
    );
  }
);

export default TableContent;
