import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const callGetAPI = url =>
  axios({
    url: url,
    method: 'GET',
  });

export const callUploadExcel = (files, url) => {
  const formData = new FormData();

  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append('files', element);
  }
  return axios({
    url: NORMAL_END_POINT + url,
    method: 'POST',
    headers: {
      'content-type': 'multipart/form-data',
    },
    data: formData,
  });
};
