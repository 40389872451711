import { ControlTypes } from 'constants/field';
import ApplyButton from './ApplyButton';
import DatePicker from './DatePicker';
import DatePickerWithApply from './DatePickerWithApply';
import DateRangePicker from './DateRangePicker';
import FitterLabel from './FitterLabel';
import CustomInput from './Input';
import SelectPicker from './SelectPicker';
import SelectPickerWithApply from './SelectPickerWithApply';
import YearPicker from './YearPicker';

const FilterInput = ({ controlType = '', params, onApply, ...rest }) => {
  switch (controlType) {
    case ControlTypes.SELECT_PICKER:
      return <SelectPicker {...rest} />;

    case ControlTypes.SELECT_PICKER_WITH_APPLY:
      return <SelectPickerWithApply {...rest} onApply={onApply} />;

    case ControlTypes.DATE_PICKER:
      return <DatePicker {...rest} />;

    case ControlTypes.DATE_PICKER_WITH_APPLY:
      return <DatePickerWithApply {...rest} onApply={onApply} />;

    case ControlTypes.DATE_RANGE_PICKER:
      return <DateRangePicker {...rest} params={params} onApply={onApply} />;

    case ControlTypes.YEAR_PICKER:
      return <YearPicker {...rest} onApply={onApply} />;

    case ControlTypes.LABEL:
      return <FitterLabel {...rest} />;

    case ControlTypes.APPLY_BUTTON:
      return <ApplyButton {...rest} onApply={onApply} />;

    default:
      return <CustomInput {...rest} onApply={onApply} />;
  }
};

export default FilterInput;
