import { NORMAL_END_POINT } from 'constants/common';

export const getImportHistoryInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'filename',
      text: 'Tên Excel',
    },
    {
      dataField: 'import_date',
      text: 'Ngày tháng',
    },
    {
      dataField: 'total_record',
      text: 'Số lượng',
    },
    {
      dataField: 'import_by_fullname',
      text: 'Người import',
    },
  ],
  filterElements: [],
  totalBlock: [
    { label: 'Tổng file : ', name: 'item_count' },
    { label: 'Số lượng', name: 'quantity_count' },
  ],
  selectable: false,
  showSearch: true,
  title: 'DANH SÁCH PHIẾU VTSG IMPORT',
  hideBreadcrumb: true,
  detailUrl: 'services',
  url: NORMAL_END_POINT + 'imports/import/history',
});
