import CellAction from 'components/own/Table/CellRendering/CellAction';
import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { NORMAL_END_POINT } from 'constants/common';
import { parsePrice } from 'utils/util';
import { getStateBackground, getStateColor } from './report-branch';

export const getTruckOrderInfo = () => ({
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'id',
      text: '',
      formatter: cell => <CellAction value={cell} detailUrl={'truck-orders'} />,
    },
    {
      dataField: 'created_date',
      text: 'Ngày',
      sort: true,
    },
    {
      dataField: 'order_status',
      text: 'Trạng thái',
      columnStatus: true,
      sort: true,
      formatter: (v, data) => (
        <CellStatus dataField="order_status" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },
    {
      dataField: 'from_person',
      text: 'Người gửi',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    {
      dataField: 'from_person_address',
      text: 'Địa chỉ NG',
    },
    {
      dataField: 'to_person',
      text: 'Người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
    {
      dataField: 'received_date',
      text: 'Ngày giao',
    },
    {
      dataField: 'delivery_date',
      text: 'Ngày trả',
    },
    {
      dataField: 'estimate_fee',
      text: 'Cước tự động',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
  ],

  totalBlock: [{ label: 'Tổng phiếu : ', name: 'quantity_count' }],
  summaryBlock: [],
  selectable: false,
  showSearch: true,
  title: 'DANH SÁCH THUÊ XE TẢI',
  hideBreadcrumb: true,
  url: NORMAL_END_POINT + 'truck_orders/list/',
});
