import CellAction from 'components/own/Table/CellRendering/CellAction';
import { AUTH_END_POINT } from 'constants/common';

export const getUserInfo = () => ({
  showSearch: true,
  buttonAddTitle: 'Tạo thành viên',
  columns: [
    {
      dataField: 'username',
      text: 'Username',
    },
    {
      dataField: 'fullname',
      text: 'Họ và tên',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại',
    },
    {
      dataField: 'created_date',
      text: 'Ngày tạo',
    },
    {
      dataField: '_id.$oid',
      text: 'Thao tác',
      formatter: cell => <CellAction value={cell} detailUrl={'users'} />,
    },
  ],
  selectable: false,
  title: 'Danh sách thành viên',
  detailUrl: 'users',
  url: AUTH_END_POINT + 'users',
});
