import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

const DatePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;

  width: ${({ width }) => width || '100px'};
  height: 45px;
  padding: 0 8px;

  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 14px;
`;

const FilterDatePicker = ({ maxDate, minDate, name, placeholder = '', onChange, value = '', width }) => {
  return (
    <DatePicker
      width={width}
      className="me-2"
      value={value}
      onChange={(e, newV) => {
        onChange({ target: { name, value: newV } });
      }}
      placeholder={placeholder}
      options={{
        dateFormat: 'Y/m/d',
        maxDate,
        minDate,
      }}
    />
  );
};

export default FilterDatePicker;
