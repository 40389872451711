import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';

const Root = styled.div`
  display: flex;
`;

const Button = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

const CellAction = ({ detailUrl, value }) => {
  const router = useHistory();

  const onEdit = useCallback(() => {
    router.push(detailUrl + '/' + value);
  }, [detailUrl, router, value]);

  return (
    <Root>
      {value && (
        <Button onClick={onEdit}>
          <i className="fas fa-pencil-alt"></i>
        </Button>
      )}
    </Root>
  );
};

export default memo(CellAction, propsAreEqual);
