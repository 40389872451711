import { AUTH_END_POINT } from 'constants/common';
import axios from 'axios';

export const getAllScope = () => axios({ url: AUTH_END_POINT + 'scopes', method: 'GET' });

export const getScopeById = id => axios({ url: AUTH_END_POINT + 'scopes/id?user_id=' + id, method: 'GET' });

export const createScope = data => axios({ url: AUTH_END_POINT + 'scopes', method: 'POST', data });

export const updateScope = (id, data) => axios({ url: AUTH_END_POINT + 'scopes/' + id, method: 'PUT', data });

export const deleteScope = id => axios({ url: AUTH_END_POINT + 'scopes/' + id, method: 'DELETE' });
