import produce, { current } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { getInfoManagementByUrl } from './modules';
import { getParamsFromQuery, parseUrlQuery } from 'utils/util';
import { cloneDeep, filter, get, sumBy } from 'lodash';
import { DeliveryState, Role } from 'constants/common';
import { useUserInfo } from 'hook/useContextSelector';
import { saveAs } from 'file-saver';
import { getUserToken } from 'utils/localStorage';
import { useReactToPrint } from 'react-to-print';

const initialState = {
  total: 0,
  page: 1,
  data: [],
  searchValue: '',
  loading: true,
  order: { order: '', orderBy: '' },
  selected: [],
  params: {},
  summary: {},
  firstInit: true,
  selectedSummary: {},
};

export const useCommonManagementHook = () => {
  const history = useHistory();
  const location = useLocation();
  const userInfo = useUserInfo();
  const contentRef = useRef();

  const isAdmin = useMemo(() => userInfo?.scope === Role.Admin || userInfo?.scope === Role.Accountant, [userInfo?.scope]);

  const [state, setState] = useState(initialState);

  const info = useMemo(() => getInfoManagementByUrl(location.pathname), [location.pathname]);
  const executeAPI = useCallback(
    (params = {}) => {
      if (!userInfo) return;
      const defaultParams = Object.assign(
        cloneDeep(info.defaultParams || {}),
        info.showBranchFilter
          ? {
              branch:
                userInfo?.scope === Role.Admin || (userInfo?.scope === Role.Accountant && !info.branchOnly)
                  ? ''
                  : get(userInfo, ['branch', '$oid']),
            }
          : {}
      );
      const handleSuccess = res => {
        if (get(res, ['data', 0]) === '') {
          setState(
            produce(draft => {
              if (draft.firstInit) draft.params = defaultParams || {};
              draft.firstInit = false;
              draft.summary = {};
              draft.loading = false;
              draft.page = 1;
              draft.data = [];
              draft.total = 0;
            })
          );
          return;
        }
        const data = Array.isArray(res) ? res : get(res, ['data', 0, 'items']);
        const summary = get(res, ['data', 0, 'summary'], {});
        setState(
          produce(draft => {
            if (draft.firstInit) draft.params = defaultParams || {};
            draft.firstInit = false;
            draft.summary = summary;
            draft.page = 1;
            draft.loading = false;
            draft.data = data?.map(_d => ({ ..._d, id: get(_d, ['_id', '$oid'], _d.id) })) || [];
            draft.total = data.length;
          })
        );
      };

      const handleError = () => {
        setState(
          produce(draft => {
            if (draft.firstInit) draft.params = defaultParams || {};
            draft.firstInit = false;
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      };

      const callAPI = async () => {
        let query = parseUrlQuery(Object.assign(defaultParams || {}, state.params, params));
        if (state.searchValue) query += '&keyword=' + state.searchValue;
        try {
          const res = await axios.get(query ? info.url + '?' + query : info.url);
          handleSuccess(res);
        } catch (error) {
          handleError();
        }
      };
      const callPostAPI = async () => {
        try {
          let body = Object.assign(defaultParams || {}, state.params, params);
          if (!Object.keys(body).length) body = null;
          const res = await axios.post(info.url, body);
          handleSuccess(res);
        } catch (error) {
          handleError();
        }
      };

      if (info.apiPost) {
        callPostAPI();
      } else callAPI();
    },
    [info.apiPost, info.branchOnly, info.defaultParams, info.showBranchFilter, info.url, state.params, state.searchValue, userInfo]
  );

  useEffect(() => {
    if (!userInfo) return;
    if (!info.url || info.needApply) {
      const defaultParams = Object.assign(cloneDeep(info.defaultParams || {}), {
        branch:
          userInfo?.scope === Role.Admin || (userInfo?.scope === Role.Accountant && !info.branchOnly) ? '' : get(userInfo, ['branch', '$oid']),
      });
      setState(
        produce(draft => {
          if (draft.firstInit) draft.params = defaultParams || {};
          draft.firstInit = false;
          draft.loading = false;
        })
      );
      return;
    }
    executeAPI();
  }, [executeAPI, info.branchOnly, info.defaultParams, info.needApply, info.url, userInfo]);

  useEffect(() => {
    setState(
      produce(draft => {
        const data = filter(current(draft.data), _d => state.selected.indexOf(_d.id) > -1);
        draft.selectedSummary = {
          item_count: data.length,
          quantity: sumBy(data, 'quantity'),
          quantity_count: sumBy(data, 'quantity'),
        };
      })
    );
  }, [state.selected]);

  useEffect(() => {
    const queryParams = getParamsFromQuery(location.search);
    if (!queryParams) return;

    history.replace({
      search: '',
    });
    setState(
      produce(draft => {
        for (const key in queryParams) {
          if (Object.hasOwnProperty.call(queryParams, key)) {
            if (draft.params[key] !== queryParams[key]) {
              draft.params[key] = queryParams[key];
            }
          }
        }
        draft.loading = true;
        draft.page = 1;
        executeAPI(current(draft.params));
      })
    );
  }, [executeAPI, history, location.search]);

  const onAddNew = useCallback(() => {
    history.push(info?.detailUrl + '/new');
  }, [history, info?.detailUrl]);

  const onSelectFilter = useCallback(
    e => {
      setState(
        produce(draft => {
          if (draft.params[e.target.name] === e.target.value) return;
          draft.params[e.target.name] = e.target.value;
          if (!info.needApply) {
            draft.loading = true;
            draft.page = 1;
          }
        })
      );
    },
    [info.needApply]
  );

  const onApply = useCallback(() => {
    setState(
      produce(draft => {
        draft.loading = true;
        draft.page = 1;
      })
    );
    executeAPI();
  }, [executeAPI]);

  const onPrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const onExport = useCallback(async () => {
    const token = getUserToken();
    const res = await fetch(info.exportUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: info.exportMethod || 'POST',
      body: info.exportMethod ? undefined : JSON.stringify(state.params),
    });
    const blob = await res.blob();
    const contentDisposition = res.headers.get('content-disposition') || 'report.xlsx';
    const fileName = contentDisposition.replace(/attachment; filename="/gi, '').replace(/"/g, '');
    saveAs(blob, fileName);
  }, [info.exportMethod, info.exportUrl, state.params]);

  const onClickChangeStatus = useCallback(() => {
    if (!state.selected?.length) return;
    axios
      .put(
        info.updateUrl,
        state.selected.map(_id => ({ id: _id, delivery_state: DeliveryState.Receive }))
      )
      .then(res => {
        executeAPI();
      });
  }, [executeAPI, info.updateUrl, state.selected]);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setState(
        produce(draft => {
          draft.selected.push(row.id);
        })
      );
    } else {
      setState(
        produce(draft => {
          draft.selected = draft.selected.filter(x => x !== row.id);
        })
      );
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      setState(
        produce(draft => {
          draft.selected = ids;
        })
      );
    } else {
      setState(
        produce(draft => {
          draft.selected = [];
        })
      );
    }
  };

  const onPageChange = useCallback(page => {
    setState(
      produce(draft => {
        draft.page = page;
      })
    );
  }, []);

  return {
    ...info,
    ...state,
    contentRef,
    isAdmin,
    defaultSearch: !!location.search ? DeliveryState.New : '',
    handleOnSelect,
    handleOnSelectAll,
    onApply,
    onAddNew,
    onClickChangeStatus,
    onExport,
    onPageChange,
    onPrint,
    onSelectFilter,
  };
};
