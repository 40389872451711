import { CustomerOrderStatus, DeliveryState } from 'constants/common';

export const getStateColor = value => {
  switch (value) {
    case DeliveryState.Back:
      return '#333333';
    case DeliveryState.Receive:
    case CustomerOrderStatus.Inprogress:
      return '#4F4F4f';
    default:
      return '#fff';
  }
};

export const getStateBackground = value => {
  switch (value) {
    case DeliveryState.New:
    case CustomerOrderStatus.New:
      return '#4F4F4F';
    case DeliveryState.Receive:
    case CustomerOrderStatus.Inprogress:
      return '#F2C94C';
    case DeliveryState.Done:
    case CustomerOrderStatus.Done:
      return '#27AE60';

    case DeliveryState.Back:
      return '#F2994A';
    case DeliveryState.Forwarding:
      return '#556EE6';
    case DeliveryState.Cancel:
    case CustomerOrderStatus.Cancel:
      return '#DC3E15';
    case DeliveryState.CongNo:
      return '#ff3d00';

    default:
      return '';
  }
};
