import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportBiInfo = () => ({
  apiPost: true,
  showSearch: true,
  table: [
    {
      name: 'bi_thu',
      columns: [
        {
          dataField: 'received_date',
          text: 'Ngày gửi',
        },
        {
          dataField: 'order_nr',
          text: 'Mã sản phẩm',
        },
        {
          dataField: 'from_branch',
          text: 'Nơi giao',
        },
        {
          dataField: 'to_branch',
          text: 'Nơi nhận',
        },
        {
          dataField: 'from_person_name',
          text: 'Người gửi',
        },
        {
          dataField: 'to_person_name',
          text: 'Người nhận',
        },
        {
          dataField: 'service_type',
          text: 'Loại hàng',
        },
        {
          dataField: 'stpc_fee',
          text: 'STPC',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'dathu_fee',
          text: 'Đã thu',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'note',
          text: 'Ghi chú',
        },
      ],
      filterElements: [
        {
          name: 'report_date',
          width: 200,
          controlType: ControlTypes.DATE_PICKER_WITH_APPLY,
          placeholder: 'Chọn ngày',
        },
      ],
      label: 'Bên Gửi (Thu)',
      showBranchFilter: true,
      summaryBlock: [
        {
          items: [
            { label: 'TỔNG THU SPCT MANG SANG', name: '' },
            { label: 'TỔNG THU SPCT ĐÃ TRỪ PHÍ COD', name: 'total_thu_stpc' },
            { label: 'TỔNG CHI SPCT ĐÃ TRỪ PHÍ COD', name: 'total_chi_stpc' },
            { controlType: ControlTypes.DB_LINE },
            { label: 'STPC THỰC NỘP', name: 'total_stpc', fontWeight: 700 },
          ],
        },
      ],
      totalBlock: [
        { label: 'Tổng STPC: ', name: 'stpc_fee' },
        { label: 'Tổng đã thu: ', name: 'dathu_fee' },
      ],
    },
    {
      name: 'bi_chi',
      columns: [
        {
          dataField: 'received_date',
          text: 'Ngày gửi',
        },
        {
          dataField: 'done_date',
          text: 'Ngày HT',
        },
        {
          dataField: 'order_nr',
          text: 'Mã sản phẩm',
        },
        {
          dataField: 'from_branch',
          text: 'Nơi gửi',
        },
        {
          dataField: 'to_branch',
          text: 'Nơi nhận',
        },
        {
          dataField: 'from_person_name',
          text: 'Người gửi',
        },
        {
          dataField: 'to_person_name',
          text: 'Người nhận',
        },
        {
          dataField: 'service_type',
          text: 'Loại hàng',
        },
        {
          dataField: 'stpc_fee',
          text: 'STPC',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'dathu_fee',
          text: 'Đã thu',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'note',
          text: 'Ghi chú',
        },
      ],
      label: 'Bên Nhận (Chi)',

      title: '',
      totalBlock: [
        { label: 'Tổng STPC: ', name: 'stpc_fee' },
        { label: 'Tổng đã thu: ', name: 'dathu_fee' },
      ],
    },
  ],

  defaultParams: { report_date: new Date().toLocaleDateString('en-ZA') },

  needApply: true,
  title: 'BẢNG KÊ CÁC LOẠI BÌ CHI  NHÁNH',
  url: NORMAL_END_POINT + 'reports/bangkebi/',
  exportUrl: NORMAL_END_POINT + 'reports/bangkebi/excel',
});
