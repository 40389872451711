import { Role } from 'constants/common';
export const menuMockup = [
  { link: '/dashboard', title: 'Trang chủ', icon: 'mdi mdi-view-grid-outline' },
  {
    link: '/#',
    title: 'Phiếu hàng hóa',
    allow: [Role.Admin, Role.User],
    icon: 'mdi mdi-cart-outline',
    subs: [
      { link: '/orders-send', title: 'Danh sách phiếu gửi' },
      { link: '/orders-receive', title: 'Danh sách nhận' },
      { link: '/orders/new', title: 'Lập phiếu' },
      { link: '/cash/new', title: 'Phiếu gửi tiền' },
      { link: '/import-sending-slip', title: 'Import phiếu gửi VTSG' },
      { link: '/import-receipt-voucher', title: 'Import phiếu nhận VTSG' },
      { link: '/customer-orders', title: 'DS vận đơn' },
      { link: '/lookup', title: 'DS tra cứu vận đơn' },
      { link: '/truck-orders', title: 'DS thuê xe tải' },
      { link: '/truck-lookup', title: 'DS tra cứu xe tải' },
    ],
  },
  {
    link: '/#',
    title: 'Báo cáo',
    allow: [Role.Admin, Role.User],
    icon: 'mdi mdi-ticket-confirmation-outline',
    subs: [
      { link: '/report-cargo', title: 'Lệnh chuyển hàng CN' },
      { link: '/report-money', title: 'Lệnh chuyển tiền CN' },
      { link: '/report-branch', title: 'Bảng kê CN' },
      { link: '/report-bi', title: 'Bảng kê các loại bì CN' },
      { link: '/report-daily', title: 'Doanh thu CN / Ngày' },
      { link: '/report-monthly', title: 'Doanh thu CN / Tháng' },
      { link: '/report-management', title: 'Doanh thu quản trị ngày' },
      { link: '/report-refund', title: 'Hàng trả CN' },
    ],
  },
  {
    link: '/#',
    title: 'Báo cáo',
    allow: [Role.Accountant],
    icon: 'mdi mdi-ticket-confirmation-outline',
    subs: [
      { link: '/report-company', title: 'Bảng kê Cty' },
      { link: '/report-branch-accountant', title: 'Bảng kê CN' },
      { link: '/report-bi-cty-detail', title: 'Bảng kê các loại bì Cty (chi tiết)' },
      { link: '/report-bi-cty-summary', title: 'Bảng kê các loại bì Cty (tổng hợp)' },
      { link: '/report-bi-accountant', title: 'Bảng kê các loại bì CN' },
      { link: '/report-management-accountant', title: 'Doanh thu quản trị ngày' },
      { link: '/report-refund-company', title: 'Hàng trả Cty' },
      { link: '/report-refund-accountant', title: 'Hàng trả CN' },
      { link: '/report-payoff-company', title: 'Tồn kho Cty' },
      { link: '/report-payoff-accountant', title: 'Tồn kho CN' },
    ],
  },
  {
    link: '/#',
    title: 'Quản lý',
    icon: 'mdi mdi-database-settings',
    allow: [Role.Admin],
    subs: [
      { link: '/services', title: 'Dịch vụ' },
      { link: '/service-price', title: 'Bảng giá' },
      { link: '/users', title: 'Thành viên' },
      { link: '/customers', title: 'Khách hàng' },
      { link: '/branches', title: 'Chi nhánh' },
    ],
  },
];
