import { useCallback, useEffect, useMemo, useState } from 'react';
// import { ToastContainer } from 'react-toastify';
import { produce } from 'immer';
import { get } from 'lodash';

import { Authentication2Context, AuthenticationContext } from './context';
import { getCurrentInfo, updateProfile } from 'services/auth';
import VerticalLayout from 'components/VerticalLayout';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { getUserInfo, setUserInfo } from 'utils/localStorage';

export const AuthenticationProvider = ({ children }) => {
  const [{ userInfo }, setState] = useState({
    userInfo: getUserInfo() || null,
  });

  const callAPI = useCallback(async () => {
    try {
      const res = await getCurrentInfo();
      setUserInfo(res);
      setState(
        produce(draft => {
          draft.userInfo = res;
        })
      );
    } catch (error) {
      setState(
        produce(draft => {
          draft.userInfo = null;
        })
      );
    }
  }, []);
  useEffect(() => {
    callAPI();
  }, [callAPI]);

  const pushNotification = useCallback((type, message) => {
    const option = {
      progressBar: true,
      preventDuplicates: true,
      showEasing: true,
      hideEasing: true,
    };

    switch (type) {
      case 'error':
        toastr.error(message, option);
        break;
      case 'warning':
        toastr.warning(message, option);
        break;
      case 'success':
        toastr.success(message, option);
        break;
      default:
        toastr(message, option);
        break;
    }
  }, []);

  const updateUserInfo = useCallback(
    async info => {
      // const req = pick(info, ['fullname', 'phone', 'email']);
      await updateProfile(get(info, '_id.$oid'), info);
      callAPI();
    },
    [callAPI]
  );

  const value = useMemo(
    () => ({
      pushNotification,
      userInfo,
      updateUserInfo,
    }),
    [pushNotification, updateUserInfo, userInfo]
  );

  return (
    <>
      {/* <ToastContainer /> */}
      <AuthenticationContext.Provider value={value}>
        <Authentication2Context.Provider value={value}>
          <VerticalLayout>{children}</VerticalLayout>
        </Authentication2Context.Provider>
      </AuthenticationContext.Provider>
    </>
  );
};
