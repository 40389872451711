import { useMemo } from 'react';
import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 270px;
  cursor: pointer;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #74788d;

  display: flex;
`;

const DatePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;

  width: 100px;
  height: 45px;
  padding: 0 8px;

  border: unset;
  box-sizing: border-box;
  border-radius: 20px;
`;

const LineBreak = styled.div`
  height: 45px;
  width: 1px;
  background-color: #74788d;
`;

const Button = styled.div`
  background: #556ee6;
  color: #fff;
  width: 70px;
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 0px 20px 20px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDatePicker = ({ button = {}, params = {}, from, to, placeholder = '', onApply, onChange }) => {
  const minDate = useMemo(() => params[from], [from, params]);
  const maxDate = useMemo(() => params[to], [params, to]);

  return (
    <Wrapper>
      <DatePicker
        className="me-2"
        value={params[from]}
        onChange={(e, newV) => {
          onChange({ target: { name: from, value: newV } });
        }}
        placeholder={placeholder}
        options={{
          dateFormat: 'Y/m/d',
          maxDate,
        }}
      />
      <LineBreak />
      <DatePicker
        className="me-2"
        value={params[to]}
        onChange={(e, newV) => {
          onChange({ target: { name: to, value: newV } });
        }}
        placeholder={placeholder}
        options={{
          dateFormat: 'Y/m/d',
          minDate,
        }}
      />
      <Button {...button} onClick={onApply}>
        {button.title || 'Tìm'}
      </Button>
    </Wrapper>
  );
};

export default FilterDatePicker;
