import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from 'pages/Authentication/Login';
import Logout from 'pages/Authentication/Logout';
import ForgetPwd from 'pages/Authentication/ForgetPassword';

// Dashboard
import { OrderMoney, OrderProduct } from 'pages/Order';
import { CommonManagement } from 'components/own';
import { ServiceItem } from 'pages/Services';
import { ServicePriceItem } from 'pages/ServicePrice';
import { UserItem } from 'pages/UserManagement';
import { BranchItem } from 'pages/Branch';
import { CommonManagementV2 } from 'components/own/ManagementV2';
import { AdminGuard } from './guard/AdminGuard';
import { UserGuard } from './guard/UserGuard';
import { AccountantGuard } from './guard/AccountantGuard';
import { Home } from 'pages/Home';
import { ImportComponent } from 'pages/Import';
import { CustomerOrder } from 'pages/CustomerOrder';
import { TruckOrder } from 'pages/TruckOrder';
// import { CommonManagement } from 'components/own/Report';

const authProtectedRoutes = [
  { path: '/import-sending-slip', component: ImportComponent, urlDetail: 'imports/import/vtsg/hanggui/', guard: UserGuard },
  { path: '/import-receipt-voucher', component: ImportComponent, urlDetail: 'imports/import/vtsg/hangnhan/', guard: UserGuard },

  { path: '/lookup', component: CommonManagement, guard: UserGuard },
  { path: '/customer-orders', component: CommonManagement, guard: UserGuard },
  { path: '/customer-orders/:id', component: CustomerOrder, guard: UserGuard },
  { path: '/truck-orders', component: CommonManagement, guard: UserGuard },
  { path: '/truck-orders/:id', component: TruckOrder, guard: UserGuard },
  { path: '/truck-lookup', component: CommonManagement, guard: UserGuard },

  { path: '/dashboard', component: Home },
  { path: '/orders/:id', component: OrderProduct, guard: UserGuard },
  { path: '/orders-send', component: CommonManagement, guard: UserGuard },
  { path: '/orders-send/:id', component: OrderProduct, urlDetail: 'sent_nr/', guard: UserGuard },
  { path: '/orders-receive', component: CommonManagement, guard: UserGuard },
  { path: '/orders-receive/:id', component: OrderProduct, urlDetail: 'received_nr/', guard: UserGuard },
  { path: '/cash/:id', component: OrderMoney, guard: UserGuard },
  { path: '/cash-send/:id', component: OrderMoney, urlDetail: 'sent_nr/', guard: UserGuard },
  { path: '/cash-receive/:id', component: OrderMoney, urlDetail: 'received_nr/', guard: UserGuard },

  { path: '/services', component: CommonManagement, guard: AdminGuard },
  { path: '/services/:id', component: ServiceItem, guard: AdminGuard },

  { path: '/service-price', component: CommonManagement, guard: AdminGuard },
  { path: '/service-price/:id', component: ServicePriceItem, guard: AdminGuard },

  { path: '/users', component: CommonManagement, guard: AdminGuard },
  { path: '/users/:id', component: UserItem, guard: AdminGuard },

  { path: '/customers', component: CommonManagement, guard: AdminGuard },
  { path: '/branches', component: CommonManagement, guard: AdminGuard },
  { path: '/branches/:id', component: BranchItem, guard: AdminGuard },

  { path: '/report-cargo', component: CommonManagement, guard: UserGuard },
  { path: '/report-money', component: CommonManagement, guard: UserGuard },
  { path: '/report-branch', component: CommonManagementV2, guard: UserGuard },
  { path: '/report-bi', component: CommonManagementV2, guard: UserGuard },
  { path: '/report-daily', component: CommonManagement, guard: UserGuard },
  { path: '/report-monthly', component: CommonManagement, guard: UserGuard },
  { path: '/report-refund', component: CommonManagement, guard: UserGuard },
  { path: '/report-payoff', component: CommonManagement, guard: UserGuard },
  { path: '/report-management', component: CommonManagement, guard: UserGuard },
  { path: '/report-management-accountant', component: CommonManagement, guard: AccountantGuard },

  { path: '/report-company', component: CommonManagementV2, guard: AccountantGuard },
  { path: '/report-branch-accountant', component: CommonManagementV2, guard: AccountantGuard },
  { path: '/report-bi-cty-detail', component: CommonManagementV2, guard: AccountantGuard },
  { path: '/report-bi-cty-summary', component: CommonManagementV2, guard: AccountantGuard },
  { path: '/report-bi-accountant', component: CommonManagementV2, guard: AccountantGuard },

  { path: '/report-refund-company', component: CommonManagement, guard: AccountantGuard },
  { path: '/report-refund-accountant', component: CommonManagement, guard: AccountantGuard },
  { path: '/report-payoff-company', component: CommonManagement, guard: AccountantGuard },
  { path: '/report-payoff-accountant', component: CommonManagement, guard: AccountantGuard },

  // this route should be at the end of all other routes
  { path: '/', component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };
