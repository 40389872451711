import { ControlTypes } from 'constants/field';
import { CustomAutocomplete, InputCurrency } from '.';
import FieldCheckbox from './Checkbox';
import CustomDatePicker from './CustomDatePicker';
import CustomInput from './Input';
import RadioGroup from './RadioGroup';
import SelectPicker from './SelectPicker';

const FieldInput = ({ controlType, onSelect, sum, ...rest }) => {
  switch (controlType) {
    case ControlTypes.SPACE:
      return <div />;

    case ControlTypes.SELECT_PICKER:
      return <SelectPicker {...rest} />;

    case ControlTypes.CHECK_BOX:
      return <FieldCheckbox {...rest} />;

    case ControlTypes.RADIO_GROUP:
      return <RadioGroup {...rest} />;

    case ControlTypes.DATE_PICKER:
      return <CustomDatePicker {...rest} />;

    case ControlTypes.CURRENCY:
      return <InputCurrency {...rest} />;

    case ControlTypes.AUTOCOMPLETE:
      return <CustomAutocomplete onSelect={onSelect} {...rest} />;

    default:
      return <CustomInput {...rest} />;
  }
};

export default FieldInput;
