export const DeliveryStateOptions = [
  { label: 'Chưa xử lý', value: 'Chưa xử lý' },
  { label: 'Đến nơi nhận', value: 'Đến nơi nhận' },
  { label: 'Hoàn tất', value: 'Hoàn tất' },
  { label: 'Hàng thanh lý', value: 'Hàng thanh lý' },
  { label: 'Quay về nơi gửi', value: 'Quay về nơi gửi' },
  { label: 'Chuyển tiếp nơi nhận', value: 'Chuyển tiếp nơi nhận' },
];

export const CustomerOrderStatusOptions = [
  { label: 'Mới tạo', value: 'Mới tạo' },
  { label: 'Đang xử lý', value: 'Đang xử lý' },
  { label: 'Hoàn tất', value: 'Hoàn tất' },
  { label: 'Huỷ', value: 'Huỷ' },
];

export const TruckOrderStatusOptions = [
  { label: 'Mới tạo', value: 'Mới tạo' },
  { label: 'Đang xử lý', value: 'Đang xử lý' },
  { label: 'Hoàn tất', value: 'Hoàn tất' },
  { label: 'Huỷ', value: 'Huỷ' },
];
