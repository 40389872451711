import axios from 'axios';
import { NORMAL_END_POINT } from 'constants/common';

export const getTruckOrderDetail = id =>
  axios({
    url: NORMAL_END_POINT + 'truck_orders/detail/',
    method: 'POST',
    data: JSON.stringify({ id }),
  });

export const updateTruckOrder = data =>
  axios({
    url: NORMAL_END_POINT + 'truck_orders/update/',
    method: 'POST',
    data,
  });

export const finishTruckOrder = id =>
  axios({
    url: NORMAL_END_POINT + 'truck_orders/inactive/',
    method: 'POST',
    data: { id },
  });

export const transferTruckOrder = data =>
  axios({
    url: NORMAL_END_POINT + 'truck_orders/transfer/',
    method: 'POST',
    data,
  });
