import { getReportBiInfo } from './report-bi';
import { getReportBiAccountantInfo } from './report-bi-accountant';
import { getReportBiDetailInfo } from './report-bi-detail';
import { getReportBiSummaryInfo } from './report-bi-summary';
import { getReportBranchInfo } from './report-branch';
import { getReportBranchAccountantInfo } from './report-branch-accountant';
import { getReportCompanyInfo } from './report-company';

export const getInfoManagementByUrl = url => {
  switch (url) {
    case '/report-branch':
      return getReportBranchInfo();

    case '/report-bi':
      return getReportBiInfo();

    case '/report-company':
      return getReportCompanyInfo();

    case '/report-branch-accountant':
      return getReportBranchAccountantInfo();

    case '/report-bi-cty-detail':
      return getReportBiDetailInfo();

    case '/report-bi-cty-summary':
      return getReportBiSummaryInfo();

    case '/report-bi-accountant':
      return getReportBiAccountantInfo();

    case '/them-o-day-ne':
      return {};

    default:
      return {
        buttonAddTitle: '',
        columns: [],
        columnTemplate: '',
        filterElements: [],
        selectable: false,
        title: '',
        url: '',
      };
  }
};
