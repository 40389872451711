import { useMemo } from 'react';
import styled from 'styled-components';
import { ButtonOutline } from 'components/own';
import { useCommonManagementHook } from './useCommonHook';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContent from './TableContent';

const Button = styled.div`
  background: ${({ background }) => background};
  color: #fff;
  width: ${({ width }) => width};
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 20px;
  height: 38px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const CommonManagement = ({ hideIfEmpty }) => {
  const props = useCommonManagementHook();
  const {
    buttonAddTitle,
    contentRef,
    data,
    hideBreadcrumb,
    title,
    selectable,
    summary = {},
    selectedSummary = {},
    exportUrl,
    page,
    showPrint,
    selected,
    handleOnSelect,
    handleOnSelectAll,
    onPageChange,
    onAddNew,
    onClickChangeStatus,
    onExport,
    onPrint,
    onApply,
  } = props;

  const breadcrumbItems = useMemo(
    () =>
      !hideBreadcrumb
        ? [
            { title: 'AnPhu', link: '/' },
            { title, link: '#' },
          ]
        : [],
    [hideBreadcrumb, title]
  );

  const selectRowProp = useMemo(
    () =>
      selectable
        ? {
            mode: 'checkbox',
            selected: selected,
            onSelect: handleOnSelect,
            onSelectAll: handleOnSelectAll,
          }
        : undefined,
    [handleOnSelect, handleOnSelectAll, selectable, selected]
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: 10,
      totalSize: data.length,
      onPageChange,
      page,
      custom: true,
    }),
    [data.length, onPageChange, page]
  );
  const pageOptionPreview = useMemo(
    () => ({
      sizePerPage: data.length || 1,
      totalSize: data.length,
      custom: true,
    }),
    [data.length]
  );

  const summaryPrint = useMemo(() => ({ ...summary, ...selectedSummary }), [selectedSummary, summary]);

  if (hideIfEmpty && !data.length) return <div id="refresh-common-management-button" className="d-none" onClick={onApply}></div>;

  return (
    <div className="page-content">
      <div id="refresh-common-management-button" className="d-none" onClick={onApply}></div>
      <div className="d-flex align-items-center justify-content-end">
        {buttonAddTitle && <ButtonOutline onClick={onAddNew}>{buttonAddTitle}</ButtonOutline>}
        <div className="flex-1"></div>
        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
      </div>
      <TableContent {...props} pageOptions={pageOptions} selectRowProp={selectRowProp} />
      <div className="d-none">
        <TableContent previewMode {...props} ref={contentRef} pageOptions={pageOptionPreview} summary={summaryPrint} />
      </div>
      <div className="d-flex align-items-center mt-4">
        {selectable && (
          <Button background="#556EE6" width="300px" onClick={onClickChangeStatus}>
            Cập nhập sang TT Đến nơi nhận
          </Button>
        )}
        {showPrint && (
          <Button background="#DC3E15" width="100px" onClick={onPrint}>
            In LCH
          </Button>
        )}
        {!!exportUrl && (
          <Button background="#1D6F42" width="120px" onClick={onExport}>
            Xuất Excel
          </Button>
        )}
      </div>
    </div>
  );
};

export default CommonManagement;
