import { find } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/util';

const LabelInput = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;

const SelectPicker = ({ autoFocus, error, isRequired, label, name, onChange, options, value, ...rest }) => {
  const selectedValue = useMemo(() => (value ? find(options, ['value', value]) : null), [options, value]);
  const handleSelectGroup = useCallback(
    item => {
      onChange({ target: { name, value: item.value } });
    },
    [name, onChange]
  );
  const customStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        borderColor: !!error ? '#ff3d60 !important' : 'hsl(0, 0%, 80%)',
        boxShadow: !!error ? 'unset !important' : '',
      }),
    }),
    [error]
  );

  return (
    <>
      <LabelInput className="col-md-3 col-form-label">
        {label} {isRequired && '*'}
      </LabelInput>
      <Col md={9}>
        <Select
          classNamePrefix="select2-selection"
          styles={customStyles}
          onChange={handleSelectGroup}
          options={options}
          value={selectedValue}
          {...rest}
        />
      </Col>
    </>
  );
};

export default memo(SelectPicker, propsAreEqual);
