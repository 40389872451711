import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './ProfileDialog.module.scss';
import { get } from 'lodash';
import * as yup from 'yup';

import { Modal, Row } from 'reactstrap';
import { FieldInput } from 'components/own';
import { Formik } from 'formik';
import { ControlTypes } from 'constants/field';
import { getBranches } from 'services/branch';
import produce from 'immer';
import cloneDeep from 'lodash.clonedeep';

const ProfileDialog = ({ onHide, onUpdate, open, data }) => {
  const [{ options }, setState] = useState({ options: {} });
  useEffect(() => {
    getBranches().then(res => {
      setState(
        produce(draft => {
          draft.options.branch = res.map(_s => ({ label: _s.branch_name, value: get(_s, ['_id', '$oid']) }));
        })
      );
    });
  }, []);
  const onSave = useCallback(
    values => {
      onHide();
      onUpdate(cloneDeep(values));
    },
    [onHide, onUpdate]
  );

  const account = useMemo(
    () => [
      {
        name: 'username',
        label: 'Tên tài khoản',
      },
      {
        name: 'scope',
        label: 'Quyền',
      },
      {
        name: 'branch.$oid',
        label: 'Chi nhánh',
        controlType: ControlTypes.SELECT_PICKER,
        option: 'branch',
      },
    ],
    []
  );

  const infos = useMemo(
    () => [
      {
        name: 'fullname',
        label: 'Họ và tên',
      },
      {
        name: 'phone',
        label: 'Số điện thoại',
      },
      {
        name: 'email',
        label: 'Email',
      },
    ],
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        phone: yup
          .string()
          .nullable()
          .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
        email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
        fullname: yup.string().required('Vui lòng nhập họ và tên.'),
      }),
    []
  );

  return (
    <Modal size="l" centered={true} isOpen={open} toggle={onHide}>
      <div className={styles.popup__header}>
        <i className="fas fa-user me-2"></i>
        Hồ sơ người dùng
      </div>
      {open && !!data && (
        <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
            <>
              <div className={styles.popup__body}>
                <div className={styles.title}>Thông tin tài khoản</div>
                {account.map((field, index) => (
                  <Row key={index}>
                    <FieldInput
                      {...field}
                      value={get(values, field.name)}
                      readOnly
                      disabled
                      options={options[field.option]}
                      placeholder={field.placeholder || field.label}
                    />
                  </Row>
                ))}
              </div>
              <div className={styles.line}></div>
              <div className={styles.popup__body}>
                <div className={styles.title}>Thông tin tài khoản</div>
                {infos.map((field, index) => (
                  <Row key={index}>
                    <FieldInput
                      {...field}
                      value={get(values, field.name)}
                      error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                      options={options[field.option]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={field.placeholder || field.label}
                    />
                  </Row>
                ))}
              </div>
              <div className={styles.popup__button} onClick={handleSubmit}>
                Lưu chỉnh sửa
              </div>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default memo(ProfileDialog);
