import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${({ width }) => width + 70 || '190px'};
  cursor: pointer;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #74788d;

  display: flex;
`;

const Button = styled.div`
  background: #556ee6;
  color: #fff;
  width: 70px;
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 0px 20px 20px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const DatePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;

  width: ${({ width }) => width || '100px'};
  height: 45px;
  padding: 0 8px;

  border: unset;
  box-sizing: border-box;
  border-radius: 20px;
`;

const FilterDatePicker = ({ button = {}, maxDate, minDate, name, placeholder = '', onApply, onChange, value = '', width }) => {
  return (
    <Wrapper width={width}>
      <DatePicker
        width={width}
        className="me-2"
        value={value}
        onChange={(e, newV) => {
          onChange({ target: { name, value: newV } });
        }}
        placeholder={placeholder}
        options={{
          dateFormat: 'Y/m/d',
          maxDate,
          minDate,
        }}
      />
      <Button {...button} onClick={onApply}>
        {button.title || 'Tìm'}
      </Button>
    </Wrapper>
  );
};

export default FilterDatePicker;
