import { useMemo } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
const Wrapper = styled.div`
  width: ${({ width }) => width + 70 || '170px'};
  cursor: pointer;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #74788d;

  display: flex;
`;

const YearPicker = styled(DatePicker)`
  cursor: pointer;

  font-size: 14px;

  width: ${({ width }) => width || '100px'};
  height: 45px;
  padding: 0 8px;

  border: unset;
  box-sizing: border-box;
  border-radius: 20px;
`;

const Button = styled.div`
  background: #556ee6;
  color: #fff;
  width: 70px;
  /* Item */

  border-left: 1px solid #74788d;
  border-radius: 0px 20px 20px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterYearPicker = ({ button = {}, name, placeholder = '', onApply, onChange, value = '', width }) => {
  const year = useMemo(() => new Date().setFullYear(value), [value]);
  return (
    <Wrapper width={width}>
      <YearPicker
        width={width}
        className="me-2"
        selected={year}
        onChange={date => {
          onChange({ target: { name, value: new Date(date).getFullYear() } });
        }}
        showYearPicker
        dateFormat="yyyy"
        placeholder={placeholder}
      />
      <Button {...button} onClick={onApply}>
        {button.title || 'Tìm'}
      </Button>
    </Wrapper>
  );
};

export default FilterYearPicker;
