import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportDailyInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  showColumnBranch: true,
  columns: [
    {
      dataField: 'report_date',
      text: 'Ngày',
    },
    {
      dataField: 'item_count',
      text: 'SLP',
    },
    {
      dataField: 'quantity',
      text: 'SLH',
    },
    {
      dataField: 'dathu_fee',
      text: 'Đã thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'chuathu_fee',
      text: 'Chưa thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thuho_count',
      text: 'SPTH',
    },
    {
      dataField: 'thuho_fee',
      text: 'Thu hộ',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu',
      text: 'TổngDT1',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu_chuathu',
      text: 'TổngDT2',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'anphu_fee',
      text: 'DT An Phú',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'trahang_cn_count',
      text: 'SPTHCN',
    },
    {
      dataField: 'thuho_cn',
      text: 'Thu hộ CN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'total_dathu_thcn',
      text: 'TổngDT CN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'đã thu', name: 'dathu_fee' },
        { label: 'Phí Thu hộ', name: 'thuho_fee' },
        { controlType: ControlTypes.LINE },
        { label: 'Tổng cộng', name: 'total_dathu', fontWeight: 700 },
        { label: 'Tổng tiền chưa thu', name: 'chuathu_fee' },
        { controlType: ControlTypes.LINE },
        { label: 'Doanh thu', name: 'total_dathu_chuathu' },
        { label: 'Hoàn tất phiếu', name: 'thuho_cn' },
        { label: 'Tổng thực thu', name: 'total_dathu_thcn' },
      ],
    },
  ],
  totalBlock: [
    { label: 'SLP : ', name: 'item_count' },
    { label: 'SLH : ', name: 'quantity' },
    { label: 'SPTHCN : ', name: 'thuho_count' },
  ],
  defaultParams: { from_date: new Date().toLocaleDateString('en-ZA'), to_date: new Date().toLocaleDateString('en-ZA') },
  filterElements: [
    {
      name: 'from_date',
      from: 'from_date',
      to: 'to_date',
      controlType: ControlTypes.DATE_RANGE_PICKER,
    },
  ],
  title: 'DOANH THU CHI NHÁNH NGÀY',
  showBranchFilter: true,
  detailUrl: '',
  needApply: true,
  showPrint: true,
  url: NORMAL_END_POINT + 'reports/doanhthungay/',
  exportUrl: NORMAL_END_POINT + 'reports/doanhthungay/excel',
});
