import { propsAreEqual } from 'utils/util';
import { memo, useCallback, useState } from 'react';
import { Col, Label } from 'reactstrap';
import produce from 'immer';
import { callGetAPI } from 'services/common';
import Autocomplete from 'react-autocomplete';
import { get, omit } from 'lodash';
import styled from 'styled-components';

const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 12px;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  cursor: pointer;
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#f1f5f7' : '#fff')};
  font-weight: ${({ isHighlighted }) => (isHighlighted ? '700' : '400')};
`;

const initialState = {
  selectedValue: '',
  options: [],
};

const menuStyle = {
  borderRadius: '3px',
  zIndex: 2,
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
};
var timeout;
const CustomAutocomplete = ({
  autoFocus,
  error,
  touched,
  isRequired,
  label,
  name,
  onChange,
  onSelect,
  options: _opt,
  placeholder,
  readOnly,
  value,
  url,
  onBlur,
  onFocus,
  onKeyDown,
  optionConfig = {
    labelKey: 'phone',
    subLabelKey: 'fullname',
  },
}) => {
  const [{ options }, setState] = useState(initialState);

  const onInputChange = useCallback(
    e => {
      onChange(e);
      if (!e.target.value) return;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        // setState(
        //   produce(draft => {
        //     draft.options = dataMockup.map(_r => ({ label: get(_r, 'phone', ''), value: _r }));
        //   })
        // );
        callGetAPI(url + e.target.value).then(res => {
          if (!Array.isArray(res)) {
            setState(
              produce(draft => {
                draft.options = [];
              })
            );
            return;
          }
          setState(
            produce(draft => {
              draft.options = res.map(_r => ({
                label: get(_r, optionConfig.labelKey, ''),
                subLabel: get(_r, optionConfig.subLabelKey, ''),
                value: omit(_r, ['_id']),
              }));
            })
          );
        });
      }, 350);
    },
    [onChange, optionConfig.labelKey, optionConfig.subLabelKey, url]
  );
  // const onSelect = useCallback(val => {}, []);

  return (
    <>
      <Label className="col-md-12 col-form-label">
        {label} {isRequired && <b style={{ color: '#DC3E15' }}>*</b>}
      </Label>
      <Col md={12}>
        <Autocomplete
          getItemValue={item => item.label}
          items={options}
          renderItem={(item, isHighlighted) => (
            <Item key={item.label} isHighlighted={isHighlighted}>
              <div>{item.label}</div>
              <div>{item.subLabel}</div>
            </Item>
          )}
          value={value}
          inputProps={{
            readOnly,
            onBlur,
            onFocus,
            onKeyDown,
            placeholder,
            name,
            className: 'form-control',
          }}
          menuStyle={menuStyle}
          wrapperStyle={{ width: '100%' }}
          onChange={onInputChange}
          onSelect={onSelect}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(CustomAutocomplete, propsAreEqual);
