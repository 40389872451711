import CellAction from 'components/own/Table/CellRendering/CellAction';
import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import { NORMAL_END_POINT } from 'constants/common';
import { parsePrice } from 'utils/util';
import { getStateBackground, getStateColor } from './report-branch';

export const getCustomerOrderInfo = () => ({
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'id',
      text: '',
      formatter: cell => <CellAction value={cell} detailUrl={'customer-orders'} />,
    },
    {
      dataField: 'created_date',
      text: 'Ngày',
    },
    {
      dataField: 'order_status',
      text: 'Trạng thái',
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus dataField="order_status" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },

    {
      dataField: 'from_branch.branch_code',
      text: 'Nơi giao',
    },
    {
      dataField: 'to_branch.branch_code',
      text: 'Nơi nhận',
    },
    {
      dataField: 'from_person',
      text: 'Người gửi',
    },
    {
      dataField: 'from_person_phone',
      text: 'SDT NG',
    },
    {
      dataField: 'to_person',
      text: 'Người nhận',
    },
    {
      dataField: 'to_person_phone',
      text: 'SDT NN',
    },
    {
      dataField: 'weight',
      text: 'Trọng lượng(kg)',
    },
    {
      dataField: 'kichthuoc.0.0',
      text: 'Chiều dài',
    },
    {
      dataField: 'kichthuoc.0.1',
      text: 'Chiều rộng',
    },
    {
      dataField: 'estimate_fee',
      text: 'Cước tự động',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
  ],

  totalBlock: [
    { label: 'Tổng đơn : ', name: 'item_count' },
    { label: 'Số lượng : ', name: 'quantity_count' },
  ],
  summaryBlock: [],
  selectable: false,
  showSearch: true,
  title: 'DANH SÁCH VẬN ĐƠN',
  hideBreadcrumb: true,
  detailUrl: 'customer-orders',
  url: NORMAL_END_POINT + 'customer_orders/list/',
});
