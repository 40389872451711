import { propsAreEqual } from 'utils/util';
import { memo, useEffect, useRef } from 'react';
import { Col, Input, Label } from 'reactstrap';

const CustomInput = ({ autoFocus, error, touched, isRequired, label, name, ...rest }) => {
  const ref = useRef(name);
  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);
  return (
    <>
      <Label className="col-md-12 col-form-label">
        {label} {isRequired && <b style={{ color: '#DC3E15' }}>*</b>}
      </Label>
      <Col md={12}>
        <Input innerRef={ref} autoFocus={autoFocus} invalid={!!error} name={name} {...rest} />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(CustomInput, propsAreEqual);
