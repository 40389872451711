import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { produce } from 'immer';
import { useHistory, useParams } from 'react-router';

import { Button, Card, Col, Container, Label, Row } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { fields } from 'mockups/order';
import { FieldInput } from 'components/own';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { KEY_ENTER, KEY_TAB } from 'constants/keycode';
// import { getBranches } from 'services/branch';
import { ControlTypes } from 'constants/field';
import { usePushNotification } from 'hook/useContextSelector';
import { NORMAL_END_POINT } from 'constants/common';
import { finishTruckOrder, getTruckOrderDetail, updateTruckOrder } from 'services/truck-order';
import { TruckOrderStatusOptions } from 'constants/options';

const Root = styled(Container)`
  width: 100%;
  height: 100%;
  padding: 47px 160px;
  background-color: #fff;
  input:not([type='radio']) {
    background-color: #f1f5f7;

    &:not(:placeholder-shown) {
      background-color: #fff;
    }
    &:read-only {
      &:not(.flatpickr-input) {
        background-color: #e0e0e0;
      }
    }
    &.flatpickr-input.disabled {
      background-color: #e0e0e0;
    }
  }
  .select2-selection__control--is-disabled {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: #505d69;
`;

// const OrderTitle = styled.div`
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 150%;
//   /* identical to box height, or 36px */

//   display: flex;
//   align-items: center;

//   /* Secondary */

//   color: #505d69;
// `;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const FieldContent = styled(Card)`
  background-color: #fff;
  padding: 26px;
  display: grid;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);

  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const GridContent = styled.div`
  display: grid;
  grid-template-columns: ${({ $templateColumn }) => $templateColumn || '1fr'};
  grid-column-gap: ${({ $templateColumn }) => ($templateColumn ? '50px' : '16px')};
  grid-row-gap: 10px;
  align-items: baseline;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

// const NoteItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 8px;
// `;

// const NoteInfo = styled.div`
//   display: grid;
//   grid-template-columns: 20px 1fr max-content;
//   align-items: center;
// `;

// const NoteInfoUser = styled.div`
//   font-weight: 500;
//   color: #000;
// `;

// const NoteInfoDate = styled.div``;
// const NoteDescription = styled.div`
//   margin-left: 20px;
// `;

const breadcrumbItems = [
  { title: 'AnPhu', link: '/' },
  { title: 'Phiếu hàng hóa', link: '#' },
];

const initialState = {
  focusField: 'from_branch',
  options: {},
  loading: true,
  data: {},
};

const getOptions = (options = [], field, values) => {
  if (field.option !== 'branch') return options;
  let value = get(values, ['from_branch']);
  if (field.name === 'from_branch') {
    value = get(values, ['to_branch']);
  }
  return options.filter(_op => _op.value !== value);
};

// const isReadOnly = (field, isEditPage, scope) => {
//   if (scope !== Role.Admin && !isEditPage && field.name === 'from_branch') {
//     return true;
//   }
//   return field.readOnly;
// };

const TruckOrder = () => {
  const [{ focusField, options, data, loading }, setState] = useState(initialState);

  const params = useParams();
  const router = useHistory();
  // const userInfo = useUserInfo();

  const pushNotification = usePushNotification();

  const callAPIGet = useCallback((id, callback) => {
    getTruckOrderDetail(id).then(res => {
      setState(
        produce(draft => {
          draft.loading = false;
          if (res) {
            const data = get(res, ['data', 0], {});
            callback && callback(data);
            draft.data = data;
          } else draft.data = {};
        })
      );
    });
  }, []);

  useEffect(() => {
    if (params.id !== 'new') {
      callAPIGet(params.id);
    }
  }, [callAPIGet, params.id]);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     Promise.all([getBranches()]).then(res => {
  //       setState(
  //         produce(draft => {
  //           draft.options.branch = get(res, [0], [])?.map(_b => ({ label: _b.branch_name, value: get(_b, ['_id', '$oid']) }));
  //         })
  //       );
  //     });
  //   };
  //   callAPI();
  // }, []);

  const onChangeFocus = useCallback(field => {
    setState(
      produce(draft => {
        draft.focusField = field;
      })
    );
  }, []);

  const handleKeyDown = useCallback(
    e => {
      const currentIndex = fields.findIndex(_f => _f === focusField);
      const isLatest = currentIndex === fields.length - 1;
      switch (e.keyCode) {
        case KEY_ENTER:
        case KEY_TAB:
          e.preventDefault();
          if (currentIndex === -1 || isLatest) onChangeFocus(fields[0]);
          else onChangeFocus(fields[currentIndex + 1]);
          break;
        default:
          break;
      }
    },
    [focusField, onChangeFocus]
  );

  const infoFields = useMemo(
    () => ({
      person: [
        {
          title: 'Bên gửi',
          fields: [
            // {
            //   name: 'from_branch',
            //   label: 'Chi nhánh gửi',
            //   controlType: ControlTypes.SELECT_PICKER,
            //   option: 'branch',
            //   placeholder: 'Chọn chi nhánh',
            // },
            {
              name: 'from_person_phone',
              label: 'SDT',
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'from_person',
              label: 'Họ tên',
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            {
              name: 'from_person_address',
              label: 'Địa chỉ',
            },
          ],
        },
        {
          title: 'Bên nhận',
          fields: [
            // {
            //   name: 'to_branch',
            //   label: 'Chi nhánh nhận',
            //   controlType: ControlTypes.SELECT_PICKER,
            //   option: 'branch',
            //   placeholder: 'Chọn chi nhánh',
            // },
            {
              name: 'to_person_phone',
              label: 'SDT',
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/auto/',
            },
            {
              name: 'to_person',
              label: 'Họ tên',
              controlType: ControlTypes.AUTOCOMPLETE,
              url: NORMAL_END_POINT + 'customers/name/auto/',
              optionConfig: {
                labelKey: 'fullname',
                subLabelKey: 'phone',
              },
            },
            {
              name: 'to_person_address',
              label: 'Địa chỉ',
            },
          ],
        },
      ],
      product: [
        {
          gridTemplate: '1fr 1fr',
          columns: [
            { name: 'weight', label: 'Trọng lượng gói hàng (kg)', controlType: ControlTypes.CURRENCY },
            { name: 'quantity', label: 'Số lượng', controlType: ControlTypes.CURRENCY },
          ],
        },
        {
          gridTemplate: '1fr',
          columns: [{ name: 'created_date', label: 'Ngày tạo', controlType: ControlTypes.DATE_PICKER }],
        },
        // {
        //   gridTemplate: '1fr',
        //   columns: [
        //     {
        //       name: 'is_sender_pay',
        //       label: 'Phương thức thanh toán',
        //       controlType: ControlTypes.RADIO_GROUP,
        //       options: [
        //         { label: 'Bên nhận thanh toán', value: false },
        //         { label: 'Bên gửi thanh toán', value: true },
        //       ],
        //     },
        //   ],
        // },
        {
          gridTemplate: '1fr 1fr 1fr',
          columns: [
            { name: 'dai', label: 'Chiều dài (m)' },
            { name: 'rong', label: 'Chiều rộng (m)' },
            { name: 'cao', label: 'Chiều cao (m)' },
          ],
          field: 'kichthuoc',
        },
        // {
        //   gridTemplate: '1fr',
        //   columns: [
        //     {
        //       name: 'is_pickup',
        //       label: 'Lấy tận nơi, giao tận nhà',
        //       controlType: ControlTypes.RADIO_GROUP,
        //       options: [
        //         { label: 'Có', value: true },
        //         { label: 'Không', value: false },
        //       ],
        //     },
        //   ],
        // },
        {
          gridTemplate: '1fr',
          columns: [{ name: 'delivery_date', label: 'Ngày giao xe', controlType: ControlTypes.DATE_PICKER }],
        },

        // {
        //   gridTemplate: '1fr 1fr',
        //   columns: [
        //     { name: 'thuho_customer', label: 'Số tiền thu hộ', controlType: ControlTypes.CURRENCY },
        //     { name: 'thuho_fee', label: 'Phí thu hộ', controlType: ControlTypes.CURRENCY },
        //   ],
        // },
        // {
        //   gridTemplate: '1fr',
        //   columns: [{ name: 'from_person_address', label: 'Địa chỉ người gửi' }],
        // },
        // {
        //   gridTemplate: '1fr',
        //   columns: [],
        // },
        // {
        //   gridTemplate: '1fr',
        //   columns: [{ name: 'to_person_address', label: 'Địa chỉ người nhận' }],
        // },
        // {
        //   gridTemplate: '1fr',
        //   columns: [{ name: 'delivery_date', label: 'Ngày giao xe', controlType: ControlTypes.DATE_PICKER }],
        // },
        {
          gridTemplate: '1fr',
          columns: [{ name: 'estimate_fee', label: 'Cước phí tự động', controlType: ControlTypes.CURRENCY }],
        },
        {
          gridTemplate: '1fr',
          columns: [
            {
              name: 'order_status',
              label: 'Trạng thái',
              controlType: ControlTypes.SELECT_PICKER,
              options: TruckOrderStatusOptions,
            },
          ],
        },
      ],
    }),
    []
  );

  // const handlePrint = useReactToPrint({
  //   content: () => templateRef.current,
  // });

  const onSave = useCallback(
    (values, { resetForm, setValues }) => {
      const req = cloneDeep(values);
      if (req.id) {
        delete req.created_date;
        delete req.note_list;
        // if (!Array.isArray(req.note_list)) req.note_list = [];
        // if (req.currentNote) {
        //   req.note_list.push({
        //     stt: req.note_list.length + 1,
        //     created_by: userInfo.fullname,
        //     note: req.currentNote,
        //     created_date: parseDateTime(new Date(), '', 'en-ZA'),
        //   });
        //   delete req.currentNote;
        // }
        updateTruckOrder(req)
          .then(res => {
            callAPIGet(req.id, setValues);
            pushNotification('success', 'Cập nhật phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Cập nhật không thành công');
          });
      }
    },
    [callAPIGet, pushNotification]
  );

  const onFinish = useCallback(
    values => {
      const req = cloneDeep(values);
      if (req.id) {
        finishTruckOrder(req.id)
          .then(() => {
            router.replace('/truck-orders');
            pushNotification('success', 'Hoàn tất phiếu thành công');
          })
          .catch(error => {
            pushNotification('error', error.message || error || 'Hoàn tất phiếu không thành công');
          });
      }
    },
    [pushNotification, router]
  );

  const onBack = useCallback(() => {
    router.replace('/truck-orders');
  }, [router]);

  if (loading) return <div />;

  return (
    <div className="page-content">
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      <Root fluid>
        <Formik initialValues={data} onSubmit={onSave}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {}, setValues }) => (
            <>
              <Title className="justify-content-center mb-3">Chi tiết thuê xe tải</Title>
              {/* {params.id !== 'new' ? <OrderTitle className="mb-3">Mã vận đơn: {params.id}</OrderTitle> : <div className="mb-3" />} */}
              <UserInfo>
                {infoFields.person.map(({ title, fields }, index) => (
                  <Col xs={12} key={index}>
                    <FieldContent>
                      <SubTitle>
                        <i className="ri-send-plane-fill me-2"></i>
                        {title}
                      </SubTitle>
                      <div style={{ borderBottom: '1px solid #BDBDBD' }} />
                      {fields.map((field, i) => (
                        <Row key={i}>
                          <FieldInput
                            {...field}
                            error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                            value={get(values, field.name, '')}
                            autoFocus={focusField === field.name}
                            onKeyDown={handleKeyDown}
                            onFocus={() => onChangeFocus(field.name)}
                            onSelect={(val, item) => {
                              const itemValues = get(item, 'value', {});
                              const prefix = field.name.split('.')[0];
                              for (const key in itemValues) {
                                if (Object.hasOwnProperty.call(itemValues, key)) {
                                  const element = itemValues[key];
                                  setFieldValue(`${prefix}.${key}`, element);
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            readOnly
                            onChange={handleChange}
                            options={getOptions(options[field.option], field, values)}
                            placeholder={field.placeholder || `Nhập ${field.label}`}
                          />
                        </Row>
                      ))}
                    </FieldContent>
                  </Col>
                ))}
              </UserInfo>
              <FieldContent $templateColumn="1fr">
                <SubTitle className="d-flex align-items-center justify-content-center">
                  <i className="fas fa-info me-2"></i>
                  Thông tin hàng hoá
                </SubTitle>
                <div style={{ borderBottom: '1px solid #BDBDBD' }} />
                <GridContent $templateColumn="1fr 1fr">
                  {infoFields.product.map((rowData, i) =>
                    rowData.type === 'multiple-row' ? (
                      <GridContent key={i} $templateColumn={rowData.gridTemplate}>
                        {values[rowData.field]?.map((item, dataIndex) =>
                          rowData.columns.map((field, index) => (
                            <Row key={index}>
                              <FieldInput
                                {...field}
                                readOnly
                                controlType={ControlTypes.CURRENCY}
                                name={`${rowData.field}.${dataIndex}.${field.name}`}
                                error={
                                  get(touched, `${rowData.field}.${dataIndex}.${field.name}`) || !!submitCount
                                    ? get(errors, `${rowData.field}.${dataIndex}.${field.name}`)
                                    : null
                                }
                                value={get(item, field.name, '')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={field.placeholder || `Nhập ${field.label}`}
                              />
                            </Row>
                          ))
                        )}
                      </GridContent>
                    ) : (
                      <GridContent key={i} $templateColumn={rowData.gridTemplate}>
                        {rowData.columns.map(field => (
                          <Row key={field.name}>
                            <FieldInput
                              readOnly
                              {...field}
                              error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                              value={get(values, field.name, '')}
                              autoFocus={focusField === field.name}
                              onKeyDown={handleKeyDown}
                              onFocus={() => onChangeFocus(field.name)}
                              onSelect={(val, item) => {
                                const itemValues = get(item, 'value', {});
                                const prefix = field.name.split('.')[0];
                                for (const key in itemValues) {
                                  if (Object.hasOwnProperty.call(itemValues, key)) {
                                    const element = itemValues[key];
                                    setFieldValue(`${prefix}.${key}`, element);
                                  }
                                }
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              options={field.options}
                              placeholder={field.placeholder || `Nhập ${field.label}`}
                            />
                          </Row>
                        ))}
                      </GridContent>
                    )
                  )}
                </GridContent>
                <div className="mb-3">
                  <Label className="form-label">Note</Label>
                  {/* {Array.isArray(values.note_list) &&
                    values.note_list.map((_n, i) => (
                      <NoteItem>
                        <NoteInfo>
                          <div>{i + 1}.</div>
                          <NoteInfoUser>{_n.created_by}</NoteInfoUser>
                          <NoteInfoDate>{_n.created_date}</NoteInfoDate>
                        </NoteInfo>
                        <NoteDescription>{_n.note}</NoteDescription>
                      </NoteItem>
                    ))} */}
                  <textarea
                    className="form-control"
                    rows="2"
                    name="note"
                    value={values.note || ''}
                    placeholder="Note"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </FieldContent>

              <div className="d-flex align-items-center mt-3">
                <Button color="secondary" className="px-4 me-2" onClick={onBack}>
                  Thoát
                </Button>
                <Button className="px-4 me-2" color="info" onClick={handleSubmit}>
                  Lưu thay đổi
                </Button>
                <Button className="px-4 me-2" color="primary" onClick={() => onFinish(values)}>
                  Hoàn tất
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Root>
    </div>
  );
};

export default TruckOrder;
