import { NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';

export const getReportCompanyInfo = () => ({
  apiPost: true,
  showSearch: true,
  table: [
    {
      name: 'order',
      columns: [
        {
          dataField: 'order_nr',
          text: 'Mã sản phẩm',
        },
        {
          dataField: 'from_branch',
          text: 'Nơi giao',
        },
        {
          dataField: 'to_branch',
          text: 'Nơi nhận',
        },
        {
          dataField: 'from_person_name',
          text: 'Tên người gửi',
        },
        {
          dataField: 'from_person_phone',
          text: 'SDT NG',
        },
        {
          dataField: 'to_person_name',
          text: 'Tên người nhận',
        },
        {
          dataField: 'to_person_phone',
          text: 'SDT NN',
        },
        {
          dataField: 'service_type',
          text: 'Loại hàng',
        },
        {
          dataField: 'quantity',
          text: 'SL',
        },
        {
          dataField: 'note',
          text: 'Ghi chú',
        },
        {
          dataField: 'mc_fee',
          text: 'Phí MC',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'stpc_fee',
          text: 'STPC',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'dathu_fee',
          text: 'Đã thu',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'chuathu_fee',
          text: 'Chưa thu',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'thbh_fee',
          text: 'Thu hộ BH',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'ttn_fee',
          text: 'Tiền TTN',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'gtn_fee',
          text: 'Tiền GTN',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'anphu_fee',
          text: 'Phí AP',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'other_fee',
          text: 'Thu khác',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'gtn_address',
          text: 'Địa chỉ GTN',
        },
      ],
      filterElements: [
        {
          name: 'report_date',
          width: 200,
          controlType: ControlTypes.DATE_PICKER_WITH_APPLY,
          placeholder: 'Chọn ngày',
        },
      ],
      showBranchFilter: true,
      summaryBlock: [
        {
          items: [
            { label: 'TỔNG TIỀN ĐÃ THU', name: 'dathu_fee' },
            { label: 'TỔNG TIỀN CHƯA THU', name: 'chuathu_fee' },
            { controlType: ControlTypes.LINE },
            { label: 'TỔNG TIỀN TẠI TRẠM', name: 'total_fee', fontWeight: 700 },
            { label: 'TỔNG TIỀN THU HỘ', name: 'tongthuho_fee' },
            { label: 'TỔNG TIỀN CHI', name: 'chi_fee' },
            { controlType: ControlTypes.LINE },
            { label: 'TIỀN HÀNG BẢNG KÊ TRONG NGÀY', name: 'hang_fee' },
            { label: 'TỔNG STPC đã trừ phí COD', name: 'stpc_fee' },
            { label: 'TỔNG TIỀN TTN', name: 'ttn_fee' },
            { label: 'TỔNG TIỀN GTN', name: 'gtn_fee' },
            { label: 'THU KHÁC', name: 'other_fee' },
            { controlType: ControlTypes.DB_LINE },
            { label: 'SỐ TIỀN THỰC NỘP', name: 'thucnop_fee' },
          ],
        },
      ],
      totalBlock: [
        { label: 'Tổng phiếu: ', name: 'item_count' },
        { label: 'Số lượng : ', name: 'quantity_count' },
      ],
      title: 'BẢNG KÊ CHI NHÁNH',
    },
    {
      name: 'thuho',
      columns: [
        {
          dataField: 'received_date',
          text: 'Ngày gửi',
        },
        {
          dataField: 'done_date',
          text: 'Ngày hoàn tất',
        },
        {
          dataField: 'from_branch',
          text: 'Nơi gửi',
        },
        {
          dataField: 'to_branch',
          text: 'Nơi TH',
        },
        {
          dataField: 'order_nr',
          text: 'MSP',
        },
        {
          dataField: 'thuho_fee',
          text: 'Thu Hộ',
          formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
        },
        {
          dataField: 'received_by',
          text: 'Người nhận',
        },
      ],

      title: 'THU HỘ',
      totalBlock: [
        { label: 'Tổng phiếu: ', name: 'item_count' },
        { label: 'Tổng tiền thu hộ: ', name: 'thuho_fee' },
      ],
    },
  ],

  defaultParams: { report_date: new Date().toLocaleDateString('en-ZA') },

  needApply: true,
  reportCompany: true,
  title: 'BẢNG KÊ CHI NHÁNH',
  url: NORMAL_END_POINT + 'reports/bangke/',
  exportUrl: NORMAL_END_POINT + 'reports/bangke/excel',
});
