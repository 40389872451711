import CellStatus from 'components/own/Table/CellRendering/CellStatus';
import CellText from 'components/own/Table/CellRendering/CellText';
import { AUTH_END_POINT, NORMAL_END_POINT } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { parsePrice } from 'utils/util';
import { getStateBackground, getStateColor } from './report-branch';
import { includes } from 'lodash';
import { CheckCircleIcon } from 'components/icons';

export const getOrderReceiveInfo = () => ({
  apiPost: true,
  buttonAddTitle: '',
  showBranchFilter: true,
  columns: [
    {
      dataField: 'order_nr',
      text: 'Mã sản phẩm',
      formatter: cell => <CellText value={cell} link={includes(cell, 'COD') ? 'cash-receive/' + cell : 'orders-receive/' + cell} />,
    },
    {
      dataField: 'delivery_state',
      text: 'Trạng thái',
      sort: true,
      columnStatus: true,
      formatter: (v, data) => (
        <CellStatus dataField="delivery_state" data={data} background={getStateBackground} color={getStateColor} width="120px" />
      ),
    },
    {
      dataField: 'hang_noi_bo',
      text: 'Hàng nội bộ',
      sort: true,
      formatter: (v, data) =>
        !!v ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <CheckCircleIcon />
          </div>
        ) : (
          <div />
        ),
    },
    {
      dataField: 'ref_order_nr',
      text: 'Phiếu liên kết',
    },
    {
      dataField: 'from_branch.branch_code',
      text: 'Nơi gửi',
    },
    {
      dataField: 'to_branch.branch_code',
      text: 'Nơi nhận',
    },
    {
      dataField: 'from_person.fullname',
      text: 'Người gửi',
    },
    {
      dataField: 'from_person.phone',
      text: 'SDT NG',
    },
    {
      dataField: 'to_person.fullname',
      text: 'Người nhận',
    },
    {
      dataField: 'to_person.phone',
      text: 'SDT NN',
    },
    {
      dataField: 'service_type.service_detail',
      text: 'Tên LH',
    },
    {
      dataField: 'quantity',
      text: 'SLH',
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
    },
    {
      dataField: 'mc_fee',
      text: 'Phí MC',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'stpc_fee',
      text: 'STPC',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'dathu_fee',
      text: 'Đã thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'chuathu_fee',
      text: 'Chưa thu',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thbh_fee',
      text: 'THBH',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'ttn_fee',
      text: 'Tiền TTN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'delivery_fee',
      text: 'Tiền GTN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'other_fee',
      text: 'Thu khác',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'qnnt_fee',
      text: 'Phí An Phú',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'discount',
      text: 'Giảm giá',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'dcnn_fee',
      text: 'Thu DCNN',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'thng_fee',
      text: 'Thu THNG',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'luukho_fee',
      text: 'Phí LK',
      formatter: _v => <div className="price">{_v ? parsePrice(_v) + ' đ' : '0'}</div>,
    },
    {
      dataField: 'delivery_address',
      text: 'Địa chỉ GTN',
    },
    {
      dataField: 'received_date',
      text: 'Ngày Gửi',
    },
    {
      dataField: 'delivery_date',
      text: 'Ngày HT',
    },
    {
      dataField: 'service_type.service_type',
      text: 'Loại phiếu',
    },
    {
      dataField: 'delivery_by',
      text: 'Tên NVTH',
    },
    {
      dataField: 'created_by',
      text: 'NV Lập',
    },
  ],
  summaryBlock: [
    {
      items: [
        { label: 'TỔNG <b>PHÍ MC</b> : ', name: 'mc_fee' },
        { label: 'Tổng tiền  <b>STPC</b> : ', name: 'stpc_fee' },
        { label: 'TỔNG TIỀN <b>ĐÃ THU</b>', name: 'dathu_fee' },
        { label: 'TỔNG TIỀN <b>CHƯA THU</b>', name: 'chuathu_fee' },
        { label: 'TỔNG TIỀN <b>THU HỘ BH</b>', name: 'thbh_fee' },
        { label: 'TỔNG TIỀN <b>TTN</b>', name: 'ttn_fee' },
        { label: 'TỔNG TIỀN <b>GTN</b>', name: 'gtn_fee' },
      ],
    },
    {
      items: [
        { label: 'TỔNG TIỀN <b>THU KHÁC</b> : ', name: 'other_fee' },
        { label: 'Tổng TIỀN <b>PHÍ An Phú</b> : ', name: 'anphu_fee' },
        { label: 'TỔNG TIỀN <b>GIẢM GIÁ</b>', name: 'discount' },
        { label: 'TỔNG TIỀN <b>THU DCNN</b>', name: 'dcnn_fee' },
        { label: 'TỔNG TIỀN <b>THU THNG</b>', name: 'thng_fee' },
        { label: 'TỔNG TIỀN <b>PHÍ LK</b>', name: 'lk_fee' },
      ],
    },
  ],
  filterElements: [
    {
      name: 'from_branch',
      width: '140px',
      controlType: ControlTypes.SELECT_PICKER_WITH_APPLY,
      placeholder: 'Chi nhánh gửi',
      button: {
        title: 'Nạp',
      },
      optionEmpty: true,
      optionUrl: {
        url: AUTH_END_POINT + 'branchs',
        keyLabel: 'branch_name',
        keyValue: '_id.$oid',
      },
    },
    {
      name: 'from_date',
      from: 'from_date',
      to: 'to_date',
      controlType: ControlTypes.DATE_RANGE_PICKER,
      button: { title: 'Lọc' },
    },
  ],
  totalBlock: [
    { label: 'Tổng phiếu : ', name: 'item_count' },
    { label: 'Số lượng', name: 'quantity_count' },
  ],
  defaultParams: { from_date: new Date().toLocaleDateString('en-ZA'), to_date: new Date().toLocaleDateString('en-ZA') },
  needApply: true,
  showSearch: true,
  searchServerSide: true,
  title: 'Danh sách phiếu nhận',
  detailUrl: 'orders-receive',
  url: NORMAL_END_POINT + 'orders/received/',
  exportUrl: NORMAL_END_POINT + 'orders/received/excel/',
});
