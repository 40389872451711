import { isEqual, map } from 'lodash';

export const propsAreEqual = (prev, next) => {
  const keys = Object.keys(prev);
  for (const key in keys) {
    if (Object.hasOwnProperty.call(keys, key)) {
      const element = keys[key];
      if (typeof prev[element] === 'function') continue;
      if (!isEqual(prev[element], next[element])) {
        return false;
      }
    }
  }

  return true;
};

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const parseUrlQuery = (params, orderParams, prefix = '') => {
  let query = prefix;
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const element = params[key];
      if (!element || key === 'operatorId' || key === 'totalResults') continue;

      if (key === 'filterValue') {
        query += `&keyword=${element}`;
      } else query += `&${key}=${Array.isArray(element) ? map(element, 'value').join(',') : element}`;
    }
  }

  if (orderParams && orderParams?.order) {
    query += `&orderBy=${orderParams.orderBy}${orderParams.order}`;
  }

  return query;
};

export const hasNewParams = (oldParams, newParams) => {
  for (const key in newParams) {
    if (Object.hasOwnProperty.call(newParams, key)) {
      if (newParams[key] !== oldParams[key]) return true;
    }
  }

  return false;
};

export const getChangedData = (data, oldData, keys = Object.keys(data)) => {
  const result = {};

  for (const key in keys) {
    if (Object.hasOwnProperty.call(keys, key)) {
      const element = keys[key];
      if (Object.hasOwnProperty.call(data, element) && !isEqual(data[element], oldData[element])) {
        result[element] = data[element];
      }
    }
  }

  return result;
};

export const parseDate = (date, defaultValue = '') => (date ? new Date(date).toLocaleDateString('en-GB') : defaultValue);
export const parseTime = (date, defaultValue = '') =>
  date ? `at ${new Date(date).getHours()}:${new Date(date).getMinutes()}:${new Date(date).getSeconds()}` : defaultValue;
export const parseDateTime = (date, defaultValue = '', locale = 'es-CL') =>
  date ? new Date(date).toLocaleString(locale).replace(',', '') : defaultValue;

export const parsePrice = price => {
  if (!price) return 0;
  price = typeof price === 'number' ? price : parseInt(price);

  return price.toLocaleString('it-IT', {
    currency: 'VND',
  });
};

export const replaceTitle = (title = '') => {
  return title
    .replace(/người gửi/gi, 'NG')
    .replace(/sản phẩm/gi, 'SP')
    .replace(/nơi giao/gi, 'NG')
    .replace(/nơi nhận/gi, 'NN')
    .replace(/người giao/gi, 'NG')
    .replace(/người nhận/gi, 'NN');
};

export const getParamsFromQuery = (search = '') => {
  search = search.replace('?', '');
  if (!search) return null;
  const params = search.split('&');
  const req = {};
  params.forEach(_p => {
    req[_p.split('=')[0]] = _p.split('=')[1];
  });
  return req;
};
