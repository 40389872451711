import axios from 'axios';

import _get from 'lodash/get';

import { HTTP_CODE } from 'constants/common';
import { getUserToken, removeToken } from './localStorage';
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function formatResponse(response) {
  const res = _get(response, ['data', 'data', 0]);
  try {
    if (res && typeof res === 'string') return JSON.parse(res);
    if (res && Array.isArray(res)) return res.map(_d => (isJson(_d) ? JSON.parse(_d) : _d));
  } catch (error) {}

  return response.data;
}

export function handleDataError(error) {
  let message = '';

  if (error.response) {
    if (error.response.status === HTTP_CODE.Unauthorized) {
      removeToken();
      window.location.reload();
      message = 'Unauthorized';
    } else if (error.response.status === HTTP_CODE.InternalServerError) {
      message = 'Internal Server Error';
    } else {
      message = _get(error.response, ['data', 'data', 0, 'message']) || _get(error.response, ['data', 'error']);
    }
  }

  return { message };
}

function handleBeforeCallApi() {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      const token = getUserToken();

      if (token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

function handleAfterCallApi() {
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return formatResponse(response);
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(handleDataError(error));
    }
  );
}

export function setUpApi() {
  // axios.defaults.baseURL = NORMAL_END_POINT;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  handleBeforeCallApi();

  handleAfterCallApi();
}
