import { getBranchInfo } from './branch';
import { getCustomerInfo } from './customers';
import { getExpressServiceInfo } from './express_service';
// import { getOrderInfo } from './orders';
// import { getOrderReceiveInfo } from './orders-receive';
import { getReportCargoInfo } from './report-cargo';
import { getReportMoneyInfo } from './report-money';
import { getExpressServicePriceInfo } from './service_price';
import { getUserInfo } from './users';

import { getReportDailyInfo } from './report-daily';
import { getReportPayoffInfo } from './report-payoff';
import { getReportMonthlyInfo } from './report-monthly';
import { getReportRefundInfo } from './report-refund';
import { getOrderInfo } from './orders';
import { getOrderReceiveInfo } from './orders-receive';
import { getReportRefundCompanyInfo } from './report-refund-company';
import { getReportRefundAccountantInfo } from './report-refund-accountant';
import { getReportPayoffCompanyInfo } from './report-payoff-company';
import { getReportPayoffAccountantInfo } from './report-payoff-accountant';
import { getImportHistoryInfo } from './import-history';
import { getReportManagementInfo } from './report-management';
import { getCustomerOrderInfo } from './customer-order';
import { getLookupInfo } from './lookup';
import { getTruckOrderInfo } from './truck-order';
import { getTruckLookupInfo } from './truck-lookup';

export const getInfoManagementByUrl = url => {
  switch (url) {
    case '/import-sending-slip':
    case '/import-receipt-voucher':
      return getImportHistoryInfo();

    case '/customer-orders':
      return getCustomerOrderInfo();

    case '/lookup':
      return getLookupInfo();

    case '/truck-orders':
      return getTruckOrderInfo();

    case '/truck-lookup':
      return getTruckLookupInfo();

    case '/services':
      return getExpressServiceInfo();

    case '/service-price':
      return getExpressServicePriceInfo();

    case '/users':
      return getUserInfo();

    case '/customers':
      return getCustomerInfo();

    // case '/orders-send':
    //   return getOrderInfo();

    // case '/orders-receive':
    //   return getOrderReceiveInfo();

    case '/branches':
      return getBranchInfo();

    case '/report-cargo':
      return getReportCargoInfo();

    case '/report-money':
      return getReportMoneyInfo();

    case '/orders-send':
      return getOrderInfo();

    case '/orders-receive':
      return getOrderReceiveInfo();

    case '/report-daily':
      return getReportDailyInfo();

    case '/report-monthly':
      return getReportMonthlyInfo();

    case '/report-refund':
      return getReportRefundInfo();

    case '/report-payoff':
      return getReportPayoffInfo();

    case '/report-refund-company':
      return getReportRefundCompanyInfo();

    case '/report-refund-accountant':
      return getReportRefundAccountantInfo();

    case '/report-payoff-company':
      return getReportPayoffCompanyInfo();

    case '/report-payoff-accountant':
      return getReportPayoffAccountantInfo();

    case '/report-management':
    case '/report-management-accountant':
      return getReportManagementInfo();

    case '/them-o-day-ne':
      return {};

    default:
      return {
        buttonAddTitle: '',
        columns: [],
        columnTemplate: '',
        filterElements: [],
        selectable: false,
        title: '',
        url: '',
      };
  }
};
