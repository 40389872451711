import { AUTH_END_POINT } from 'constants/common';
import axios from 'axios';

export const getBranches = () =>
  axios({
    url: AUTH_END_POINT + 'branchs',
    method: 'GET',
  });

export const getBranchById = id =>
  axios({
    url: AUTH_END_POINT + 'branchs/id/' + id,
    method: 'GET',
  });

export const createBranch = data =>
  axios({
    url: AUTH_END_POINT + 'branchs',
    method: 'POST',
    data,
  });

export const updateBranch = data =>
  axios({
    url: AUTH_END_POINT + 'branchs',
    method: 'PUT',
    data,
  });

export const deleteBranch = name =>
  axios({
    url: AUTH_END_POINT + 'branchs/' + name,
    method: 'DELETE',
  });
